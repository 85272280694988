import routes from "../../constants/routes";
import axios from "axios";
import { OrganizationManagement } from "../../types/index";

type ResponseProps = {
  data?: OrganizationManagement[];
  totalPages?: number;
  totalCount?: number;
  pageNumber?: number;
  error?: { message: string };
};

const getOrganizationsAsync = async (
  pageNumber: number,
  pageLimit: number,
  search: string
): Promise<ResponseProps> => {
  try {
    const { data } = await axios.get(
      `${routes.get.organizations.getForManagement}`,
      {
        params: {
          page: pageNumber,
          pageLimit: pageLimit,
          search,
        },
      }
    );
    return {
      data: data.data,
      pageNumber: data.pageNumber,
      totalPages: data.totalPages,
      totalCount: data.totalCount,
    };
  } catch {
    return { error: { message: "Error getting organizations" } };
  }
};

export default getOrganizationsAsync;
