import { ApiResponse } from "../../types/ApiResponse";
import axios from "axios";
import routes from "../../constants/routes";
import { User } from "../../types";

type ResponseProps = {
  data?: User[] | [];
  totalPages?: number;
  totalCount?: number;
  pageNumber?: number;
  error?: { message: string };
};

const getOrganizationUsersAsync = async (
  name: string
): Promise<ApiResponse<ResponseProps>> => {
  try {
    const { data } = await axios.get(`${routes.get.users.getForManagement}`, {
      params: {
        page: 1,
        search: name,
      },
    });
    return {
      data,
      success: true,
    };
  } catch {
    return {
      success: false,
      error: { message: "Error trying to get organization users" },
    };
  }
};

export default getOrganizationUsersAsync;
