import React from "react";
import { Form, Field } from "react-final-form";
import styled from "styled-components";
import { Flex, Text } from "../../../core/ui";
import {
  StyledForm,
  InputField,
  StyledButton,
} from "../../shared/FormAndFields";
import {
  required,
  maxLength,
  minLength,
  composeValidators,
} from "../../../core/utils";
import createOrganizationAsync from "../../../api/Organizations/createOrganizationAsync";
import updateOrganizationAsync from "../../../api/Organizations/updateOrganizationAsync";
import { showUIToast } from "../../../core/ui/UIToast";
import {
  CreateOrganizationDto,
  UpdateOrganizationDto,
} from "../../../types/OrganizationTypes";

type Props = {
  organization?: UpdateOrganizationDto | CreateOrganizationDto;
  onSuccess: () => void;
};

const StyledInputField = styled(InputField)`
  width: 100%;
`;

const CreateEditOrgForm = ({ organization, onSuccess }: Props): JSX.Element => {
  const handleCreate = async (organization: CreateOrganizationDto) => {
    const { data, success, error } = await createOrganizationAsync(
      organization
    );
    if (data && success) {
      showUIToast({
        type: "success",
        text: "A new organization was created.",
      });
      onSuccess();
    }

    error && showUIToast({ type: "error", text: error.message });
  };

  const handleUpdate = async (organization: UpdateOrganizationDto) => {
    const { data, success, error } = await updateOrganizationAsync(
      organization
    );
    if (success && data) {
      showUIToast({ type: "info", text: "Organization updated." });
      onSuccess();
    }

    error && showUIToast({ type: "error", text: error.message });
  };

  const submitForm = async (
    organization: CreateOrganizationDto | UpdateOrganizationDto
  ) => {
    "id" in organization || "_id" in organization
      ? await handleUpdate(organization)
      : await handleCreate(organization);
  };

  return (
    <Form
      onSubmit={submitForm}
      initialValues={organization}
      render={({ handleSubmit, submitting, pristine, valid }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Flex mb="20px" gap="24px">
            <Field
              label="Organization name"
              name="name"
              component={StyledInputField}
              type="text"
              validate={composeValidators(
                required,
                minLength(3),
                maxLength(30)
              )}
            />
          </Flex>

          <StyledButton
            bg="lightBlue"
            px={25}
            py={12}
            type="submit"
            mt={12}
            disabled={submitting || pristine || !valid}
          >
            <Text fontSize={16} fontWeight="600" color="white">
              Submit
            </Text>
          </StyledButton>
        </StyledForm>
      )}
    ></Form>
  );
};

export default CreateEditOrgForm;
