import React, { useState, useEffect, useContext } from "react";
import { Form, Field } from "react-final-form";
import { Flex, Text, Button } from "../../../core/ui";
import ReactTooltip from "react-tooltip";
import getActiveOrganizationsAsync, {
  ActiveOrganizationsResponse,
} from "../../../api/Organizations/getActiveOrganizationsAsync";
import getSdrsAsync, { SdrsResponse } from "../../../api/Users/getAllSdrsAsync";
import createUserAsync from "../../../api/Users/createUserAsync";
import updateUserAsync from "../../../api/Users/updateUserAsync";
import { UserTypes } from "../../shared/enums";
import {
  composeValidators,
  required,
  email,
  password,
  minLength,
  maxLength,
} from "../../../core/utils";
import InfoIcon from "../../../assets/icons/info_icon.svg";
import Bell from "../../../assets/icons/bell.svg";
import { User } from "../../../types";
import {
  StyledForm,
  InputField,
  SelectField,
  CheckboxField,
  FieldsGroup,
  StyledButton,
} from "../../shared/FormAndFields";
import { UserProviderContext } from "../../app/UserProvider";
import { showUIToast } from "../../../core/ui/UIToast";

const TooltipIcon = () => (
  <img
    src={InfoIcon}
    data-class="password-tooltip"
    data-place="right"
    data-type="info"
    data-effect="solid"
    data-html={true}
    data-tip={`
    <div style="text-align: left;">
      <p>* At least 8 characters</p>
      <p>* At least 1 number</p>
      <p>* At least 1 uppercase letter</p>
      <p>* At least 1 lowercase letter</p>
      <p>* At least 1 special character</p>
    </div>
  `}
  />
);

type Props = {
  user?: User;
  onSuccess: () => void;
};

const CreateUserForm = ({ user, onSuccess }: Props): JSX.Element => {
  const [isAgent, setIsAgent] = useState<boolean>(
    user ? user.userType.includes(UserTypes.AGENT) : false
  );
  const [showPasswordInput, setShowPasswordInput] = useState<boolean>(!user);
  const [isNew] = useState<boolean>(!user);
  const [organizations, setOrganizations] = useState<
    ActiveOrganizationsResponse[] | []
  >([]);
  const [selectedOrg, setSelectedOrg] = useState<string>("");

  const [srds, setSdrs] = useState<SdrsResponse[]>([]);
  const [isSdrSelected, setIsSdrSelected] = useState<boolean>(
    user?.userType.includes("sdr") ? true : false
  );
  const [isAgentSelected, setIsAgentSelected] = useState<boolean>(
    user?.userType.includes("agent") ? true : false
  );

  const {
    currentUser: { isSuperAdmin, organizationId },
  } = useContext(UserProviderContext);

  const getOrganizations = async () => {
    const { activeOrganizations, error } = await getActiveOrganizationsAsync();
    if (error) return;
    if (activeOrganizations) setOrganizations(activeOrganizations);
  };

  const getAllActiveSdrs = async () => {
    const { allSdrs, error } = await getSdrsAsync();
    if (error) return;
    if (allSdrs) setSdrs(allSdrs);
  };

  useEffect(() => {
    if (isSuperAdmin) getOrganizations();

    if (user) setSelectedOrg(user.organizationId);
    else setSelectedOrg(organizationId);
  }, []);

  useEffect(() => {
    getAllActiveSdrs();
  }, []);

  const handleCreateUser = async (data) => {
    const { user, error } = await createUserAsync(data);
    if (user) {
      showUIToast({ type: "success", text: "A new user was created." });
      onSuccess();
      return;
    }

    error && showUIToast({ type: "error", text: error.message });
  };

  const handleUpdateUser = async (data) => {
    const { user, error } = await updateUserAsync(data);
    if (user) {
      showUIToast({ type: "info", text: "User updated." });
      onSuccess();
      return;
    }

    error && showUIToast({ type: "error", text: error.message });
  };

  const handleCreateOrEdit = async (values) => {
    const isEdit = typeof values._id !== "undefined";

    if (!values.userType.includes(UserTypes.AGENT)) {
      delete values.sdrId;
    }
    const userType = values.userType.map((type) => {
      return { type };
    });

    const data = { ...values, userType };

    if (isEdit) {
      await handleUpdateUser(data);
      return;
    }

    await handleCreateUser(data);
  };

  const validateRecord = (values) => {
    const errors: {
      userTypeGroup?: string;
    } = {};

    if (!values.userType?.length) {
      errors.userTypeGroup = "Required";
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleCreateOrEdit}
      initialValues={user || { organizationId }}
      validate={validateRecord}
      render={({ handleSubmit, form, submitting, pristine, valid }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Flex mb="20px" gap="24px">
            <Flex flexDirection="column" flex="1">
              <Field
                label="Name"
                name="name"
                component={InputField}
                type="text"
                validate={composeValidators(
                  required,
                  minLength(3),
                  maxLength(20)
                )}
              />
              {isNew && (
                <Field
                  label="Email"
                  name="email"
                  component={InputField}
                  type="text"
                  validate={composeValidators(required, email)}
                />
              )}

              {showPasswordInput ? (
                <Field
                  label={
                    <>
                      <label>Password</label>
                      <TooltipIcon />
                      <ReactTooltip />
                    </>
                  }
                  name="password"
                  component={InputField}
                  type="password"
                  validate={composeValidators(required, password)}
                />
              ) : (
                <Button
                  width="100%"
                  p="12px 25px"
                  mt={26}
                  bg="lightBlue"
                  type="button"
                  onClick={() => setShowPasswordInput(true)}
                >
                  <Text fontSize={16} fontWeight="600" color="white">
                    Change password
                  </Text>
                </Button>
              )}
            </Flex>
            <Flex flexDirection="column" flex="1">
              {isNew && (
                <>
                  {organizations.length > 0 ? (
                    <Field
                      label="Organization"
                      name="organizationId"
                      component={SelectField}
                      validate={required}
                      options={organizations}
                      customOnChange={(e) => {
                        form.change("sdrId", undefined);
                        setSelectedOrg(e.target.value);
                      }}
                    />
                  ) : (
                    <Field
                      name="organizationId"
                      type="hidden"
                      validate={required}
                      component="input"
                    />
                  )}
                </>
              )}
              <FieldsGroup groupName="userTypeGroup" label="Type">
                <Flex
                  alignItems="center"
                  justifyContent="space-around"
                  height="36px"
                  mt="8px"
                >
                  <Field
                    label={UserTypes.ADMIN}
                    name="userType"
                    component={CheckboxField}
                    type="checkbox"
                    value={UserTypes.ADMIN}
                  />
                  {!isAgentSelected && (
                    <Field
                      label={UserTypes.SDR}
                      name="userType"
                      component={CheckboxField}
                      type="checkbox"
                      value={UserTypes.SDR}
                      visible={() => setIsSdrSelected((prev) => !prev)}
                    />
                  )}
                  {!isSdrSelected && (
                    <Field
                      label={UserTypes.AGENT}
                      name="userType"
                      component={CheckboxField}
                      type="checkbox"
                      value={UserTypes.AGENT}
                      customOnChange={() => setIsAgent((prev) => !prev)}
                      visible={() => setIsAgentSelected((prev) => !prev)}
                    />
                  )}
                </Flex>
              </FieldsGroup>
              {isAgent && isSuperAdmin && (
                <Field
                  label="Sdr"
                  name="sdrId"
                  component={SelectField}
                  validate={required}
                  options={srds}
                />
              )}
              {user && (
                <Field
                  name="_id"
                  type="hidden"
                  validate={required}
                  component="input"
                />
              )}
            </Flex>
          </Flex>

          <StyledButton
            bg="lightBlue"
            px={25}
            py={12}
            type="submit"
            mt={12}
            disabled={submitting || pristine || !valid}
          >
            <Text fontSize={16} fontWeight="600" color="white">
              Submit
            </Text>
          </StyledButton>
        </StyledForm>
      )}
    ></Form>
  );
};

export default CreateUserForm;
