import routes from "../../constants/routes";
import axios from "axios";
import { HubspotContact } from "../../types/HubspotTypes";

type ContactsProps = {
  contacts?: HubspotContact[];
  error?: { message: string };
};

const getHubspotContactsAsync = async (
  tendrilId: string,
  id: string,
  custom: boolean
): Promise<ContactsProps> => {
  try {
    const hubspotAccounts = await axios.get<HubspotContact[]>(
      `${
        custom
          ? routes.get.customHubspot.getContacts
          : routes.get.hubspot.getContacts
      }/${tendrilId}/${id}`
    );

    return { contacts: hubspotAccounts?.data };
  } catch (error) {
    return { error: { message: "Error while getting contacts" } };
  }
};

export default getHubspotContactsAsync;
