import React from "react";
import { UIInputText } from "../../core/ui/UIElements";
import { UpdateRecordingDto } from "../../types/Recording";

interface EditVoicemailProps {
  recordingDto: UpdateRecordingDto;
  setRecordingDto: (value: UpdateRecordingDto) => void;
}

const EditVoicemail: React.FC<EditVoicemailProps> = ({
  recordingDto,
  setRecordingDto,
}) => {
  const handleInputChange = (event) => {
    const { value } = event.target;

    setRecordingDto({ title: value, id: recordingDto.id });
  };

  return (
    <>
      <UIInputText
        data-qa-id={"nameVoiceInput"}
        type="text"
        name="title"
        placeholder={"Voicemail Name"}
        value={recordingDto?.title || ""}
        onChange={handleInputChange}
      />
    </>
  );
};

export default EditVoicemail;
