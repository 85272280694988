import { microservices } from "./microservices";

/**
 * Abstracts away the need to know at call site in which service each route lives on
 */
const routes = {
  health: {
    edge: microservices.edge + "/health",
    contacts: microservices.contacts + "/health",
    sync: microservices.sync + "/health",
    contactsNest: microservices.contactsNest + "/health",
  },
  get: {
    v1: {
      contacts: microservices.contacts + "/v1/contacts",
    },
    twilio: {
      token: microservices.edge + "/twilio/token",
      tokenNest: microservices.edgeNest + "/twilio/token",
    },
    rabbit: {
      cosume: microservices.contacts + "/sqs/consume", //@deprecated
      consumeNest: microservices.contactsNest + "/rabbit/consume",
    },
    users: {
      user: microservices.edge + "/user", // @deprecated
      userNest: microservices.edgeNest + "/users",
      allActiveSdrs: microservices.edge + "/user/active/sdrs",
      /** @deprecated use {@link getForManagementNest} instead.*/
      getForManagement: microservices.edge + "/user/getForManagement",
      getForManagementNest: microservices.edgeNest + "/users/getForManagement",
      /** @deprecated use {@link getUserInfoNest} instead.*/
      getUserInfo: microservices.edge + "/user/getInfo",
      getUserInfoNest: microservices.edgeNest + "/users/getInfo",
      getAgentValidations: microservices.edge + "/user/agentValidations",
    },
    recordings: {
      getRecordingsBySdrId: microservices.contacts + "/recording/sdr/", // @deprecated
      getRecordingsBySdrIdNest: microservices.contactsNest + "/recording/sdr/",
      getRecordingByCallId: microservices.contactsNest + "/recording/callId",
      getRecordingById: microservices.contactsNest + "/recording",
    },
    sdrLogs: {
      sdrLog: microservices.contacts + "/sdr/log/",
    },
    callLogs: {
      callLog: microservices.contacts + "/call/log/", // @deprecated
      callLogNest: microservices.contactsNest + "/call-logs/",
    },
    organizations: {
      organization: microservices.edge + "/organization",
      activeOrganizations: microservices.edge + "/organization/active",
      getForManagement: microservices.edge + "/organization/getForManagement",
      getOrganizationInfo: microservices.edgeNest + "/organization/info",
    },
    contacts: {
      contactListsNest: microservices.contactsNest + "/contact/lists/orgId",
      byContactListIdNest: microservices.contactsNest + "/contact/lists",
      contactsNest: microservices.contactsNest + "/contacts/contactList",
      contactList: microservices.contacts + "/contact/lists", // @deprecated
      contactListByOrgId: microservices.contacts + "/contact/lists/orgId", // unused ?
      contacts: microservices.contacts + "/contacts", //deprecated
      byContactIdNest: microservices.contactsNest + "/contacts",
      byContactId: microservices.contacts + "/contacts/byId", //@deprecated
    },
    script: {
      scriptList: microservices.contacts + "/script",
    },
    voicemail: {
      voicemailList: microservices.contacts + "/recording/sdr", // @deprecated
      voicemailListNest: microservices.contactsNest + "/recording/sdr",
      byCallId: microservices.contacts + "/recording/callId", // @deprecated
      byVoiceMailId: microservices.contacts + "/recording/", // @deprecated
    },
    callSession: {
      getById: microservices.contacts + "/call/session", //@deprecated
      getByIdNest: microservices.contactsNest + "/call-sessions/callSessionId",
      getByStatusAndSdrId:
        microservices.contacts + "/call/session/getByStatusAndSdrId",
      getByStatusAndAgentId:
        microservices.contacts + "/call/session/getByStatusAndAgentId", // @deprecated
      getByStatusAndAgentIdNest:
        microservices.contactsNest + "/call-sessions/getByStatusAndAgentId",
      getByMultipleStatusAndSdrId:
        microservices.contacts + "/call/session/getByMultipleStatusAndSdrId", // @deprecated
      getByMultipleStatusAndSdrIdNest:
        microservices.contactsNest +
        "/call-sessions/getByMultipleStatusAndSdrId",
      getBySdrId: microservices.contacts + "/call/session/sdr",
      getReport: microservices.contacts + "/call/session/getReport", //@deprecated
      getReportNest: microservices.contactsNest + "/call-sessions/getReport",
      getDialingStatus:
        microservices.contacts + "/call/session/getDialingStatus", //@deprecated
      getDialingStatusNest:
        microservices.contactsNest + "/call-sessions/getDialingStatus",
      getPreview: microservices.contacts + "/call/session/preview", //@deprecated
      getPreviewNest: microservices.contactsNest + "/call-sessions/preview",
      getCallSessionInfo:
        microservices.contactsNest + "/call-sessions/callSessionId",
    },
    metrics: {
      getByCallSessionId: microservices.contacts + "/metric/getByCallSessionId",
      getDynamicStats: microservices.contacts + "/metric/getDynamicStats",
      buildMetrics: microservices.contacts + "/metric/buildMetrics",
      buildDispositionMetrics:
        microservices.contacts + "/metric/buildDispositionMetrics",
    },
    dashboard: {
      getDashboardData: microservices.contacts + "/dashboard/getDashboardData", // @deprecated
      getDashboardNest: microservices.contactsNest + "/dashboard/sdrId",
      getSdrIdsByNameOrOrganization:
        microservices.edge + "/dashboard/searchDataByName", // @deprecated
      getSdrIdsByNameOrOrganizationNest:
        microservices.edgeNest + "/dashboard/searchDataByName",
    },
    outreach: {
      getLinkedAccounts: microservices.sync + "/outreach-configs/config",
      getSequences: microservices.sync + "/outreach-configs/sequences",
      getSequenceSteps: microservices.sync + "/outreach-configs/sequence-steps",
      getProspects: microservices.sync + "/outreach-configs/prospects",
    },
    hubspot: {
      getLinkedAccounts: microservices.sync + "/hubspot-configs/config",
      getLists: microservices.sync + "/hubspot-configs/contact-lists",
      getContacts:
        microservices.sync + "/hubspot-configs/contact-lists/contacts",
    },
    customHubspot: {
      getCustomConfiguration:
        microservices.sync + "/hubspot-configs-custom/config",
      getLinkedAccounts:
        microservices.sync + "/hubspot-configs-custom/accounts",
      getLists: microservices.sync + "/hubspot-configs-custom/contact-lists",
      getContacts:
        microservices.sync + "/hubspot-configs-custom/contact-lists/contacts",
    },
    featureToggle: {
      getFeaturesFlagsForOrg: microservices.sync + "/feature-flags",
    },
    numbers: {
      getForManagement: microservices.edgeNest + "/numbers",
    },
  },
  post: {
    twilio: {
      sdrConference: microservices.edge + "/twilio",
      sdrConferenceNest: microservices.edgeNest + "/twilio",
    },
    v1: {
      login: microservices.edge + "/v1/login/", // @deprecated
      logout: microservices.edge + "/v1/login/logout", // @deprecated
    },
    auth: {
      login: microservices.edgeNest + "/auth/login",
      logout: microservices.edgeNest + "/auth/logout",
    },
    contact: {
      contactsListNest: microservices.contactsNest + "/contact/lists",
      contactsNest: microservices.contactsNest + "/contacts",
      contactsList: microservices.contacts + "/contact/lists/", // @deprecated
      contacts: microservices.contacts + "/contacts", // @deprecated
    },
    recordings: {
      createRecording: microservices.contacts + "/recording/",
      playRecording: microservices.edge + "/twilio/",
    },
    users: {
      user: microservices.edge + "/user", // @deprecated
      userNest: microservices.edgeNest + "/users",
      password: {
        recover: microservices.edge + "/password/recover",
        change: microservices.edge + "/password/update",
      },
    },
    scripts: {
      script: microservices.contacts + "/script", //@deprecated
      scriptNest: microservices.contactsNest + "/scripts",
    },
    callSession: {
      create: microservices.contacts + "/call/session/", //@deprecated
      createNest: microservices.contactsNest + "/call-sessions",
      hangup: microservices.contacts + "/call/session/hangup",
      answer: microservices.contacts + "/call/session/answer",
    },
    sqs: {
      load: microservices.contacts + "/sqs/load",
    },
    organizations: {
      /** @deprecated In favor of new nest microservice {@link organizationNest} instead */
      organization: microservices.edge + "/organization",
      organizationNest: microservices.edgeNest + "/organization",
    },
    session: {
      refresh: microservices.edge + "/session/refresh",
      validate: microservices.edge + "/session/validate",
    },
    outreach: {
      oAuthLogin: microservices.sync + "/outreach-configs/login",
      updateJob: microservices.sync + "/outreach-jobs",
    },
    hubspot: {
      oAuthLogin: microservices.sync + "/hubspot-configs/login",
      updateJob: microservices.sync + "/hubspot-jobs",
    },
    customHubspot: {
      saveConfig: microservices.sync + "/hubspot-configs-customs",
      oAuthLogin: microservices.sync + "/hubspot-configs-custom/login",
      updateJob: microservices.sync + "/hubspot-jobs-custom",
    },
    numbers: {
      create: microservices.edgeNest + "/numbers",
    },
  },
  put: {
    callLog: {
      /** @deprecated In favor of new nest microservice {@link updateCallLog} */
      update: microservices.contacts + "/call/log",
      updateCallLog: microservices.contactsNest + "/call-logs",
    },
    callSession: {
      update: microservices.contacts + "/call/session", //@deprecated
      updateNest: microservices.contactsNest + "/call-sessions",
    },
    contacts: {
      update: `${microservices.contactsNest}/contacts`,
      updateNotes: microservices.contactsNest + "/contacts/notes",
    },
    recordings: { updateRecording: microservices.contactsNest + "/recording" },
    users: {
      user: microservices.edge + "/user",
    },
    organizations: {
      /** @deprecated In favor of new nest microservice {@link organizationNest} instead */
      organization: microservices.edge + "/organization",
      organizationNest: microservices.edgeNest + "/organization",
    },
    numbers: {
      updateNumber: microservices.edgeNest + "/numbers",
      enableNumberPool: microservices.edgeNest + "/numbers/enable",
    },
  },
  delete: {
    twilio: {
      deleteSdrLog: microservices.contacts + "/sdr/log/",
    },
    script: {
      deleteScript: microservices.contacts + "/script", // @deprecated
      deleteScriptNest: microservices.contactsNest + "/scripts",
      editScriptNest: microservices.contactsNest + "/scripts",
    },
    voicemail: {
      deleteVoicemail: microservices.contacts + "/recording", // @deprecated
      deleteVoicemailNest: microservices.contactsNest + "/recording",
    },
    contacts: {
      deleteContactList: microservices.contacts + "/contact/lists", // @deprecated
      deleteContactListNest: microservices.contactsNest + "/contact/lists",
      deleteContact: microservices.contacts + "/contacts", // @deprecated
      deleteContactNest: microservices.contactsNest + "/contacts",
    },
    users: {
      user: microservices.edge + "/user", //@deprecated
      userNest: microservices.edgeNest + "/users",
    },
    organizations: {
      /** @deprecated use {@link organizationNest } instead. */
      organization: microservices.edge + "/organization",
      organizationNest: microservices.edgeNest + "/organization",
    },
    outreach: {
      deleteAccount: microservices.sync + "/outreach-configs/config",
    },
    hubspot: {
      deleteAccount: microservices.sync + "/hubspot-configs/config",
    },
    numbers: {
      deleteNumber: microservices.edgeNest + "/numbers",
    },
    customHubspot: {
      deleteAccount: microservices.sync + "/hubspot-configs-custom/config",
    },
  },
};

export default routes;
