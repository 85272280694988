import { Button } from '@components/ui/button';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import React, { useState } from 'react';

export interface PaginatedData {
  pageNumber: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
  onPrevPage: () => void;
  onNextPage: () => void;
}

const PaginatorControlsComponent = (paginatedData: PaginatedData): React.JSX.Element => {
  const [readyToChangePage, setReadyToChangePage] = useState(false);
  const getPagesInfo = (): React.JSX.Element => {
    const { pageNumber, pageSize, totalCount } = paginatedData;
    const start = (pageNumber - 1) * pageSize + 1;
    const calculatedEnd = pageNumber * pageSize;
    const end: number = calculatedEnd < totalCount ? calculatedEnd : totalCount;
    return (
      <div className="text-justify">
        {start} - {end} of {totalCount}
      </div>
    );
  };

  const canGoPrev = paginatedData.pageNumber > 1;
  const canGoNext = paginatedData.pageNumber < paginatedData.totalPages;

  const handleChangePage = (action: string): void => {
    setReadyToChangePage(false);

    if (action === 'prev' && canGoPrev) {
      paginatedData.onPrevPage();
      return;
    }

    if (action === 'next' && canGoNext) {
      paginatedData.onNextPage();
    }
  };

  return (
    <div className="flex-row flex shrink justify-end items-center">
      {getPagesInfo()}
      <div className="flex w-22 gap-2 p-2">
        <Button
          size="icon"
          onClick={() => {
            handleChangePage('prev');
          }}
          disabled={!readyToChangePage && !canGoPrev}
        >
          <IconChevronLeft />
        </Button>

        <Button
          size="icon"
          onClick={() => {
            handleChangePage('next');
          }}
          disabled={!readyToChangePage && !canGoNext}
        >
          <IconChevronRight />
        </Button>
      </div>
    </div>
  );
};

export default PaginatorControlsComponent;
