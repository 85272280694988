import routes from "../../constants/routes";
import axios from "axios";

export type SdrsResponse = {
  id: string;
  name: string;
};

type ResponseProps = {
  allSdrs?: SdrsResponse[] | [];
  error?: { message: string };
};

const getSdrsAsync = async (): Promise<ResponseProps> => {
  try {
    const sdrs = await axios.get<SdrsResponse[]>(
      `${routes.get.users.allActiveSdrs}/`
    );
    return { allSdrs: sdrs.data };
  } catch {
    return { error: { message: "Error getting all sdrs" } };
  }
};

export default getSdrsAsync;
