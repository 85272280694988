import React from "react";
import tabTitle from "../../utils/updateTitle";

const NotFound: React.FC = () => {
  tabTitle("Tendril Connect | Not found");

  return (
    <div>
      <h3>Sorry, page not found!</h3>
    </div>
  );
};

export default NotFound;
