import styled, { css } from "styled-components";
import { theme } from "../../theme";

const { colors } = theme;

const { lighterBlue, lightGray, darkGray } = colors;

const baseInputsProps = css`
    width: 100%;
    background-color: #fff;
    color: ${darkGray};
    padding: 0.75rem 1rem;
    
    font-size: 1.25rem;
    line-height: 1.5rem;
    
    border: 1px solid ${darkGray};
    border-radius: 0.25rem;
  
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  
    &:focus {
      outline: none;
      border-color ${lighterBlue};
      box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
    }
  
    &::placeholder {
      color: ${darkGray};
    }
    
    &:disabled,
    &:read-only {
        background-color: ${lightGray};
        opacity: 1;
    }
  
  
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border-color: ${lighterBlue};
      box-shadow: 0 0 0 5px rgba(37, 99, 235, 0.1);
    }
  }
`;

const baseLabelProps = css`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: ${darkGray};
  margin-bottom: 0.5rem;
`;

export const BaseForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  border-radius: 0.25rem;
`;

export const BaseField = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  label {
    ${baseLabelProps};
  }

  input,
  textarea {
    ${baseInputsProps}
  }
`;

export const BaseInput = styled.input`
  ${baseInputsProps};
`;

export const BaseTextArea = styled.textarea`
  ${baseInputsProps};
  resize: none;
  overflow-y: auto;
  overflow-x: auto;
`;

export const BaseLabel = styled.label<{ isHidden?: boolean }>`
  ${baseLabelProps};
  display: ${({ isHidden }) => (isHidden ? "none" : "block")};
`;
