import routes from "../../constants/routes";
import axios from "axios";
import { HubspotApp } from "../../types/HubspotAppTypes";

type ResponseProps = {
  hubspotConfig?: HubspotApp;
  error?: { message: string };
};

const saveHubspotCustomConfigAsync = async (
  orgId: string,
  userId: string,
  hubspotAppData: Pick<
    HubspotApp,
    | "id"
    | "appId"
    | "accessKey"
    | "accessSecret"
    | "url"
    | "organizationId"
    | "userId"
    | "callbackUrl"
  >
): Promise<ResponseProps> => {
  try {
    const saveHubspotCustomConfiguration = await axios.post<HubspotApp>(
      `${routes.get.customHubspot.getCustomConfiguration}/${orgId}/${userId}`,
      hubspotAppData
    );
    return { hubspotConfig: saveHubspotCustomConfiguration.data };
  } catch (e) {
    return { error: { message: "Error saving the Hubspot Configuration" } };
  }
};

export default saveHubspotCustomConfigAsync;
