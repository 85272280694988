import React, { useEffect, useState, useContext } from "react";
import { useAppContext } from "../../libs/contextLib";
import constants from "../../constants.json";
import axios from "axios";
import routes from "../../constants/routes";
import TendrilSlogan from "../../../public/assets/icons/tendril_slogan.svg";
import { UserProviderContext } from "../../components/app/UserProvider";
import { createSessionUser } from "../../core/utils/sessionRefresher";
import LoginLeftColumn from "./LoginLeftColumn";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

import {
  LoginGrid,
  LoginCell,
  LoginColumn,
  LoginErrorMessage,
  LoginAnchor,
  LoginForm,
  LoginInputText,
  LoginButton,
  Icon,
  Row,
} from "./LoginElements";
import { featuresStore } from "../../components/app/FeaturesProvider";
import TagManager from "react-gtm-module";
import getType from "../../utils/getUserType";
import { showUIToast } from "../../core/ui/UIToast";
import login from "../../api/Auth/login";

type TodoErrorResponse = {
  error: string;
};

type userType = {
  type: string;
};

type UserGroupType = {
  [key: string]: userType;
};

const Login: React.FC = () => {
  const { userHasAuthenticated } = useAppContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordShown, setPasswordShown] = useState<boolean>(false);

  const { setCurrentUser } = useContext(UserProviderContext);
  const { fetchFeatures } = useContext(featuresStore);

  useEffect(() => {
    console.log(JSON.stringify(constants));
  }, []);

  const validateForm = () => {
    const valid = email.length > 0 && password.length > 0;
    return valid;
  };

  const togglePassword = () => {
    setPasswordShown(!isPasswordShown);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { authToken } = await login(email, password);

      const user = createSessionUser(authToken as string);

      window.localStorage.setItem("target", authToken as string);
      window.localStorage.setItem("user", JSON.stringify(user));

      userHasAuthenticated(true);
      setCurrentUser(user);
      fetchFeatures(user.organizationId);

      TagManager.dataLayer({
        dataLayer: {
          event: "login",
          path: window.location.pathname,
          userId: user.userId,
          organizationId: user.organizationId,
          role: getType(user.type),
        },
      });

      const healthResponse = await axios.get(routes.health.sync);
      console.log("SYNC response");
      console.log(JSON.stringify(healthResponse));
      console.log(healthResponse.data);

      const healthResponseContactsNest = await axios.get(
        routes.health.contactsNest
      );
      console.log("CONTACTS NEST response");
      console.log(JSON.stringify(healthResponseContactsNest));
      console.log(healthResponseContactsNest.data);
    } catch (error) {
      setErrors(true);
      if (axios.isAxiosError(error) && error.response) {
        // Request made and server responded
        setErrorMessage((error.response?.data as TodoErrorResponse).error);
        showUIToast({
          type: "warning",
          text:
            (error.response?.data as TodoErrorResponse).error ||
            "Login requires attention. If the problem persist, please contact customer support.",
        });
      } else {
        // The request was made but no response was received
        setErrorMessage("Something went wrong, please try later");
        showUIToast({
          type: "error",
          text: "Something went wrong, try again later.",
        });
      }
    }
  };

  return (
    <LoginGrid columns={["35%", "1fr"]}>
      <LoginLeftColumn />
      <LoginCell backgroundColor={"#EFEFEF"} color="black">
        <LoginColumn
          alignItems="center"
          justifyContent="center"
          gap="2rem"
          padding="3em 0"
        >
          <img src={TendrilSlogan} alt="Tendril Slogan" width="312" />
          <LoginForm onSubmit={handleSubmit} columns={["60%", "1fr"]}>
            {errors && <LoginErrorMessage>{errorMessage}</LoginErrorMessage>}
            <Row>
              <LoginInputText
                type="email"
                placeholder={"Email Address"}
                value={email}
                onChange={handleEmailChange}
                fontSize="1.625rem"
                data-qa-id="input-email"
              />
            </Row>
            <Row>
              <Icon onClick={togglePassword}>
                {!isPasswordShown ? (
                  <FA icon="eye" color="#95979A" size="xl" />
                ) : (
                  <FA icon="eye-slash" color="#95979A" size="xl" />
                )}
              </Icon>

              <LoginInputText
                type={isPasswordShown ? "text" : "password"}
                placeholder={"Password"}
                value={password}
                onChange={handlePasswordChange}
                fontSize="1.625rem"
                data-qa-id="input-password"
              />
            </Row>
            <LoginButton
              type="submit"
              value="Login"
              disabled={!validateForm()}
              fontSize="1.625rem"
              data-qa-id="login-button"
            />

            <LoginAnchor href="/password/recover" className="right gray">
              Forgot your password?
            </LoginAnchor>
          </LoginForm>
        </LoginColumn>
      </LoginCell>
    </LoginGrid>
  );
};

export default Login;
