import routes from "../../constants/routes";
import axios from "axios";
import { SequenceStep } from "../../types/OutreachTypes";

type SequencesProps = {
  sequenceSteps?: SequenceStep[];
  error?: { message: string };
};

const getSequencesStepsFromOutreachAsync = async (
  userId: string,
  orgId: string
): Promise<SequencesProps> => {
  try {
    const sequenceSteps = await axios.get<SequenceStep[]>(
      `${routes.get.outreach.getSequenceSteps}/${orgId}/${userId}`
    );

    return { sequenceSteps: sequenceSteps?.data };
  } catch (error) {
    return { error: { message: "Error while getting sequences" } };
  }
};

export default getSequencesStepsFromOutreachAsync;
