import React from "react";
import styled from "styled-components";
import { theme } from "../../core/theme";

interface Props {
  state: boolean;
  setState: (value) => void;
  title?: string;
  showHeader: boolean;
  showOverlay: boolean;
  modalPosition: string;
  padding: string;
  children: JSX.Element;
  showCloseBtn: boolean;
  zIndex?: number;
}

const Modal = ({
  state,
  setState,
  modalPosition,
  padding,
  showOverlay,
  showHeader,
  title,
  showCloseBtn,
  children,
  zIndex = 1,
}: Props): JSX.Element => {
  return (
    <>
      {state && (
        <Overlay
          showOverlay={showOverlay}
          modalPosition={modalPosition}
          zIndex={zIndex}
        >
          <ModalContainer padding={padding}>
            {showHeader && (
              <ModalHeader>
                <h3>{title}</h3>
              </ModalHeader>
            )}

            {showCloseBtn && (
              <CloseButton onClick={() => setState(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </CloseButton>
            )}

            <Content>{children}</Content>
          </ModalContainer>
        </Overlay>
      )}
    </>
  );
};

export default Modal;

interface OverlayI {
  showOverlay: boolean;
  modalPosition: string;
  zIndex: number;
}

const Overlay = styled.div<OverlayI>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${(props) =>
    props.showOverlay ? "rgba(0,0,0,.5)" : "rgba(0,0,0,0)"};
  padding: 40px;
  display: flex;
  align-items: ${(props) =>
    props.modalPosition ? props.modalPosition : "center"};
  justify-content: center;
  z-index: ${(props) => (props.zIndex ? props.zIndex : "1")};
`;

interface ModalContainerI {
  padding: string;
}

const ModalContainer = styled.div<ModalContainerI>`
  width: 500px;
  min-height: 100px;
  background: #fff;
  position: relative;
  color: ${theme.colors.darkGray};
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: ${(props) => (props.padding ? props.padding : "20px")};
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: ${theme.colors.lightBlue};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 10px;
  color: ${theme.colors.lightBlue};
  &:hover {
    background: #f2f2f2;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  p {
    margin: 0 auto 20px auto;
    font-size: 18px;
    color: ${theme.colors.darkGray};
  }

  button {
    margin: auto;
    background: linear-gradient(
      90deg,
      ${theme.colors.lightBlue},
      ${theme.colors.darkBlue}
    );
    color: ${theme.colors.white};
    border: none;
    outline: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    font-size: 14px;
    width: 20%;
    &:hover {
      background: ${theme.colors.lightBlue};
    }
    &:disabled {
      background: ${theme.colors.lightGray};
      color: ${theme.colors.darkGray};
      cursor: default;
    }
  }
`;
