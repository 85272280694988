import React from "react";
import IdleTimer from "react-idle-timer";

const IdleTimerContainer = ({ pageRef, timedOutHandler }): JSX.Element => {
  const timeOutDuration = 1000 * 60 * 210;

  const onIdleHandler = () => {
    timedOutHandler(true);
  };

  return (
    <IdleTimer ref={pageRef} timeout={timeOutDuration} onIdle={onIdleHandler} />
  );
};

export default IdleTimerContainer;
