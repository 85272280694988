export const isTendrilAdmin = async (): Promise<boolean> => {
  const userFromSession = window.localStorage.getItem("user");

  if (!userFromSession) {
    return false;
  }

  const user = JSON.parse(userFromSession);

  return user.isSuperAdmin;
};
