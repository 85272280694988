import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import routes from "../../constants/routes";

import LoginLeftColumn from "./LoginLeftColumn";
import {
  LoginGrid,
  LoginCell,
  LoginColumn,
  LoginErrorMessage,
  LoginSuccessMesage,
  LoginFromText,
  LoginForm,
  LoginInputText,
  LoginButton,
  LoginHints,
} from "./LoginElements";
import { showUIToast } from "../../core/ui/UIToast";

type RecoverResponse = {
  authToken: string;
};

const PasswordChange: React.FC = () => {
  const successMessage = "Password recovered successfully.";

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [success, setSuccess] = useState(false);
  const [enablePassword, setEnablePassword] = useState(true);
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { search } = useLocation();

  const validateEmptyPasswords = (password1: string, password2: string) => {
    setEnablePassword(password1 === "" || password2 === "");
  };

  const handlePassword1Change = (event) => {
    setPassword1(event.target.value);
    validateEmptyPasswords(event.target.value, password2);
  };

  const handlePassword2Change = (event) => {
    setPassword2(event.target.value);
    validateEmptyPasswords(password1, event.target.value);
  };

  const handleSubmit = async (event) => {
    if (password1 !== password2) {
      setErrors(true);
      setSuccess(false);
      setErrorMessage("Passwords do not match.");
      showUIToast({
        type: "warning",
        text: "Password doesn't match.",
      });
      event.preventDefault();

      return false;
    }
    const tokenUtils = new URLSearchParams(search);

    const newToken = tokenUtils.get("token");

    event.preventDefault();
    const data = {
      password: password1,
      token: newToken,
    };

    try {
      await axios.post<RecoverResponse>(
        routes.post.users.password.change,
        data
      );

      setErrors(false);
      setSuccess(true);
    } catch (error) {
      setErrors(true);

      if (axios.isAxiosError(error) && error.response) {
        // Request made and server responded
        setErrorMessage("Invalid password, please confirm the rules.");
        showUIToast({
          type: "warning",
          text: "Invalid password, please confirm the rules.",
        });
        setSuccess(false);
      } else {
        // The request was made but no response was received
        setErrorMessage("Something went wrong, please try later");
        showUIToast({
          type: "error",
          text: "Something went wrong, try again later.",
        });
        setSuccess(false);
      }
    }
  };

  return (
    <LoginGrid columns={["35%", "1fr"]}>
      <LoginLeftColumn />
      <LoginCell backgroundColor={"#EFEFEF"} color="black">
        <LoginColumn
          alignItems="center"
          justifyContent="center"
          gap="2rem"
          padding="3em 0"
        >
          <LoginFromText>Change your password</LoginFromText>
          <LoginForm onSubmit={handleSubmit}>
            <LoginInputText
              type="password"
              placeholder={"Password"}
              value={password1}
              onChange={handlePassword1Change}
              fontSize="1.625rem"
            />
            <LoginInputText
              type="password"
              placeholder={"Repeat Password"}
              value={password2}
              onChange={handlePassword2Change}
              fontSize="1.625rem"
            />
            <LoginHints>
              <b>Password rules: </b>
              At least 8 characters, at least 1 number, at least 1 uppercase
              letter, at least 1 lowercase letter, at least 1 special character.
            </LoginHints>
            <LoginButton
              type="submit"
              value="Change Password"
              disabled={enablePassword}
              fontSize="1.625rem"
            />
          </LoginForm>
          {errors && <LoginErrorMessage>{errorMessage}</LoginErrorMessage>}
          {success && <LoginSuccessMesage>{successMessage}</LoginSuccessMesage>}
        </LoginColumn>
      </LoginCell>
    </LoginGrid>
  );
};

export default PasswordChange;
