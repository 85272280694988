import routes from "../../constants/routes";
import axios from "axios";

export type TwilioTokenResponse = {
  token: string;
  agentID: string;
};

type ResponseProps = {
  twilioTokenResponse?: TwilioTokenResponse;
  error?: { message: string };
};

const getTwilioToken = async (userId: string): Promise<ResponseProps> => {
  try {
    const twilioToken = await axios.get<TwilioTokenResponse>(
      routes.get.twilio.tokenNest + `/${userId}`
    );
    return { twilioTokenResponse: twilioToken.data };
  } catch {
    return { error: { message: "Error getting the twilio token for user" } };
  }
};

export default getTwilioToken;
