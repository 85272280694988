import React, { ReactNode, FC } from "react";
import styled from "styled-components";
import { theme } from "../theme";

const { colors } = theme;
const { lightBlue, darkGray } = colors;

const Card = styled.section<{
  width?: string;
}>`
  border-radius: 0.5rem;
  ${({ width }) => ({ width })}};
  background-color: white;
  color: ${darkGray};
`;

const CardTitle = styled.h3`
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 1em;
  color: white;
  background-color: ${lightBlue};
`;

const CardBody = styled.div<{
  minHeight?: string;
  height?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  ${({ minHeight }) => ({ minHeight })}};
  ${({ height }) =>
    height && {
      height,
      overflowY: "auto",
    }}};
`;

interface Props {
  title: string;
  showTitle?: boolean;
  width?: string;
  minHeight?: string;
  height?: string;
  children: ReactNode | FC;
}

const UICard: FC<Props> = ({
  title,
  showTitle = true,
  width,
  minHeight,
  height,
  children,
}): JSX.Element => {
  const ariaId = `${title.toLowerCase().replace(/\s/g, "")}_header`;
  const aria = {
    a: { "aria-label": title },
    b: { "aria-labelledby": `#${ariaId}` },
  };
  return (
    <Card {...aria[!showTitle ? "a" : "b"]} width={width}>
      {showTitle && <CardTitle id={ariaId}>{title}</CardTitle>}
      <CardBody minHeight={minHeight} height={height}>
        {children}
      </CardBody>
    </Card>
  );
};

export default UICard;
