export const config = {
  url: {
    edgeUrl: "https://api.tendrilconnect.app:20020",
    edgeNestUrl: "https://api.tendrilconnect.app:20050",
    contactsUrl: "https://api.tendrilconnect.app:20010",
    syncUrl: "https://api.tendrilconnect.app:20030",
    contactsNestUrl: "https://api.tendrilconnect.app:20040",
    outreachApi: "https://api.outreach.io",
  },
  sentry: {
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: "local",
  },
  analytics: {
    trackingCode: "G-J0JE3HE888",
    tagManager: "GTM-N2NFZQ6",
  },
  domainProd: "",
};
