import React from "react";
import GenericModal from "../shared/GenericModal";
import { Text } from "../../core/ui";
import { StyledButton } from "../shared/FormAndFields";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const NavDisabledModal = ({ isOpen, onClose }: Props): JSX.Element => (
  <GenericModal
    isOpen={isOpen}
    onClose={onClose}
    width="500px"
    padding={20}
    overflow="hidden!important"
  >
    <Text
      fontSize={22}
      fontWeight="600"
      textAlign="center"
      color="black"
      mb={40}
    >
      Navigation disabled
    </Text>
    <Text color="black" mb={30} textAlign="center">
      The navigation is disabled during an active call session.
    </Text>
    <StyledButton
      onClick={onClose}
      bg={"lightBlue"}
      px={25}
      py={12}
      mt={12}
      width={"100%"}
    >
      <Text fontSize={16} fontWeight="600" color="white">
        I understand
      </Text>
    </StyledButton>
  </GenericModal>
);

export default NavDisabledModal;
