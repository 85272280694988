import routes from "../../constants/routes";
import axios from "axios";
import { User } from "../../types";
import { ApiResponse } from "../../types/ApiResponse";

const deleteUserAsync = async (userId: string): Promise<ApiResponse<User>> => {
  try {
    const user = await axios.delete<User>(
      `${routes.delete.users.userNest}/${userId}`
    );

    return { data: user.data, success: true };
  } catch {
    return {
      success: false,
      error: { message: "Error trying to delete user" },
    };
  }
};

export default deleteUserAsync;
