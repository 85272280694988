import routes from "../../constants/routes";
import axios from "axios";
import { NumberPool } from "../../types";

type ResponseProps = {
  numberPool?: NumberPool;
  error?: { message: string };
};

const updateNumberAsync = async (
  numberData: NumberPool
): Promise<ResponseProps> => {
  try {
    const number = await axios.put<NumberPool>(
      `${routes.put.numbers.updateNumber}/${numberData._id}`,
      numberData
    );
    return { numberPool: number.data };
  } catch {
    return { error: { message: "Error trying to update number" } };
  }
};

export default updateNumberAsync;
