import routes from "../../constants/routes";
import axios from "axios";
import { NumberPool } from "../../types";

type ResponseProps = {
  numberPool?: NumberPool;
  error?: { message: string };
};

const createNumberAsync = async (
  numberData: NumberPool
): Promise<ResponseProps> => {
  try {
    const numberResponse = await axios.post<NumberPool>(
      `${routes.post.numbers.create}`,
      numberData
    );
    return { numberPool: numberResponse.data };
  } catch (e) {
    return { error: { message: "Error trying to create number" } };
  }
};

export default createNumberAsync;
