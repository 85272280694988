import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  width: 100%;
`;

const DataContainer = styled.div`
  padding: 1rem 2rem;
  text-align: center;
  align-content: center;
  color: ${({ theme }) => theme.colors.darkGray};
`;

const ValueContainer = styled.div`
  font-size: 35px;
  margin: 5px;
`;

const Title = styled.span`
  font-weight: 600;
`;
interface Props {
  extraData: any;
  show: boolean;
}

const Collapsible = ({ extraData, show }: Props): JSX.Element => {
  const data = extraData || [];

  return (
    <Container>
      {data.map((item, index) => (
        <DataContainer key={index}>
          <div>
            <img
              width={"30px"}
              src={item.image}
              alt="icon"
              style={{
                margin: "5px",
                fill: "#A6A8AA",
                gridColumn: 1,
                alignSelf: "center",
              }}
            />
          </div>
          <Title>{`${item.text}`}</Title>{" "}
          <ValueContainer>{`${item.value}`}</ValueContainer>
        </DataContainer>
      ))}
    </Container>
  );
};

export default Collapsible;
