type Columns = {
  phone: string;
  name: string;
  jobTitle: string;
  accountName: string;
  email: string;
  crmId: string;
  notes: string;
  ssid: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  grade: string;
  returningSchool: string;
  leavingReason: string;
  firstParentGuardianName: string;
  firstParentGuardianLastName: string;
  firstParentGuardianEmail: string;
  firstParentGuardianPhone: string;
  firstParentGuardianRelationChild: string;
  secondParentGuardianName: string;
  secondParentGuardianLastName: string;
  secondParentGuardianEmail: string;
  secondParentGuardianPhone: string;
  secondParentGuardianRelationChild: string;
  phoneTwo: string;
  contactNameTwo: string;
  homeAddress: string;
  homeLineTwo: string;
  homeCity: string;
  homeZip: string;
  transportationStatus: string;
  amAddress: string;
  lineTwo: string;
  amCity: string;
  amZip: string;
  pmAddress: string;
  pmLineTwo: string;
  pmCity: string;
  pmZip: string;
  daysOfWeek: string;
  incomingSibling: string;
  additionalNotes: string;
};

export default class ColumnsValidator {
  private exampleColumns: Columns;
  private errorColumns: string;
  constructor() {
    this.exampleColumns = {
      name: "Jon Doe",
      phone: "000-000-0000",
      jobTitle: "Point of Contact",
      email: "example@mail.com",
      accountName: "Tendril LLC",
      notes: "This is a note",
      crmId: "123456-Id",
      ssid: "HBA_14683",
      firstName: "Anzal",
      lastName: "Abdi",
      dateOfBirth: "04/12/2012",
      gender: "F",
      grade: "2",
      returningSchool: "Yes",
      leavingReason: "",
      firstParentGuardianName: "Kossar",
      firstParentGuardianLastName: "Ismail",
      firstParentGuardianEmail: "kossar@example.com",
      firstParentGuardianPhone: "000-000-0000",
      firstParentGuardianRelationChild: "Mother",
      secondParentGuardianName: "Ali",
      secondParentGuardianLastName: "Kossar",
      secondParentGuardianEmail: "ali@example.com",
      secondParentGuardianPhone: "000-000-0000",
      secondParentGuardianRelationChild: "Father",
      phoneTwo: "000-000-0000",
      contactNameTwo: "Abdul",
      homeAddress: "1808 University Ave NE,326",
      homeLineTwo: "1806 University",
      homeCity: "Minniapolis",
      homeZip: "55418",
      transportationStatus: "Not Riding",
      amAddress: "",
      lineTwo: "",
      amCity: "",
      amZip: "",
      pmAddress: "",
      pmLineTwo: "",
      pmCity: "",
      pmZip: "",
      daysOfWeek: "Monday",
      incomingSibling: "Yes",
      additionalNotes: "some notes",
    };
    this.errorColumns = "";
  }

  validate(data: Columns): boolean | string {
    for (const column in data) {
      if (!this.exampleColumns.hasOwnProperty(column)) {
        this.errorColumns += `${column}, `;
      }
    }
    if (this.errorColumns !== "")
      return `Columns ${this.errorColumns} are not valid.`;
    return true;
  }
}
