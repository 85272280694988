import React, { useState, useContext, useEffect, Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/Login/Login";
import Contacts from "./pages/Contacts/Contacts";
import NotFound from "./pages/NotFound/NotFound";
import Voice from "./pages/Voice/Voice";
import Script from "./pages/Script/Script";
import TestComponents from "./pages/TestComponents";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Dashboard from "./pages/Dashboard/Dashboard";
import PasswordRecover from "./pages/Login/PasswordRecover";
import PasswordChange from "./pages/Login/PasswordChange";
import UILoader from "./core/ui/UILoader";

import { Device } from "twilio-client";
import { CallSessionSuccessResponse } from "./components/shared/CallSession";
import Management from "./pages/Management/Management";
import Settings from "./pages/Settings/Settings";
import { UserProviderContext } from "./components/app/UserProvider";

import * as Sentry from "@sentry/react";
import { useAppContext } from "./libs/contextLib";
import { featuresStore } from "./components/app/FeaturesProvider";
import Summary from "./pages/Dashboard/Summary";

const DialerSDR = lazy(() => import("./pages/Dialer/DialerSDR"));
const DialerAgent = lazy(() => import("./pages/Dialer/DialerAgent"));

interface Props {
  isSDRorAdmin: boolean;
  userSession: string;
  handleLogout: () => void;
  fourMativ: (string) => void;
}

const Routes = ({
  isSDRorAdmin,
  userSession,
  handleLogout,
}: Props): JSX.Element => {
  const [callSessionStatus, setCallSessionStatus] = useState<string>("");
  const [currentCallSession, setCurrentCallSession] = useState<
    CallSessionSuccessResponse | null | undefined
  >(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [twilioDevice, setTwilioDevice] = useState<Device>({} as Device);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [intervals, setIntervals] = useState<any>({});
  const { currentUser, setCurrentUser } = useContext(UserProviderContext);
  const { isAuthenticated } = useAppContext();
  const { fetchFeatures } = useContext(featuresStore);

  const handleRoutesLogout = () => {
    // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
    Sentry.configureScope((scope) => scope.setUser(null));
    setCallSessionStatus("");
    setTwilioDevice({} as Device);
    handleLogout();
    setIsFirstRender(true);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const userSessionJson = JSON.parse(userSession);
      const { organizationId } = userSessionJson;
      setCurrentUser(userSessionJson);
      fetchFeatures(organizationId);
    }
  }, [userSession]);

  return (
    <Switch>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/password/recover">
        <PasswordRecover />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/password/change">
        <PasswordChange />
      </UnauthenticatedRoute>
      <AuthenticatedRoute path="/home">
        <Suspense fallback={<UILoader message="Preparing workspace..." />}>
          {isSDRorAdmin ? (
            <DialerSDR
              userSession={userSession}
              handleLogout={handleRoutesLogout}
              callSessionStatus={callSessionStatus}
              changeCallSessionStatus={setCallSessionStatus}
              twilioDevice={twilioDevice}
              setTwilioDevice={setTwilioDevice}
              currentCallSession={currentCallSession}
              setCurrentCallSession={setCurrentCallSession}
              isFirstRender={isFirstRender}
              setIsFirstRender={setIsFirstRender}
              intervals={intervals}
              setIntervals={setIntervals}
            />
          ) : (
            <DialerAgent
              userSession={userSession}
              handleLogout={handleRoutesLogout}
              setTwilioDevice={setTwilioDevice}
              twilioDevice={twilioDevice}
              intervals={intervals}
              setIntervals={setIntervals}
              feature={fetchFeatures}
            />
          )}
        </Suspense>
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/contacts">
        <Contacts
          userSession={userSession}
          handleLogout={handleRoutesLogout}
          intervals={intervals}
          setIntervals={setIntervals}
        />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/voice">
        <Voice
          userSession={userSession}
          handleLogout={handleRoutesLogout}
          setTwilioDevice={setTwilioDevice}
          twilioDevice={twilioDevice}
          intervals={intervals}
          setIntervals={setIntervals}
        />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/script">
        <Script
          userSession={userSession}
          handleLogout={handleRoutesLogout}
          intervals={intervals}
          setIntervals={setIntervals}
        />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/summary/:callsessionid">
        <Summary
          userSession={userSession}
          handleLogout={handleRoutesLogout}
          intervals={intervals}
          setIntervals={setIntervals}
        />
      </AuthenticatedRoute>
      {currentUser.isAdmin && (
        <AuthenticatedRoute exact path="/management">
          <Management
            handleLogout={handleRoutesLogout}
            intervals={intervals}
            setIntervals={setIntervals}
            feature={fetchFeatures}
          />
        </AuthenticatedRoute>
      )}
      {isSDRorAdmin && (
        <>
          <AuthenticatedRoute exact path="/dashboard">
            <Dashboard
              userSession={userSession}
              handleLogout={handleRoutesLogout}
              intervals={intervals}
              setIntervals={setIntervals}
            />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/settings">
            <Settings
              userSession={userSession}
              handleLogout={handleRoutesLogout}
              intervals={intervals}
              setIntervals={setIntervals}
            />
          </AuthenticatedRoute>
        </>
      )}

      <UnauthenticatedRoute exact path="/">
        <Redirect to="/home" />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/test">
        <TestComponents />
      </UnauthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
