import React, { useState } from "react";
import styled from "styled-components";
import { useCSVDownloader } from "react-papaparse";
import { useCSVReader } from "react-papaparse";
import Csv from "../../assets/icons/CSV.svg";
import { UIInputText } from "../../core/ui/UIElements";
import { theme } from "../../core/theme";
import fourMativTemplate from "./FourMativTemplate";
import ColumnsValidator from "../../core/utils/ColumnsValidator";
import { showUIToast } from "../../core/ui/UIToast";
const CSVContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 100%;
  .csv-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

interface Props {
  width?: string;
  height?: string;
  handleOnDrop: (value, valueB) => void;
  handleOnError: () => void;
  handleOnRemoveFile: () => void;
  setContactListName: (value) => void;
  fourMativ: boolean;
}

const NewCSV: React.FC<Props> = ({
  width,
  handleOnDrop,
  handleOnError,
  handleOnRemoveFile,
  setContactListName,
  fourMativ,
  height,
}) => {
  const [title, setTitle] = useState("");

  const validateTitle = () => {
    if (title === "") {
      showUIToast({ type: "info", text: "List name is required." });
    }
  };
  const handleOnErrorColumns = (resultValidator) => {
    showUIToast({ type: "error", text: resultValidator });
  };
  const validateColumns = (data) => {
    const columnsValidator = new ColumnsValidator();
    return columnsValidator.validate(data);
  };

  const handleChangeInput = (e) => {
    setContactListName(e.target.value);
    setTitle(e.target.value);
  };

  const { CSVReader } = useCSVReader();
  const { CSVDownloader } = useCSVDownloader();

  const StyledCSVReader = styled(CSVReader)`
    text-align: center;
  `;

  const StyledCSVDownloader = styled(CSVDownloader)`
    color: ${theme.colors.lightBlue};
    text-decoration: underline;
    margin-top: auto;
    background-color: transparent;
    pointer: cursor;
    &:hover,
    &:focus {
      color: ${theme.colors.darkBlue};
    }
  `;

  return (
    <CSVContainer>
      <UIInputText
        data-qa-id={`input-contact-list-name`}
        type="text"
        placeholder="List name"
        onChange={handleChangeInput}
      />
      <div
        data-qa-id="upload-contact-list-file"
        onClick={validateTitle}
        onDropCapture={validateTitle}
      >
        <StyledCSVReader
          onUploadAccepted={(results: any) => {
            const resultValidator = validateColumns(results.data[0]);
            if (typeof resultValidator === "string") {
              handleOnErrorColumns(resultValidator);
            } else {
              title.length > 0 ? handleOnDrop(results.data, null) : null;
            }
          }}
          onError={handleOnError}
          onDragOver={(event: DragEvent) => {
            event.preventDefault();
          }}
          onDragLeave={(event: DragEvent) => {
            event.preventDefault();
          }}
          onRemoveFile={handleOnRemoveFile}
          addRemoveButton
          noClick={title.length > 0 ? false : true}
          style={{
            dropArea: {
              height: "100px",
              borderColor: "#fff",
            },
            dropFile: {
              height: "100px",
              background: "#ccc",
            },
          }}
          config={{
            header: true,
            skipEmptyLines: true,
            delimiter: ",",
          }}
        >
          {({ getRootProps }: any) => (
            <div
              {...getRootProps()}
              style={Object.assign({})}
              className="csv-inner"
            >
              <img src={Csv} alt="CSV image" width="90" />
              <div>
                Drop CSV file here or click to <u>upload.</u>
              </div>
            </div>
          )}
        </StyledCSVReader>
      </div>
      <div data-qa-id={"downloadTemplateBtn"}>
        <StyledCSVDownloader
          type="button"
          filename={"template"}
          data={() => {
            return [
              fourMativ
                ? fourMativTemplate
                : {
                    name: "Jon Doe",
                    phone: "000-000-0000",
                    jobTitle: "Point of Contact",
                    email: "example@mail.com",
                    accountName: "Tendril LLC",
                    notes: "This is a note",
                    crmId: "123456-Id",
                  },
            ];
          }}
        >
          Download the contact list template
        </StyledCSVDownloader>
      </div>
    </CSVContainer>
  );
};

export default NewCSV;
