const backgroundGradient = (colors: string[], direction: string): string =>
  `background-image: linear-gradient(${direction}, ${colors});`;

const maxMediaWidth = (width: number, rules: string): string =>
  `@media screen and (max-width: ${width}px) {
    ${rules}
  }`;

const minMediaWidth = (width: number, rules: string): string =>
  `@media screen and (min-width: ${width}px) {
    ${rules}
  }`;

const mask = (pseudo: string, rules: string): string => `
  position: relative;
  &:${pseudo}{
    position: absolute;
    display: block;
    content: '';
    ${rules}
  }
`;

export { backgroundGradient, maxMediaWidth, minMediaWidth, mask };
