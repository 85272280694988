import React from "react";
import styled from "styled-components";
import Recording from "../../assets/icons/recording.svg";
import { UIInputText } from "../../core/ui/UIElements";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { UpdateRecordingDto } from "../../types/Recording";

import { theme } from "../../core/theme";

const { colors } = theme;

const RecordButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  font-size: 2.5rem;
  background-color: transparent;
  background-image: url(${Recording});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid ${colors.lightGray};
  border-radius: 0.25rem;
  svg {
    border-radius: 50%;
    border: 0.15em solid ${colors.lighterBlue};
    background-color: white;
    color: ${colors.lightBlue};
  }
  &.recording svg {
    color: white;
    background-color: red;
  }
`;

interface Props {
  handleBtn: () => void;
  setRecordingTitle: (value) => void;
  isRecording: boolean;
  recording?: UpdateRecordingDto;
}

const NewVoicemail: React.FC<Props> = ({
  handleBtn,
  setRecordingTitle,
  isRecording,
  recording,
}) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    setRecordingTitle(value);
  };

  return (
    <>
      <UIInputText
        data-qa-id={"nameVoiceInput"}
        type="text"
        name="title"
        placeholder={"Voicemail Name"}
        onChange={handleInputChange}
      />
      <RecordButton
        data-qa-id={"recordButton"}
        onClick={handleBtn}
        className={`${isRecording && "recording"}`}
      >
        <FA icon={["fas", `${isRecording ? "circle-pause" : "circle-dot"}`]} />
      </RecordButton>
    </>
  );
};

export default NewVoicemail;
