import React from "react";
import GenericModal from "../../shared/GenericModal";
import { Box, Flex, Text, Button } from "../../../core/ui";
import deleteUserAsync from "../../../api/Users/deleteUserAsync";
import { User } from "../../../types";
import { showUIToast } from "../../../core/ui/UIToast";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedUser: User;
  onSuccess: () => void;
};

const DeleteModal = ({
  isOpen,
  onClose,
  selectedUser,
  onSuccess,
}: Props): JSX.Element => {
  const handleDeleteUser = async () => {
    const { data, success, error } = await deleteUserAsync(
      selectedUser._id || selectedUser.id
    );
    if (data && success) {
      showUIToast({ type: "info", text: "User deleted." });
      onSuccess();
      return;
    }
    !success && error && showUIToast({ type: "error", text: error.message });
  };
  return (
    <GenericModal isOpen={isOpen} onClose={onClose} padding="50px 20px 20px">
      <Box>
        <Text
          fontSize={22}
          fontWeight="600"
          textAlign="center"
          color="black"
          lineHeight="28px"
          mb={32}
        >
          Are you sure you want to delete
          <br />
          {`${selectedUser?.name.split(" ")[0]}'s account?`}
        </Text>
        <Flex gap="16px" justifyContent="center" mb={24}>
          <Button bg="lightBlue" p="12px 40px" onClick={onClose}>
            <Text color="white" fontWeight="600" fontSize={15}>
              No
            </Text>
          </Button>
          <Button bg="darkGray" p="12px 40px" onClick={handleDeleteUser}>
            <Text color="white" fontWeight="600" fontSize={15}>
              Yes
            </Text>
          </Button>
        </Flex>
      </Box>
    </GenericModal>
  );
};

export default DeleteModal;
