import React, { ReactNode, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import routes from "../../../constants/routes";
import { UserProviderContext } from "../../../components/app/UserProvider";
import axios from "axios";
import hubspotLogo from "./hubspot.svg";
import { UIMiniButton } from "../../../core/ui/UIElements";
import Loader from "../../../core/ui/UILoader";
import { Account } from "../Shared/Entities/Account";
import { showUIToast } from "../../../core/ui/UIToast";

const HubspotContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-height: 395px;
`;

export const Hubspot = ({
  children,
  accounts,
  callback,
}: {
  children: ReactNode;
  accounts: Account[];
  callback: (user: Account[]) => any;
}): JSX.Element => {
  const { currentUser } = useContext(UserProviderContext);
  const [state, dispatch] = useState<{
    ready: boolean;
    configuring: boolean;
    message: string;
    loading: boolean;
    loadingMessage: string;
  }>({
    ready: true,
    configuring: false,
    message: "Use button above to connect Hubspot.",
    loading: true,
    loadingMessage: "Loading linked accounts",
  });

  useEffect(() => {
    if (accounts.length === 0) {
      changeMessage("No accounts linked.");
    } else {
      changeMessage(
        `You have ${accounts.length} ${
          accounts.length === 1 ? "account" : "accounts"
        } linked.`
      );
    }
  }, [children]);

  const handleConnect = async () => {
    try {
      const url = `${routes.post.hubspot.oAuthLogin}/${currentUser.organizationId}/${currentUser.userId}`;
      const res = await axios.post<{
        url: string;
      }>(url);
      if (res.status === 201) {
        window.open(res.data.url, "hubspotPopup", `width=${500},height=${500}`);
        getAccountsInterval();
        showUIToast({
          type: "default",
          text: "Hubspot connected",
        });
        return dispatch((state) => ({
          ...state,
          configuring: true,
        }));
      }
    } catch (err) {
      hubspotError();
    }
  };

  const hubspotError = () => {
    showUIToast({
      type: "error",
      text: "Can't configure Hubspot right now, please try again later.",
    });
    return dispatch((state) => ({
      ...state,
      ready: false,
      message: "Can't configure Hubspot right now, please try again later.",
    }));
  };

  const changeMessage = (message: string) =>
    dispatch((state) => ({ ...state, message }));

  const handleCancel = () => {
    // TODO: stop current configuration and show the corresponding ui toast messages
    dispatch((state) => ({
      ...state,
      configuring: false,
      message: "Use button above to connect Hubspot.",
    }));
  };

  const getAccounts = async (wipeInterval?: () => void) => {
    try {
      callback([]);
      const listenUrl = `${routes.get.hubspot.getLinkedAccounts}/${currentUser.organizationId}/${currentUser.userId}`;
      const res = await axios.get<Account[]>(listenUrl);
      if (res.status === 202) {
        showUIToast({
          type: "info",
          text: "Hubspot syncing in progress.",
        });
        return dispatch((state) => ({
          ...state,
          configuring: true,
          message: "Configuration in process.",
        }));
      }
      if (res.status === 204) {
        if (wipeInterval) wipeInterval();
        dispatch((state) => ({
          ...state,
          configuring: false,
        }));
        showUIToast({
          type: "info",
          text: "Hubspot synced.",
        });
        return changeMessage("Nothing was configured.");
      }
      if (res.status === 200) {
        if (wipeInterval) wipeInterval();
        const amount = res.data.length || 0;
        changeMessage(
          `You have ${amount} ${amount > 1 ? "account" : "accounts"} linked.`
        );
        dispatch((state) => ({
          ...state,
          loading: false,
          configuring: false,
        }));
        showUIToast({
          type: "info",
          text: `HubSpot linked with ${amount} ${
            amount > 1 ? "account" : "accounts"
          }.`,
        });
        return callback(res.data || []);
      }
    } catch (err) {
      if (wipeInterval) wipeInterval();
      dispatch((state) => ({
        ...state,
        loading: false,
      }));
      console.log(err);
      return changeMessage("No accounts linked.");
    }
  };

  const getAccountsInterval = () => {
    const interval = setInterval(
      () => getAccounts(() => clearInterval(interval)),
      100
    );
  };

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <HubspotContainer>
      {state.loading ? (
        <Loader message={state.loadingMessage} />
      ) : (
        <>
          <UIMiniButton
            className="hubspot"
            style={{ justifySelf: "center" }}
            disabled={state.configuring}
            onClick={handleConnect}
          >
            <img alt="Hubspot" src={hubspotLogo} width="120" />
          </UIMiniButton>
          {state.configuring && (
            <UIMiniButton onClick={handleCancel}>
              Cancel Configuration
            </UIMiniButton>
          )}
          {children}
          <div>{state.message}</div>
        </>
      )}
    </HubspotContainer>
  );
};
