import React, { useEffect, useState, useContext, ReactElement } from "react";
import Layout from "../../components/shared/Layout";
import { GenericTableHeader } from "../../components/shared/GenericTable";
import NewCSV from "../../components/newCSV/NewCSV";
import axios, { AxiosError } from "axios";
import routes from "../../constants/routes";
import { jsonToCSV } from "react-papaparse";
import { useHistory, useLocation } from "react-router-dom";
import {
  CallSession,
  LocationState,
} from "../../components/shared/CallSession";
import { checkAndRedirectCallSession } from "../../core/utils/redirectCallSession";
import getSequencesStepsFromOutreachAsync from "../../api/Sync/getSequenceStepsFromOutreachAsync";
import { UITabbedContainer, Tab } from "../../core/ui/UITabbedContainer";
import UICard from "../../core/ui/UICard";
import GenericList from "../../components/shared/GenericList";
import { UIButton } from "../../core/ui/UIElements";
import { featuresStore } from "../../components/app/FeaturesProvider";

import { Sequence, SequenceStep } from "../../types/OutreachTypes";
import { UserProviderContext } from "../../components/app/UserProvider";
import Loader from "../../core/ui/UILoader";
import styled from "styled-components";
import UITable from "../../core/ui/UITable";
import tabTitle from "../../utils/updateTitle";
import { bodyFourMativ, headersFourmativ } from "./FourmativData";
import getListsFromHubspotAsync from "../../api/Sync/getListsFromHubspotAsync";
import getHubspotAccountsAsync from "../../api/Sync/getHubspotAccountsAsync";
import getOutreachAccountsAsync from "../../api/Sync/getOutreachAccountsAsync";
import { HubspotContact, HubspotList } from "../../types/HubspotTypes";
import getHubspotContactsAsync from "../../api/Sync/getHubspotContactsAsync";
import { showUIToast } from "../../core/ui/UIToast";
import deleteContactAsync from "../../api/Contacts/deleteContactAsync";
import getUser, { UserResponseProps } from "../../api/Users/getUser";

const headers: GenericTableHeader[] = [
  { text: "Name", width: "2" },
  { text: "Company", width: "2" },
  { text: "Position", width: "2" },
  { text: "Phone Number", width: "2" },
  { text: "Notes", width: "1.5" },
];

const tableBodies = [`name`, `accountName`, `jobTitle`, `phone`, `notes`];

interface Type {
  type: string;
}

interface OrgIdSuccessResponse {
  name: string;
  email: string;
  organizationId: string;
  userType: Type[];
  id: string;
}

interface ContactsListI {
  id: string;
  organizationId: string;
  title: string;
}

interface ContactInterface {
  accountName: string;
  contactListId: string;
  email: string;
  id: string;
  jobTitle: string;
  name: string;
  phone: string;
  crmId: string;
  notes: string;
  notesAgent: string;
  notesSDR: string;
}

type ContactList = {
  id: string;
  contactListId: string;
  completed: boolean;
};

interface Props {
  userSession: string;
  handleLogout: () => void;
  intervals: { [key: string]: any };
  setIntervals: (value: any) => void | undefined;
}

const TextContainer = styled.div`
  display: grid;
  place-items: center;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1rem;
  font-weight: 600;
  margin: auto;
`;

const StyledSpan = styled.span`
  margin-bottom: 1rem;
`;

const ContactsCounter = styled.div`
  width: 100%;
  height: 20px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: right;
`;

const Contacts = ({
  userSession,
  handleLogout,
  intervals,
  setIntervals,
}: Props): JSX.Element => {
  const { outreach, fourMativ, hubspot, hubspotCustom } =
    useContext(featuresStore).featuresState;
  const [callSession, setCallSession] = useState<CallSession>(
    {} as CallSession
  );
  const history = useHistory();
  const location = useLocation();
  const userSessionJSON = JSON.parse(userSession);
  const [isSession, setIsSession] = useState(true);
  const [orgId, setOrgId] = useState<string | undefined>("");
  const [contactsList, setContactsList] = useState<ContactsListI[]>([]);
  const [visibleNewCSV, setVisibleNewCSV] = useState(false);
  const [visibleTable, setVisibleTable] = useState(false);
  const [contacts, setContacts] = useState<ContactInterface[]>([]);
  const [optionSelected, setOptionSelected] = useState([] as any[]);
  const [contactListName, setContactListName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [newFile, setNewFile] = useState("");
  const [selectedRow, setSelectedRow] = useState([]); //Queda pendiente en lo que veo como resolver conflicto
  const [btnText, setBtnText] = useState("Upload");
  const [showButton, setShowButton] = useState(false);
  const [tableHeaders, setTableHeaders] = useState(
    fourMativ ? headersFourmativ : headers
  );
  const [tableBody, setTableBody] = useState(
    fourMativ ? bodyFourMativ : tableBodies
  );
  const [loadingStatus, updateLoadingStatus] = useState<{
    sequences: boolean;
    contacts: boolean;
  }>({ sequences: false, contacts: false });
  const [label, setLabel] = useState<string | ReactElement>(
    "Select a sequence to show"
  );
  const [errorMessage, setError] = useState("");
  const [outreachSequenceSteps, dispatch] = useState([] as Sequence[]);
  const [hubspotSequences, setLists] = useState([] as HubspotList[]);

  const setSequenceSteps = (sequences: any[]) => dispatch(sequences);

  const setHubspotLists = (sequences: any[]) => setLists(sequences);

  const { currentUser } = useContext(UserProviderContext);

  if (window.location.search === "?setup=1") {
    tabTitle("Tendril Connect | Contacts - setup");
  } else {
    tabTitle("Tendril Connect | Contacts");
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = location.state as LocationState;

    const setup = urlParams.get("setup");
    if (setup && setup === "1" && state) {
      setCallSession(state.callSession);
    } else {
      history.push({
        pathname: "/contacts",
      });
      setIsSession(false);
    }
  }, [callSession, location.state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const actualUser: UserResponseProps = await getUser(
          userSessionJSON.userId
        );
        const organizationId = actualUser.user?.organizationId;
        setOrgId(organizationId);
        const contactsListUrl = `${routes.get.contacts.contactListsNest}/${organizationId}`;
        const contactListId = await axios.get<ContactsListI[]>(contactsListUrl);
        setContactsList(contactListId.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 404) {
            setVisibleNewCSV(true);
          } else {
            showUIToast({
              type: "error",
              text: "Something went wrong, try again later.",
            });
          }
        }
      }
    };
    fetchData();
  }, [userSessionJSON.userId]);

  useEffect(() => {
    if (optionSelected.length) {
      const found = optionSelected.some((el) => {
        if (el !== undefined) {
          if (el.id === "newItem" && el.select === true) {
            return el;
          }
        }
      });
      if (found) {
        setVisibleTable(false);
        setVisibleNewCSV(true);
        setShowButton(true);
      } else {
        optionSelected.map(async (item) => {
          if (item !== undefined) {
            if (item.select === false) {
              setVisibleNewCSV(false);
              setVisibleTable(false);
              setDisabled(true);
              setShowButton(false);
            }
            if (item.select === true && item.id !== "newItem") {
              let contactsResponse: ContactInterface[] = [];
              if (item.tendrilId) {
                if (item.type === "OUTREACH") {
                  contactsResponse = await getAllOutreachContacts(item);
                } else if (item.type === "CUSTOM_HUBSPOT") {
                  contactsResponse = await getAllHubspotContacts(item, true);
                } else {
                  contactsResponse = await getAllHubspotContacts(item);
                }
              } else {
                contactsResponse = await getAllContacts(item);
              }
              if (contactsResponse.length === 0) {
                handleEmptyList("There are no contacts for selected list");
                setContacts([]);
              } else {
                setContacts(contactsResponse);
              }
              setVisibleNewCSV(false);
              setVisibleTable(true);
              if (isSession) {
                setShowButton(true);
              } else {
                setShowButton(false);
              }
              updateLoadingStatus({ ...loadingStatus, contacts: false });
            }
          }
        });
      }
    }
  }, [optionSelected]);

  useEffect(() => {
    const validateInput = () => {
      optionSelected.forEach((item) => {
        if (item !== undefined) {
          if (item.id !== "newItem" && item.select === true) {
            setBtnText("Upload");
            setDisabled(false);
          }
          if (item.id === "newItem" && item.select === true) {
            setBtnText("Save");
            if (contactListName.length > 0 && contacts.length > 0) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          }
        }
      });
    };
    validateInput();
  }, [contactListName, optionSelected, contacts]);

  const selectOutreachContactsCallback = async () => {
    const titles: string[] = [];
    const viewContactList: ContactInterface[] = [];
    const updatedCallSession = callSession;

    const selectedItem = optionSelected.find((option) => {
      return option.select;
    });

    if (selectedItem) {
      titles.push(selectedItem.title);
      let gotContacts: ContactInterface[] = [];
      gotContacts = await createOutreachList(selectedItem);
      updateLoadingStatus({ ...loadingStatus, contacts: false });
      const previewContacts = gotContacts.slice(0, 3);
      previewContacts.forEach((i) => {
        viewContactList.push(i);
      });
      const contactList = {
        id: selectedItem.id,
        contactListId: previewContacts[0].contactListId,
        completed: false,
      };
      updatedCallSession.contactList = [contactList];
      updatedCallSession.contactListName = titles as [string];
      updatedCallSession.totalContacts = gotContacts.length;
      updatedCallSession.previewContacts = viewContactList as [
        ContactInterface
      ];

      checkAndRedirectCallSession(callSession, history);

      showUIToast({ type: "info", text: "Contact list selected." });
    }
  };

  const selectHubspotContactsCallback = async (custom = false) => {
    const titles: string[] = [];
    const viewContactList: ContactInterface[] = [];
    const updatedCallSession = callSession;

    const selectedItem = optionSelected.find((option) => {
      return option.select;
    });

    if (selectedItem) {
      titles.push(selectedItem.title);
      let hubspotContacts: ContactInterface[] = [];
      hubspotContacts = await createHubspotList(selectedItem, custom);
      updateLoadingStatus({ ...loadingStatus, contacts: false });
      const previewContacts = hubspotContacts.slice(0, 3);
      previewContacts.forEach((i) => {
        viewContactList.push(i);
      });
      const contactList = {
        id: selectedItem.id,
        contactListId: previewContacts[0].contactListId,
        completed: false,
      };
      updatedCallSession.contactList = [contactList];
      updatedCallSession.contactListName = titles as [string];
      updatedCallSession.totalContacts = hubspotContacts.length;
      updatedCallSession.previewContacts = viewContactList as [
        ContactInterface
      ];

      checkAndRedirectCallSession(callSession, history);

      showUIToast({ type: "info", text: "Contact list selected." });
    }
  };

  const selectContactsCallback = () => {
    const found = optionSelected.some((el) => {
      if (el !== undefined) {
        if (el.id === "newItem" && el.select === true) {
          return el;
        }
      }
    });

    const aux: ContactList[] = [];
    const titles: string[] = [];
    let totalContacts = 0;
    const viewContactList: ContactInterface[] = [];

    if (found) {
      uploadFiles(contactListName);
    } else {
      optionSelected.map(async (item) => {
        if (item !== undefined) {
          if (item.id !== "newItem" && item.select === true) {
            aux.push(item);
            titles.push(item.title);
            let gotContacts: ContactInterface[] = [];
            gotContacts = await getAllContacts(item);
            updateLoadingStatus({ ...loadingStatus, contacts: false });
            totalContacts += gotContacts.length;
            const previewContacts = gotContacts.slice(0, 3);
            previewContacts.forEach((i) => {
              viewContactList.push(i);
            });
            const callSession1 = callSession;
            callSession1.contactList = aux as [ContactList];
            callSession1.contactList.forEach((value) => {
              value.contactListId = gotContacts[0].contactListId;
              value.completed = false;
            });
            callSession1.contactListName = titles as [string];
            callSession1.totalContacts = totalContacts;
            callSession1.previewContacts = viewContactList as [
              ContactInterface
            ];

            checkAndRedirectCallSession(callSession, history);

            showUIToast({ type: "info", text: "Contact list selected." });
          }
        }
      });
    }
  };

  const getAllContacts = async (arr) => {
    try {
      const contactsUrl = `${routes.get.contacts.contactsNest}/${arr.id}`;
      const contactsResponse = await axios.get<ContactInterface[]>(contactsUrl);
      return contactsResponse.data;
    } catch (error) {
      handleEmptyList("There are no contacts for selected contact list");
      throw error;
    }
  };

  const createOutreachList = async (selectedItem) => {
    try {
      updateLoadingStatus({ ...loadingStatus, contacts: true });
      const contactsUrl = `${routes.post.contact.contactsNest}/outreach`;

      const data = {
        organizationId: currentUser.organizationId,
        title: selectedItem.title,
        outreachSequenceId: selectedItem.sequenceId,
        outreachSequenceStep: Number(selectedItem.id),
        nonce: selectedItem.tendrilId,
      };

      const contactsResponse = await axios.post<ContactInterface[]>(
        contactsUrl,
        data
      );

      return contactsResponse.data;
    } catch (error) {
      handleEmptyList("There are no contacts for selected contact list");
      throw error;
    }
  };

  const createHubspotList = async (selectedItem, custom) => {
    try {
      updateLoadingStatus({ ...loadingStatus, contacts: true });
      const contactsUrl = custom
        ? `${routes.post.contact.contactsNest}/hubspotCustom`
        : `${routes.post.contact.contactsNest}/hubspot`;

      const data = {
        organizationId: currentUser.organizationId,
        title: selectedItem.title,
        listId: Number(selectedItem.id),
        nonce: selectedItem.tendrilId,
      };

      const contactsResponse = await axios.post<ContactInterface[]>(
        contactsUrl,
        data
      );

      return contactsResponse.data;
    } catch (error) {
      handleEmptyList("There are no contacts for selected contact list");
      throw error;
    }
  };

  const getAllOutreachContacts = async ({ id, tendrilId, sequenceId }) => {
    setOutreachDisabledButton(true);
    try {
      updateLoadingStatus({ ...loadingStatus, contacts: true });
      const contactsUrl = `${routes.get.outreach.getProspects}/${tendrilId}/${sequenceId}/${id}`;
      const contactsResponse = await axios.get<ContactInterface[]>(contactsUrl);
      setOutreachDisabledButton(false);
      return contactsResponse.data;
    } catch (error) {
      console.log(error);
      handleEmptyList("There are no contacts for selected sequence step");
      throw error;
    }
  };

  const prepareHubspotContactsForList = (contacts: HubspotContact[]) => {
    return contacts.map((contact) => {
      const { vid, properties } = contact;
      const contactListId = contact["portal-id"] ?? "";
      const name = `${properties.firstname?.value || ""} ${
        properties.lastname?.value || ""
      }`;
      return {
        accountName: properties.company?.value || "",
        contactListId,
        email: properties.email?.value || "",
        id: vid,
        jobTitle: properties.jobtitle?.value || "",
        name,
        phone: properties.mobilephone?.value || properties.phone?.value || "",
        crmId: "",
        notes: "",
        notesAgent: "",
        notesSDR: "",
      };
    });
  };

  const getAllHubspotContacts = async ({ id, tendrilId }, custom = false) => {
    setHubspotDisabledButton(true);
    try {
      updateLoadingStatus({ ...loadingStatus, contacts: true });
      const { contacts, error } = await getHubspotContactsAsync(
        tendrilId,
        id,
        custom
      );

      if (contacts && contacts.length > 0) {
        let contactsResponse: ContactInterface[] = [];
        contactsResponse = prepareHubspotContactsForList(contacts);
        setHubspotDisabledButton(false);
        return contactsResponse;
      } else {
        handleEmptyList("There are no contacts for selected list");
        throw error;
      }
    } catch (error) {
      console.log(error);
      handleEmptyList("There are no contacts for selected list");
      throw error;
    }
  };

  const handleEmptyList = (label: string) => {
    updateLoadingStatus({ ...loadingStatus, contacts: false });
    setLabel(label);
    setVisibleTable(false);
    setVisibleNewCSV(false);
    showUIToast({
      type: "warning",
      text: label,
    });
  };

  const handleNoAccounts = () => {
    return (
      <>
        <StyledSpan>There are no accounts linked to this profile.</StyledSpan>
        <StyledSpan>To link an account go to settings.</StyledSpan>
        <UIButton onClick={goToSettings}>Settings</UIButton>
      </>
    );
  };

  const goToSettings = () => {
    history.push("/settings");
  };

  const uploadFiles = async (contactListName) => {
    try {
      const contactListPayload = {
        organizationId: orgId,
        title: contactListName,
        type: fourMativ ? "FOURMATIV" : "CSV",
        nonce: fourMativ ? "FOURMATIV" : "CSV",
      };

      const contactListResponse = await axios.post(
        routes.post.contact.contactsListNest,
        contactListPayload
      );

      if (
        contactListResponse.status === 200 ||
        contactListResponse.status === 201
      ) {
        showUIToast({ type: "success", text: "A new list was created." });
        const url = `${routes.post.contact.contactsNest}/${contactListResponse.data.id}`;
        const blob = new Blob(["\ufeff", newFile]);
        const formData = new FormData();
        formData.append("contactListId", contactListResponse.data.id);
        formData.append("file", blob);
        fourMativ &&
          formData.append("toggleFeature", JSON.stringify({ fourMativ: true }));
        const fileReq = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (fileReq.status === 200 || fileReq.status === 201) {
          showUIToast({
            type: "success",
            text: `${contacts.length} new contacts were added.`,
          });
          const contactListFiltered = contactsList.filter(
            (item) => item.id !== "newItem"
          );
          setContactsList([contactListResponse.data, ...contactListFiltered]);
        }
      }
    } catch (error) {
      showUIToast({
        type: "error",
        text: "Something went wrong, try again later.",
      });
    }
  };

  const handleDeleteContactList = async (element) => {
    setOptionSelected([]);
    try {
      const { id } = element;

      if (id) {
        const deleteContactListUrl = `${routes.delete.contacts.deleteContactListNest}/${id}`;
        const deleteContactListReq = await axios.delete(deleteContactListUrl);

        if (deleteContactListReq.status === 200) {
          showUIToast({ type: "info", text: "Contact list deleted." });
          const contactListUpdate = contactsList.filter((item) => {
            if (item.id !== element.id && item.id !== "newItem") {
              return item;
            }
          });
          setContactsList(contactListUpdate);
        }
      }
    } catch (error) {
      showUIToast({
        type: "error",
        text: "Something went wrong, try again later.",
      });
    }
  };

  const handleDeleteAContact = async (elem) => {
    const { id } = elem;
    if (id?.length < 24) {
      setContacts(contacts.filter((item) => Number(item.id) !== Number(id)));
      handleOnDrop(contacts.filter((item) => Number(item.id) !== Number(id)));
    } else {
      if (!id) return;
      const { success, error } = await deleteContactAsync(id);
      if (success) {
        showUIToast({ type: "info", text: "Contact deleted." });
        const contactsUpdate = contacts.filter((item) => item.id !== id);
        setContacts(contactsUpdate);
        return;
      }
      error &&
        showUIToast({
          type: "error",
          text: error.message || "Something went wrong.",
        });
    }
  };

  const handleSelectAnOption = (data: []) => {
    if (data.length && data.length < 0) setVisibleTable(true);
    setOptionSelected(data);
    setOutreachDisabledButton(true);
    setHubspotDisabledButton(true);
  };

  const addNewListAndUsers = async () => {
    setNewFile("");
    setContactListName("");
    await uploadFiles(contactListName);
  };

  const prepareSequenceStepsForList = (sequences: SequenceStep[]) => {
    return sequences.map((sequence) => {
      const { id, attributes, tendrilId, sequenceId } = sequence;
      return {
        id: id.toString(),
        title: sequence.sequenceName
          ? `${sequence.sequenceName} - ${attributes.displayName}`
          : attributes.displayName,
        tendrilId,
        sequenceId,
        type: "OUTREACH",
      };
    });
  };

  const prepareHubspotListsForList = (
    sequences: HubspotList[],
    custom = false
  ) => {
    return sequences.map((sequence) => {
      const { listId, name, nonce } = sequence;
      return {
        id: listId,
        title: name,
        tendrilId: nonce,
        type: custom ? "CUSTOM_HUBSPOT" : "HUBSPOT",
      };
    });
  };

  const handleOnDrop = (data) => {
    const allData = data;
    const getDataFile = (data) => {
      if (data.length > 0) {
        const filterData = data.map((element) => {
          if (element?.name && element?.phone) {
            if (fourMativ) {
              return {
                name: element?.name,
                phone: element?.phone,
                jobTitle: element?.jobTitle,
                email: element?.email,
                accountName: element?.accountName,
                notes: element?.notes,
                notesAgent: element?.notesAgent,
                notesSDR: "",
                crmId: element?.crmId,
                ssid: element?.ssid,
                firstName: element?.firstName,
                lastName: element?.lastName,
                dateOfBirth: element?.dateOfBirth,
                gender: element?.gender,
                grade: element?.grade,
                returningHBA: element?.returningSchool,
                leavingReason: element?.leavingReason,
                firstParentGuardianName: element?.firstParentGuardianName,
                firstParentGuardianLastName:
                  element?.firstParentGuardianLastName,
                firstParentGuardianEmail: element?.firstParentGuardianEmail,
                firstParentGuardianPhone: element?.firstParentGuardianPhone,
                firstParentGuardianRelationChild:
                  element?.firstParentGuardianRelationChild,
                secondParentGuardianName: element?.secondParentGuardianName,
                secondParentGuardianLastName:
                  element?.secondParentGuardianLastName,
                secondParentGuardianEmail: element?.secondParentGuardianEmail,
                secondParentGuardianPhone: element?.secondParentGuardianPhone,
                secondParentGuardianRelationChild:
                  element?.secondParentGuardianRelationChild,
                phoneTwo: element?.phoneTwo,
                contactNameTwo: element?.contactNameTwo,
                homeAddress: element?.homeAddress,
                homeLineTwo: element?.homeLineTwo,
                homeCity: element?.homeCity,
                homeZip: element?.homeZip,
                transportationStatus: element?.transportationStatus,
                amAddress: element?.amAddress,
                lineTwo: element?.lineTwo,
                amCity: element?.amCity,
                amZip: element?.amZip,
                pmAddress: element?.pmAddress,
                pmLineTwo: element?.pmLineTwo,
                pmCity: element?.pmCity,
                pmZip: element?.pmZip,
                daysOfWeek: element?.daysOfWeek,
                incomingSibling: element?.incomingSibling,
                additionalNotes: element?.additionalNotes,
              };
            } else {
              return {
                name: element?.name,
                phone: element?.phone,
                jobTitle: element?.jobTitle,
                email: element?.email,
                accountName: element?.accountName,
                notes: element?.notes,
                notesAgent: element?.notesAgent,
                notesSDR: "",
                crmId: element?.crmId,
              };
            }
          } else {
            return "";
          }
        });

        const contactsWithoutError = filterData.filter(
          (item) => item.phone !== "#ERROR!"
        );

        const validationFilterData = filterData.find((item) => item !== "");

        if (validationFilterData !== undefined) {
          const dataFile = jsonToCSV(contactsWithoutError, {
            delimiter: ",",
            header: true,
            skipEmptyLines: true,
          });
          setNewFile(dataFile);
          const totalContactsRemoved =
            allData.length - contactsWithoutError.length;

          if (totalContactsRemoved > 0) {
            showUIToast({
              type: "warning",
              text: `${totalContactsRemoved} contacts were removed, due to an incorrect or invalid phone number.`,
            });
          }
        }
      } else {
        setVisibleTable(false);
        setVisibleNewCSV(true);
      }
    };

    if (data[0]?.name) {
      const contactsWithoutError = data.filter((item) => {
        if (item.phone !== "#ERROR!") {
          return item;
        }
      });
      getDataFile(contactsWithoutError);
      const dataToShow = contactsWithoutError.map((item, index) => {
        if (
          item.name !== undefined ||
          item.phone !== undefined ||
          item.jobTitle !== undefined ||
          item.accountName !== undefined
        ) {
          if (item.name === "" || item.phone === "") {
            return {
              ...item,
              id: index,
              error: true,
            };
          } else {
            return {
              ...item,
              id: index,
              error: false,
            };
          }
        } else {
          return undefined;
        }
      });

      if (dataToShow[0] === undefined) {
        setVisibleNewCSV(true);
        showUIToast({
          type: "warning",
          text: "CSV doesn't match the current template.",
        });
        setDisabled(false);
      } else {
        setContacts(dataToShow);
        setVisibleNewCSV(false);
        setVisibleTable(true);
        if (fourMativ) {
          setTableHeaders(headersFourmativ);
          setTableBody(bodyFourMativ);
        }
      }
    } else {
      getDataFile(data);
    }
  };

  const showTrashIcon = () => {
    const elem = optionSelected.find((elem) => elem.select === true);
    return elem && elem.id !== "newItem";
  };

  const handleOnError = () => {
    showUIToast({
      type: "error",
      text: "Something went wrong, try again later.",
    });
  };

  const handleOnRemoveFile = () => {
    showUIToast({ type: "info", text: "CSV file deleted." });
    setNewFile("");
  };

  const initialTab = "csvTab";
  const [currentTab, setCurrentTab] = useState(initialTab);

  const contactTabs: Tab[] = [
    {
      id: "csvTab",
      header: "CSV Contacts",
      content: (index) => (
        <>
          <GenericList
            radioBtn={isSession}
            height="377px"
            data={contactsList}
            deleteItem={handleDeleteContactList}
            setVisible={setVisibleNewCSV}
            setOptionSelected={handleSelectAnOption}
            newItemTitle="+ New List"
          />
          {showButton && (
            <UIButton
              data-qa-id="contacts-btn"
              disabled={visibleNewCSV}
              onClick={isSession ? selectContactsCallback : addNewListAndUsers}
            >
              {btnText}
            </UIButton>
          )}
        </>
      ),
      callback() {
        setCurrentTab("csvTab");
        setLabel("Select a contact list to show");
      },
    },
  ];

  const [outreachDisabledButton, setOutreachDisabledButton] =
    useState<boolean>(true);

  if (outreach) {
    contactTabs.push({
      id: "outreachTab",
      header: "Outreach",
      content: (index) => (
        <>
          <GenericList
            radioBtn={isSession}
            height="377px"
            data={outreachSequenceSteps}
            setVisible={setVisibleNewCSV}
            setOptionSelected={handleSelectAnOption}
          />
          {isSession && (
            <UIButton
              disabled={outreachDisabledButton}
              onClick={selectOutreachContactsCallback}
            >
              Upload
            </UIButton>
          )}
        </>
      ),
      async callback() {
        setVisibleTable(false);
        setVisibleNewCSV(false);
        setLabel("Select a sequence to show");
        updateLoadingStatus({ ...loadingStatus, sequences: true });
        const { accounts, error: accountsError } =
          await getOutreachAccountsAsync(
            currentUser.userId,
            currentUser.organizationId
          );
        if (accounts && accounts.length > 0) {
          const { sequenceSteps, error } =
            await getSequencesStepsFromOutreachAsync(
              currentUser.userId,
              currentUser.organizationId
            );
          if (sequenceSteps) {
            setSequenceSteps(prepareSequenceStepsForList(sequenceSteps));
            setCurrentTab("outreachTab");
          } else if (error) {
            handleEmptyList(
              "There are no sequences for your current accounts."
            );
            console.log(error);
          }
        } else if (accountsError) {
          setLabel(handleNoAccounts());
          console.log(accountsError);
        }
        updateLoadingStatus({ ...loadingStatus, sequences: false });
      },
    });
  }

  const [hubspotDisabledButton, setHubspotDisabledButton] =
    useState<boolean>(true);

  if (hubspot) {
    contactTabs.push({
      id: "hubspotTab",
      header: "HubSpot",
      content: (index) => (
        <>
          <GenericList
            radioBtn={isSession}
            height="377px"
            data={hubspotSequences}
            setVisible={setVisibleNewCSV}
            setOptionSelected={handleSelectAnOption}
          />
          {isSession && (
            <UIButton
              disabled={hubspotDisabledButton}
              onClick={() => selectHubspotContactsCallback()}
            >
              Upload
            </UIButton>
          )}
        </>
      ),
      async callback() {
        setVisibleTable(false);
        setVisibleNewCSV(false);
        setHubspotLists([]);
        setLabel("Select a sequence to show");
        updateLoadingStatus({ ...loadingStatus, sequences: true });
        const { accounts, error: accountsError } =
          await getHubspotAccountsAsync(
            currentUser.userId,
            currentUser.organizationId
          );
        if (accounts && accounts.length > 0) {
          const { lists, error } = await getListsFromHubspotAsync(
            currentUser.userId,
            currentUser.organizationId
          );
          if (lists) {
            setHubspotLists(prepareHubspotListsForList(lists));
            setCurrentTab("hubspotTab");
          } else if (error) {
            handleEmptyList("There are no lists for your current accounts.");
            console.log(error);
          }
        } else if (accountsError) {
          setLabel(handleNoAccounts());
          console.log(accountsError.message);
        }
        updateLoadingStatus({ ...loadingStatus, sequences: false });
      },
    });
  }

  if (hubspotCustom) {
    contactTabs.push({
      id: "hubspotCustomTab",
      header: "HubSpot Custom",
      content: (index) => (
        <>
          <GenericList
            radioBtn={isSession}
            height="377px"
            data={hubspotSequences}
            setVisible={setVisibleNewCSV}
            setOptionSelected={handleSelectAnOption}
          />
          {isSession && (
            <UIButton
              disabled={hubspotDisabledButton}
              onClick={() => selectHubspotContactsCallback(true)}
            >
              Upload
            </UIButton>
          )}
        </>
      ),
      async callback() {
        setVisibleTable(false);
        setVisibleNewCSV(false);
        setHubspotLists([]);
        setLabel("Select a sequence to show");
        updateLoadingStatus({ ...loadingStatus, sequences: true });
        const { accounts, error: accountsError } =
          await getHubspotAccountsAsync(
            currentUser.userId,
            currentUser.organizationId,
            true
          );
        if (accounts && accounts.length > 0) {
          const { lists, error } = await getListsFromHubspotAsync(
            currentUser.userId,
            currentUser.organizationId,
            true
          );
          if (lists) {
            setHubspotLists(prepareHubspotListsForList(lists, true));
            setCurrentTab("hubspotCustomTab");
          } else if (error) {
            handleEmptyList("There are no lists for your current accounts.");
            console.log(error);
          }
        } else if (accountsError) {
          setLabel(handleNoAccounts());
          console.log(accountsError.message);
        }
        updateLoadingStatus({ ...loadingStatus, sequences: false });
      },
    });
  }

  return (
    <Layout
      sidebar
      handleLogout={handleLogout}
      user={userSessionJSON.userName}
      intervals={intervals}
      setIntervals={setIntervals}
    >
      <UITabbedContainer
        title=""
        maxWidth="470px"
        tabs={contactTabs}
        loading={loadingStatus.sequences}
        loadingMessage={"Loading content..."}
        isSettings={false}
      />
      <UICard
        title={`${
          visibleNewCSV ? "Upload a new CSV" : "Contacts from selected List"
        }`}
        showTitle={false}
        width={`${visibleNewCSV ? "375px" : "720px"}`}
        minHeight="460px"
      >
        {visibleTable && (
          <ContactsCounter>Contacts: {contacts.length || 0}</ContactsCounter>
        )}

        {loadingStatus.contacts ? (
          <Loader message={"Loading contacts, please wait..."} />
        ) : (
          <>
            {visibleNewCSV && (
              <NewCSV
                width="100%"
                height="auto"
                handleOnDrop={handleOnDrop}
                handleOnError={handleOnError}
                handleOnRemoveFile={handleOnRemoveFile}
                setContactListName={setContactListName}
                fourMativ={fourMativ}
              />
            )}
            {visibleTable && (
              <UITable
                headers={
                  currentTab === "csvTab"
                    ? [...tableHeaders, { text: "", width: "0.50" }]
                    : tableHeaders
                }
                tableBodies={
                  currentTab === "csvTab"
                    ? !showTrashIcon()
                      ? tableBody
                      : [...tableBody, { icon: "trash" }]
                    : tableBody
                }
                data={contacts}
                selectionHandler={handleDeleteAContact}
                setSelectedRow={setSelectedRow}
                visible={false}
              />
            )}
            {!visibleTable && !visibleNewCSV && (
              <TextContainer>{label}</TextContainer>
            )}
          </>
        )}
      </UICard>
    </Layout>
  );
};

export default Contacts;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function simulateSlowNetworkRequest() {
  throw new Error("Function not implemented.");
}
