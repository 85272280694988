import React, { useState, useContext, useEffect } from "react";
import Layout from "../../components/shared/Layout";
import styled from "styled-components";
import { theme, outreachColor, hubspotColor } from "../../core/theme";

import { UITabbedContainer, Tab } from "../../core/ui/UITabbedContainer";
import { featuresStore } from "../../components/app/FeaturesProvider";

import { Outreach } from "./Outreach/Outreach";
import tabTitle from "../../utils/updateTitle";
import { Hubspot } from "./Hubspot/Hubspot";
import { Account, AccountType } from "./Shared/Entities/Account";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import deleteHubspotAccountAsync from "../../api/Sync/deleteHubspotAccountAsync";
import { UserProviderContext } from "../../components/app/UserProvider";
import deleteOutreachAccountAsync from "../../api/Sync/deleteOutreachAccountAsync";
import DeleteAccountModal from "./Shared/Components/DeleteAccountModal";
import { showUIToast } from "../../core/ui/UIToast";
import { HubspotCustomApp } from "./HubspotApp/HubspotCustomApp";
import { HubspotApp } from "../../types/HubspotAppTypes";
import SettingsSideBar from "./SettingsSideBar";

const { colors } = theme;

const userTypeColor = {
  outreach: outreachColor,
  hubspot: hubspotColor,
};

const Integrations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  min-height: 400px;
`;

const IntegrationsList = styled.div<{
  scrollStartingAt: number;
}>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  ${({ scrollStartingAt }) =>
    scrollStartingAt > 0 &&
    `
    max-height: calc(82px * ${scrollStartingAt} + (.5rem * ${scrollStartingAt}));
    overflow-y: scroll;
  `}
`;

const IntegrationUser = styled.div<{
  type: string;
}>`
  display: grid;
  grid-template-columns: 64px auto auto 1fr;
  grid-template-areas: "img name name delete" "img l c delete";
  grid-gap: 8px;
  align-items: center;

  font-weight: 600;
  padding: 0.5em 1em;
  color: ${colors.darkGray};
  border: 1px solid;
  border-radius: 0.5rem;

  .image {
    grid-area: img;
    flex: none;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      background-color: ${colors.lightGray};
    }
  }

  .name {
    grid-area: name;
    align-self: flex-end;
    font-weight: 700;
    padding-bottom: 4px;
    border-bottom: 1px solid;
    color: ${({ type }) => userTypeColor[type]};
  }

  .lists {
    grid-area: l;
    align-self: flex-start;
  }

  .contacts {
    grid-area: c;
    align-self: flex-start;
  }

  .delete {
    grid-area: delete;
    justify-self: flex-end;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: inherit;
    &:hover,
    &:focus {
      color: ${colors.lightBlue};
    }
  }
`;

const MenuTitle = styled.h2`
  border-bottom: 1px solid gray;
  text-align: right;
  font-size: 2rem;
  font-weight: 300;
  color: #aaacae;
  padding: 8px;
  margin-right: 2rem;
  width: 50%;
`;
const StyledDiv = styled.div`
  vertical-align: middle !important;
`;

interface Props {
  userSession: any;
  handleLogout: () => void;
  intervals: { [key: string]: any };
  setIntervals: (value: any) => void | undefined;
}

const User = ({
  remove,
  id,
  name,
  email,
  nonce,
  activeProspectsCount,
  type = "OUTREACH",
}: {
  remove: (nonce: string, type: string) => void;
  id: string;
  name: string;
  activeProspectsCount: number;
  email: string;
  nonce: string;
  type: string;
}): JSX.Element => {
  const initials = name ? name.substring(0, 1) : email.substring(0, 1);
  return (
    <IntegrationUser type={type}>
      <div className="image empty">{initials}</div>
      <div className="name">{name || email}</div>
      {activeProspectsCount && (
        <div className="lists">{`${activeProspectsCount} contacts`}</div>
      )}
      <button
        title="Delete user"
        className="delete"
        onClick={() => remove(nonce, type)}
      >
        <FontAwesomeIcon icon={["fas", "trash"]} />
      </button>
    </IntegrationUser>
  );
};

const Settings = ({
  handleLogout,
  intervals,
  setIntervals,
}: Props): JSX.Element => {
  const [accounts, dispatch] = useState([] as Account[]);

  const { currentUser } = useContext(UserProviderContext);

  const { featuresState } = useContext(featuresStore);

  const [isDeleteAccountModalOpen, setDeleteAccountModalOpen] =
    useState<boolean>(false);

  const [accountToDelete, setAccountToDelete] = useState<{
    nonce: string;
    type: string;
  } | null>(null);

  const { outreach, userSettings, fourMativ, hubspot, hubspotCustom } =
    featuresState;

  const [hubspotApp, setHubspotApp] = useState<HubspotApp>({
    id: "",
    appId: "",
    accessKey: "",
    accessSecret: "",
    url: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    organizationId: "",
    userId: "",
    callbackUrl: "",
  });

  const setUsers = (users: Account[]) => dispatch(users);
  const [menuOption, setMenuOption] = useState("Integrations");
  const getMenuOption = (menuOption: string) => {
    setMenuOption(menuOption);
  };
  const removeAccount = (nonce: string, type: string) => {
    setDeleteAccountModalOpen(true);
    setAccountToDelete({ nonce, type });
  };

  const removeOutreachUser = async (nonce: string) => {
    /// TODO remove user from state and everywhere else
    const { success, error } = await deleteOutreachAccountAsync(
      currentUser.organizationId,
      currentUser.userId,
      nonce
    );

    if (success) {
      const newUsers = accounts.filter((elem) => {
        return elem.nonce !== nonce;
      });
      setUsers(newUsers);
      showUIToast({
        type: "info",
        text: "Outreach account removed.",
      });
    } else if (error) {
      showUIToast({
        type: "error",
        text: "Error removing Outreach account.",
      });
    }
  };

  const removeHubspotUser = async (nonce: string, custom = false) => {
    /// TODO remove user from state and everywhere else
    const { success, error } = await deleteHubspotAccountAsync(
      currentUser.organizationId,
      currentUser.userId,
      nonce,
      custom
    );

    if (success) {
      const newUsers = accounts.filter((elem) => {
        return elem.nonce !== nonce;
      });
      setUsers(newUsers);
      showUIToast({
        type: "info",
        text: "Hubspot account removed.",
      });
    } else if (error) {
      showUIToast({
        type: "error",
        text: "Error removing Hubspot account.",
      });
    }
  };

  const handleDelete = async () => {
    if (accountToDelete) {
      switch (accountToDelete.type) {
        case AccountType.Outreach: {
          await removeOutreachUser(accountToDelete.nonce);
        }
        case AccountType.Hubspot: {
          await removeHubspotUser(accountToDelete.nonce);
        }
        case AccountType.Custom: {
          await removeHubspotUser(accountToDelete.nonce, true);
        }
      }
      setDeleteAccountModalOpen(false);
    }
  };

  tabTitle("Tendril Connect | Settings");

  const outreachIngration = {
    id: "outereachTab",
    header: "Outreach",
    content: (index) => (
      <Integrations key={index}>
        <Outreach accounts={accounts} callback={setUsers}>
          <IntegrationsList scrollStartingAt={accounts.length > 10 ? 5 : 0}>
            {accounts.map((user) => {
              console.log("accounts", accounts);
              const { id, email, attributes, nonce } = user;
              console.log("user", user);
              return (
                <User
                  key={nonce}
                  remove={removeAccount}
                  id={id}
                  email={email}
                  nonce={nonce}
                  type={AccountType.Outreach}
                  {...attributes}
                />
              );
            })}
          </IntegrationsList>
        </Outreach>
      </Integrations>
    ),
  };

  const hubspotIntegration = {
    id: "hubspotTab",
    header: "HubSpot",
    content: (index) => (
      <Integrations key={index}>
        <Hubspot accounts={accounts} callback={setUsers}>
          <IntegrationsList scrollStartingAt={accounts.length > 10 ? 5 : 0}>
            {accounts.map((user) => {
              console.log("accounts", accounts);
              const { id, attributes, email, nonce } = user;
              console.log("user", user);
              return (
                <User
                  key={nonce}
                  remove={removeAccount}
                  id={id}
                  type={AccountType.Hubspot}
                  email={email}
                  nonce={nonce}
                  {...attributes}
                />
              );
            })}
          </IntegrationsList>
        </Hubspot>
      </Integrations>
    ),
  };

  const hubspotCustomApp = {
    id: "hubspotCustomAppTab",
    header: "HubSpot Custom App",
    content: (index) => (
      <Integrations key={index}>
        <HubspotCustomApp
          accounts={accounts}
          callback={setUsers}
          hubspotApp={hubspotApp}
          setHubspotApp={setHubspotApp}
        >
          <IntegrationsList scrollStartingAt={accounts.length > 10 ? 5 : 0}>
            {accounts.map((user) => {
              console.log("accounts", accounts);
              const { id, attributes, email, nonce } = user;
              console.log("user", user);
              return (
                <User
                  key={nonce}
                  remove={removeAccount}
                  id={id}
                  type={AccountType.Custom}
                  email={email}
                  nonce={nonce}
                  {...attributes}
                />
              );
            })}
          </IntegrationsList>
        </HubspotCustomApp>
      </Integrations>
    ),
  };

  const settings = {
    // TODO USER SETTINGS
    id: "settingsTab",
    header: "User Settings",
    content: (index) => <div key={index}>User Settings</div>,
  };

  const tabs = [] as Tab[];

  if (outreach) {
    tabs.push(outreachIngration);
  }
  if (userSettings) {
    tabs.push(settings);
  }
  if (hubspot) {
    tabs.push(hubspotIntegration);
  }
  if (hubspotCustom) {
    tabs.push(hubspotCustomApp);
  }

  return (
    <Layout
      sidebar
      handleLogout={handleLogout}
      intervals={intervals}
      setIntervals={setIntervals}
      isSettings={true}
      tabsIsEmpty={!tabs.length ? true : false}
    >
      <DeleteAccountModal
        isOpen={isDeleteAccountModalOpen}
        onClose={() => setDeleteAccountModalOpen(false)}
        handleDelete={handleDelete}
      />
      {tabs.length === 0 ? (
        <div style={{ verticalAlign: "middle" }}>
          Contact your sales representative to get the newest features.
        </div>
      ) : (
        <SettingsSideBar getMenuOption={getMenuOption}>
          <MenuTitle>{menuOption}</MenuTitle>
          {menuOption === "Integrations" && (
            <UITabbedContainer
              title="Integrations Tab Container"
              tabs={tabs}
              isSettings={true}
            />
          )}
        </SettingsSideBar>
      )}
    </Layout>
  );
};

export default Settings;
