import { ApiResponse } from "../../types/ApiResponse";
import { VoiceMailList } from "../../components/shared/CallSession";
import routes from "../../constants/routes";
import axios from "axios";

const getRecordingByCallIdAsync = async (
  callId: string
): Promise<ApiResponse<VoiceMailList>> => {
  try {
    const response = await axios.get<VoiceMailList>(
      `${routes.get.recordings.getRecordingByCallId}/${callId}`
    );
    return { success: true, data: response.data };
  } catch (e) {
    return { error: { message: "Something went wrong, try later" } };
  }
};

export default getRecordingByCallIdAsync;
