import { GenericTableHeader } from "../../components/shared/GenericTable";

export const bodyFourMativ: string[] = [
  `name`,
  `accountName`,
  `jobTitle`,
  `phone`,
  `notes`,
  `ssid`,
  `firstName`,
  `lastName`,
  `dateOfBirth`,
  `gender`,
  `grade`,
  `returningSchool`,
  `leavingReason`,
  `firstParentGuardianName`,
  `firstParentGuardianLastName`,
  `firstParentGuardianEmail`,
  `firstParentGuardianPhone`,
  `firstParentGuardianRelationChild`,
  `secondParentGuardianName`,
  `secondParentGuardianLastName`,
  `secondParentGuardianEmail`,
  `secondParentGuardianPhone`,
  `secondParentGuardianRelationChild`,
  `phoneTwo`,
  `contactNameTwo`,
  `homeAddress`,
  `homeLineTwo`,
  `homeCity`,
  `homeZip`,
  `transportationStatus`,
  `amAddress`,
  `lineTwo`,
  `amCity`,
  `amZip`,
  `pmAddress`,
  `pmLineTwo`,
  `pmCity`,
  `pmZip`,
  `daysOfWeek`,
  `incomingSibling`,
  `additionalNotes`,
];
export const headersFourmativ: GenericTableHeader[] = [
  { text: "Name", width: "1" },
  { text: "Company", width: "1" },
  { text: "Position", width: "1" },
  { text: "Phone Number", width: "1" },
  { text: "Notes ", width: "1" },
  { text: "SSID", width: "1" },
  { text: "First Name", width: "1" },
  { text: "Last name", width: "1" },
  { text: "Date of Birth", width: "1" },
  { text: "Gender", width: "1" },
  { text: "Grade", width: "1" },
  { text: "Return School", width: "1" },
  { text: "Leaving Reason", width: "1" },
  { text: "Parent Guardian 1 First", width: "1" },
  { text: "Parent Guardian 1 Last", width: "1" },
  { text: "Email Parent 1", width: "1" },
  { text: "Phone Number Parent 1", width: "1" },
  { text: "Relation To Child", width: "1" },
  { text: "Parent Guardian 2 First", width: "1" },
  { text: "Parent Guardian 2 Last", width: "1" },
  { text: "Email Parent 2", width: "1" },
  { text: "Phone Number Parent 2", width: "1" },
  { text: "Relation To Child", width: "1" },
  { text: "Phone Number 3", width: "1" },
  { text: "Contact Name 3", width: "1" },
  { text: "Home Address", width: "1" },
  { text: "Home Line 2", width: "1" },
  { text: "Home City", width: "1" },
  { text: "Home ZIP", width: "1" },
  { text: "Transportation Status", width: "1" },
  { text: "AM Address", width: "1" },
  { text: "Line 2", width: "1" },
  { text: "AM City", width: "1" },
  { text: "AM ZIP", width: "1" },
  { text: "PM Address", width: "1" },
  { text: "Line 2", width: "1" },
  { text: "PM City", width: "1" },
  { text: "PM ZIP", width: "1" },
  { text: "Days Of Week", width: "1" },
  { text: "Incoming Sibling", width: "1" },
  { text: "Notes", width: "1" },
];
