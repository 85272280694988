import React, {
  ReactNode,
  useState,
  useEffect,
  useContext,
  Suspense,
} from "react";
import styled from "styled-components";
import { UserProviderContext } from "../../../components/app/UserProvider";
import Loader from "../../../core/ui/UILoader";
import { Account } from "../Shared/Entities/Account";
import { showUIToast } from "../../../core/ui/UIToast";
import {
  BaseField,
  BaseInput,
  BaseLabel,
} from "../../../core/ui/base/FormElements";
import { UIButton, UIMiniButton } from "../../../core/ui/UIElements";
import { Flex } from "../../../core/ui";
import hubspotLogo from "../Hubspot/hubspot.svg";
import routes from "../../../constants/routes";
import axios from "axios";
import { HubspotApp } from "../../../types/HubspotAppTypes";
import saveHubspotCustomConfigAsync from "../../../api/Sync/saveHubspotCustomConfigAsync";
import getHubspotCustomConfigAsync from "../../../api/Sync/getHubspotCustomConfigAsync";
import { textAlign } from "styled-system";

const HubspotContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-height: 395px;
`;

interface Props {
  hubspotApp: HubspotApp;
  setHubspotApp: (value) => void;
  children: ReactNode;
  accounts: Account[];
  callback: (user: Account[]) => any;
}

export const HubspotCustomApp = ({
  children,
  hubspotApp,
  setHubspotApp,
  accounts,
  callback,
}: Props): JSX.Element => {
  const { currentUser } = useContext(UserProviderContext);
  const [state, dispatch] = useState<{
    ready: boolean;
    configuring: boolean;
    message: string;
    loading: boolean;
    loadingMessage: string;
    hasConfig: boolean;
  }>({
    ready: true,
    configuring: false,
    message: "Use button above to connect Hubspot.",
    loading: true,
    loadingMessage: "Loading linked accounts",
    hasConfig: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      const { hubspotConfig, error } = await getHubspotCustomConfigAsync(
        currentUser.userId,
        currentUser.organizationId
      );
      if (hubspotConfig) {
        setHubspotApp(hubspotConfig);
        dispatch((state) => ({
          ...state,
          hasConfig:
            hubspotConfig.appId !== "" &&
            hubspotConfig.accessKey !== "" &&
            hubspotConfig.accessSecret !== "",
        }));
      } else {
        showUIToast({
          type: "error",
          text: "An error occurred while getting Hubspot configuration, please try later.",
        });
        dispatch((state) => ({ ...state, hasConfig: false }));
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (accounts.length === 0) {
      changeMessage("No accounts linked.");
    } else {
      changeMessage(
        `You have ${accounts.length} ${
          accounts.length === 1 ? "account" : "accounts"
        } linked.`
      );
    }
  }, [children]);

  const handleConnect = async () => {
    try {
      const url = `${routes.post.customHubspot.oAuthLogin}/${currentUser.organizationId}/${currentUser.userId}`;
      const res = await axios.post<{
        url: string;
      }>(url);
      if (res.status === 201) {
        window.open(res.data.url, "hubspotPopup", `width=${500},height=${500}`);
        getAccountsInterval();
        showUIToast({
          type: "default",
          text: "Hubspot connected",
        });
        return dispatch((state) => ({
          ...state,
          configuring: true,
        }));
      }
    } catch (err) {
      hubspotError();
    }
  };

  const hubspotError = () => {
    showUIToast({
      type: "error",
      text: "Can't configure Hubspot right now, please try again later.",
    });
    return dispatch((state) => ({
      ...state,
      ready: false,
      message: "Can't configure Hubspot right now, please try again later.",
    }));
  };

  const changeMessage = (message: string) =>
    dispatch((state) => ({ ...state, message }));

  const handleCancel = () => {
    // TODO: stop current configuration and show the corresponding ui toast messages
    dispatch((state) => ({
      ...state,
      configuring: false,
      message: "Use button above to connect Hubspot.",
    }));
  };

  const getAccounts = async (wipeInterval?: () => void) => {
    callback([]);
    dispatch((state) => ({
      ...state,
      loading: false,
    }));
    try {
      callback([]);
      const listenUrl = `${routes.get.customHubspot.getLinkedAccounts}/${currentUser.organizationId}/${currentUser.userId}`;
      const res = await axios.get<Account[]>(listenUrl);
      if (res.status === 202) {
        showUIToast({
          type: "info",
          text: "Hubspot syncing in progress.",
        });
        return dispatch((state) => ({
          ...state,
          configuring: true,
          message: "Configuration in process.",
        }));
      }
      if (res.status === 204) {
        if (wipeInterval) wipeInterval();
        dispatch((state) => ({
          ...state,
          configuring: false,
        }));
        showUIToast({
          type: "info",
          text: "Hubspot synced.",
        });
        return changeMessage("Nothing was configured.");
      }
      if (res.status === 200) {
        if (wipeInterval) wipeInterval();
        const amount = res.data.length || 0;
        changeMessage(
          `You have ${amount} ${amount > 1 ? "account" : "accounts"} linked.`
        );
        dispatch((state) => ({
          ...state,
          loading: false,
          configuring: false,
        }));
        showUIToast({
          type: "info",
          text: `HubSpot Custom linked with ${amount} ${
            amount > 1 ? "account" : "accounts"
          }.`,
        });
        return callback(res.data || []);
      }
    } catch (err) {
      if (wipeInterval) wipeInterval();
      dispatch((state) => ({
        ...state,
        loading: false,
      }));
      console.log(err);
      return changeMessage("No accounts linked.");
    }
  };

  const getAccountsInterval = () => {
    const interval = setInterval(
      () => getAccounts(() => clearInterval(interval)),
      100
    );
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setHubspotApp({ ...hubspotApp, [name]: value });
  };

  const handleSave = async () => {
    const { hubspotConfig, error } = await saveHubspotCustomConfigAsync(
      currentUser.organizationId,
      currentUser.userId,
      hubspotApp
    );
    if (hubspotConfig) {
      setHubspotApp({ ...hubspotApp, hubspotConfig });
      showUIToast({
        type: "success",
        text: "Hubspot configuration saved.",
      });
      dispatch((state) => ({ ...state, hasConfig: true }));
    } else {
      showUIToast({
        type: "error",
        text: "Error saving Hubspot configuration, please try again later.",
      });
      dispatch((state) => ({
        ...state,
        hasConfig: false,
        message: "An error occurred while saving the configuration",
      }));
    }
  };

  return (
    <HubspotContainer>
      {state.loading ? (
        <Loader message={state.loadingMessage} />
      ) : (
        <>
          <BaseField>
            <BaseLabel>
              Add your developer account info to minimize service interruptions.{" "}
              <a
                target="_blank"
                href="https://developers.hubspot.com/docs/api/creating-an-app"
                rel="noreferrer"
              >
                Learn how to set up developer accounts.
              </a>
            </BaseLabel>
          </BaseField>
          <BaseField>
            <BaseLabel title="App Id">App Id</BaseLabel>
            <BaseInput
              data-qa-id={"scriptInput"}
              type="text"
              name="appId"
              value={hubspotApp.appId || ""}
              onChange={handleInputChange}
            />
          </BaseField>
          <BaseField>
            <BaseLabel title="Client Id">Client Id</BaseLabel>
            <BaseInput
              data-qa-id={"scriptInput"}
              type="text"
              name="accessKey"
              value={hubspotApp.accessKey || ""}
              onChange={handleInputChange}
            />
          </BaseField>
          <BaseField>
            <BaseLabel title="Client Secret">Client Secret</BaseLabel>
            <BaseInput
              data-qa-id={"scriptInput"}
              type="text"
              name="accessSecret"
              value={hubspotApp.accessSecret || ""}
              onChange={handleInputChange}
            />
          </BaseField>
          <BaseField>
            <BaseLabel title="Redirect URL (OAuth)">
              Redirect URL (OAuth)
            </BaseLabel>
            <BaseInput
              data-qa-id={"scriptInput"}
              type="text"
              name="callbackUrl"
              readOnly={true}
              value={hubspotApp.callbackUrl || ""}
            />
          </BaseField>
          <Flex flexDirection="row" justifyContent="flex-end" width="100%">
            <UIButton onClick={handleSave}>Save</UIButton>
          </Flex>
          {state.hasConfig && (
            <>
              <UIMiniButton
                className="hubspot"
                style={{ justifySelf: "center" }}
                disabled={state.configuring}
                onClick={handleConnect}
              >
                <img alt="Hubspot" src={hubspotLogo} width="120" />
              </UIMiniButton>
              {state.configuring && (
                <UIMiniButton onClick={handleCancel}>
                  Cancel Configuration
                </UIMiniButton>
              )}
              {children}
              <div>{state.message}</div>
            </>
          )}
        </>
      )}
    </HubspotContainer>
  );
};
