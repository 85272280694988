import routes from "../../constants/routes";
import axios from "axios";
import { User } from "../../types";

type ResponseProps = {
  user?: User;
  error?: { message: string };
};

const createUserAsync = async (userData): Promise<ResponseProps> => {
  try {
    const user = await axios.post<User>(
      `${routes.post.users.userNest}`,
      userData
    );
    return { user: user.data };
  } catch (e) {
    return { error: { message: "Error trying to create user" } };
  }
};

export default createUserAsync;
