import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Image } from "./index";
import { motion } from "framer-motion";
import ThreeDots from "../../components/shared/ThreeDots";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { colors } from "../theme";
import moment from "moment-timezone";
import { UIIconButton } from "./UIElements";
import animations from "../../core/ui/base/Animations";
import UsersFilter from "../../../src-v2/Presentation/Views/Users/Components/UsersFilter";

const { lightBlue, darkBlue, lightGray, darkGray } = colors;

interface TableContainerI {
  colorScroll: string;
}

const TableWrapper = styled.div<{
  hasChildren?: boolean;
  maxHeight?: string;
}>`
  display: grid;
  ${({ hasChildren }) =>
    hasChildren
      ? {
          gridTemplateAreas: "'search children' 'table table' 'pag pag'",
          gridTemplateColumns: "1fr auto",
        }
      : {
          gridTemplateAreas: "'search' 'table' 'pag'",
          gridTemplateColumns: "100%",
        }}
  grid-gap: var(--layout-gap-small);

  ${({ maxHeight }) => maxHeight && { maxHeight }}
`;

const IconButton = styled.button`
  flex: none;
  display: flex;
  height: 2rem;
  width: 2rem;
  color: white;
  background: ${lightBlue};
  border-radius: var(--box-border-radius);
  svg {
    min-height: 1em;
    width: 1em;
    margin: auto;
  }
  &:hover,
  &:focus {
    background: ${darkBlue};
  }
  &:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    background: ${darkGray};
  }
`;

const SearchForm = styled.form`
  grid-area: search;
  display: flex;
  justify-content: flex-end;
  gap: var(--layout-gap-small);
  font-size: var(--font-size);

  input {
    flex: 1;
    font-size: inherit;
    padding: 0.25em;
    border-radius: var(--box-border-radius);
    border: 1px solid ${lightGray};
    color: black;

    &::placeholder {
      color: ${lightGray};
    }

    &:hover,
    &:focus {
      background: ${lightGray};

      &::placeholder {
        color: ${darkGray};
      }
    }
  }
`;
const Table = styled.table<{
  cols: string;
  maxHeight?: string;
  iconCol?: boolean;
  isABigTable?: boolean;
}>`
  display: grid;
  text-align: left;
  ${({ maxHeight }) =>
    maxHeight && {
      maxHeight: maxHeight ?? "580px",
      overflowY: "auto",
    }}
  ${({ isABigTable }) =>
    isABigTable && {
      overflowX: "scroll",
    }}
  th, td {
    padding: 0.5em 0.25em;
    font-weight: 400;
    word-wrap: break-word;
    white-space: pre-line;
    ${({ isABigTable }) =>
      isABigTable && {
        minWidth: "120px",
        maxWidth: "300px",
      }}
  }
  thead,
  tbody {
    display: block;
  }
  thead {
    background: ${lightBlue};
    color: white;
    ${({ maxHeight }) =>
      maxHeight && {
        position: "sticky",
        top: 0,
      }};
  }
  tr {
    display: grid;
    grid-template-columns: ${({ cols }) => cols};
    align-items: center;
  }
  .delete-icon {
    background: transparent;
    color: ${darkGray};
    cursor: pointer;
    svg {
      height: 1.5em;
      width: 1.5em;
    }
    &:hover,
    &:focus {
      color: ${lightBlue};
    }
  }
`;

const MotionTableRow = styled(motion.tr)<TrProps>`
  &:nth-child(even) {
    background: ${lightGray};
  }
`;

const LoadingContainer = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  padding: 10rem;
`;

const PaginationControls = styled.div`
  grid-area: pag;
  justify-self: right;
  display: flex;
  align-items: center;
  gap: var(--layout-gap-small);
`;

const StyledDiv = styled.div`
  display: grid;
  justify-content: center;
  margin: 5rem 0;
  font-weight: bold;
  font-size: 22px;
`;

interface TrProps {
  colorLines?: string;
  iconRow?: boolean;
  error?: boolean;
  clickable?: boolean;
}

interface TdProps {
  color?: string;
  colorLines?: string;
  error?: boolean;
  textAlign?: "left" | "right" | "center";
}

const Td = styled(motion.td)<TdProps>`
  ${({ error }) => error && { color: "red" }}
`;

const NavigationContainer = styled.div`
  grid-area: nav;
`;

const RotatedImage = styled(Image)`
  transform: rotate(180deg);
`;

export type GenericTableHeader = {
  text: string;
  width?: string;
};

export type PaginatedProps = {
  pageNumber: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
  onPrevPage: () => void;
  onNextPage: () => void;
};

export type SearchProps = {
  placeHolder: string;
  onSearch: (e: any, filters:string[] | null) => void;
};

interface Props {
  headers: GenericTableHeader[];
  tableBodies: any[];
  data: any;
  selectionHandler?: (e) => void;
  summaryHandler?: (e) => void;
  searchHandler?: { (e: any): void };
  setSelectedRow?: { (e: any): void };
  visible: boolean;
  setItemId?: { (data: any): void };
  extraData?: any;
  setScroll?: { (data: any): void };
  paginatedData?: PaginatedProps | undefined;
  searchData?: SearchProps | undefined;
  filterEnabled?: boolean;
  children?: React.ReactNode;
  maxHeight?: string;
}

const UITable: React.FC<Props> = ({
  headers,
  tableBodies,
  data,
  visible,
  extraData,
  paginatedData,
  searchData,
  filterEnabled,
  children,
  selectionHandler,
  summaryHandler,
  setSelectedRow,
  setItemId,
  setScroll,
  maxHeight,
}) => {
  const [dataTable, setDataTable] = useState([] as any[]);
  const [detailsShown, setDetailsShown] = useState({});
  const [currentSelected, setCurrentSelected] = useState("");
  const iconRow = tableBodies.filter((e) => e.icon1).length > 0;
  const [readyToChangePage, setReadyToChangePage] = useState(!!paginatedData);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [lastSearch, setLastSearch] = useState("");
  useEffect(() => {
    setDataTable(
      data.map((d) => {
        const obj = { select: false };
        //global timezone
        if (d.startAt) {
          d.startAt = moment(d.startAt["$date"]).format("YYYY-MM-DD HH:mm:ss");
        }
          return { ...d, ...obj };
      })
    );
    setIsLoading(false);
  }, [data]);

  const handleSearch = async (e) => {
    if (lastSearch !== inputValue) {
      setIsLoading(true);
      searchData?.onSearch(inputValue,null);
      setLastSearch(inputValue);
    }

    e.preventDefault();
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const getProperty = (obj, prop) => {
    const parts = prop.split(".");

    if (Array.isArray(parts)) {
      const last = parts.length > 1 ? parts.pop() : parts;
      const l = parts.length;
      let i = 1;
      let current = parts[0];

      while ((obj = obj[current]) && i < l) {
        current = parts[i];
        i++;
      }

      if (obj && typeof obj === "object") {
        //avoid null obj
        if (Array.isArray(obj)) {
          const arr = obj.map((item) => {
            if (item.type) {
              return item.type;
            }
          });
          return arr.join(" ");
        } else {
          return obj[last];
        }
      }
      return obj;
    } else {
      throw "parts is not valid array";
    }
  };

  const handleCheckBoxHeader = (e) => {
    setDataTable([]);
    const checked = e.target.checked;
    setDataTable(
      dataTable.map((d) => {
        d.select = checked;
        return d;
      })
    );

    const aux = [];
    dataTable.forEach((item) => {
      if (item.select === true) {
        aux.push(item as never);
      }
    });
    if (setSelectedRow) {
      setSelectedRow(aux);
    }
  };

  const handleCheckboxRow = (e, value) => {
    setDataTable([]);
    const checked = e.target.checked;

    setDataTable(
      dataTable.map((d) => {
        if (value === d.id) {
          d.select = checked;
        }
        return d;
      })
    );

    const aux = [];
    dataTable.forEach((item) => {
      if (item.select === true) {
        aux.push(item as never);
      } else {
        return aux.filter((el) => el !== item.id);
      }
    });
    if (setSelectedRow) {
      setSelectedRow(aux);
    }
  };

  // this ensure only show element until has been fetched
  useEffect(() => {
    if (currentSelected) {
      setDetailsShown((prev) => {
        return { ...prev, [currentSelected]: true };
      });
    }
  }, [extraData]);

  const handleScroll = (e) => {
    const scrollable = Math.ceil(e.target.scrollHeight - e.target.scrollTop);
    const scrolled = e.target.clientHeight + 1;
    if (scrollable === scrolled) {
      if (setScroll) {
        setScroll(true);
      }
    }
  };

  useEffect(() => {
    setReadyToChangePage(!!paginatedData);
  }, [paginatedData?.pageNumber]);

  const canGoPrev = () => paginatedData && paginatedData.pageNumber > 1;
  const canGoNext = () =>
    paginatedData && paginatedData.pageNumber < paginatedData.totalPages;

  const getPagesInfo = () => {
    if (paginatedData) {
      const { pageNumber, pageSize, totalCount } = paginatedData;
      const start = (pageNumber - 1) * pageSize + 1;
      const calculatedEnd = pageNumber * pageSize;
      const end = calculatedEnd < totalCount ? calculatedEnd : totalCount;
      return `${start} - ${end} of ${totalCount}`;
    }
  };

  const handleChangePage = (action) => {
    setReadyToChangePage(false);

    if (action === "prev" && canGoPrev()) {
      paginatedData?.onPrevPage();
      return;
    }

    if (action === "next" && canGoNext()) {
      paginatedData?.onNextPage();
      return;
    }
  };

  return (
    <TableWrapper hasChildren={children !== undefined} maxHeight={maxHeight}>
      {searchData && (
        <SearchForm onSubmit={handleSearch}>
          <input
            type="text"
            placeholder={searchData?.placeHolder || "Search"}
            value={inputValue}
            onChange={handleInputChange}
            data-qa-id={"searchBarManagement"}
          />
          <IconButton onClick={handleSearch} data-qa-id={"searchBtnManagement"}>
            <FA icon={["fas", "search"]} />
          </IconButton>
          {filterEnabled && <UsersFilter onChangeFilters={searchData?.onSearch}/>}
        </SearchForm>
      )}
      {children && <div style={{ gridArea: "children" }}> {children}</div>}
      <div
        onScroll={handleScroll}
        style={{ gridArea: "table", justifySelf: "right", width: "100%" }}
      >
        {!isLoading ? (
          <>
            {dataTable.length ? (
              <Table
                iconCol={true}
                maxHeight={maxHeight ?? "580px"}
                isABigTable={headers.length > 20}
                cols={headers.reduce(
                  (acc, curr) => acc + `${curr.width}fr `,
                  ""
                )}
              >
                <motion.thead {...animations.fadeInFromTop}>
                  <tr>
                    {visible && (
                      <th>
                        <input
                          type="checkbox"
                          onChange={handleCheckBoxHeader}
                        />
                      </th>
                    )}
                    {headers.map(({ text, width }, i) => (
                      <th key={i}>{text}</th>
                    ))}
                  </tr>
                </motion.thead>
                <tbody style={{overflowY: "scroll", maxHeight: "400px"}}>
                  {dataTable.map((data, index) => (
                    <MotionTableRow
                      key={data.id + `_${index}`}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0 }}
                      animate={{ y: [-20, 10, 0], opacity: 1 }}
                      transition={{ ease: "easeIn", duration: 0.5 }}
                      error={data.error}
                      clickable={setItemId && true}
                    >
                      {visible && (
                        <td key={data.id}>
                          <input
                            key={data.id}
                            type="checkbox"
                            checked={data.select}
                            onChange={(e) => handleCheckboxRow(e, data.id)}
                          />
                        </td>
                      )}
                      {tableBodies.map((body) =>
                        typeof body === "string" ? (
                          <Td
                            key={`${data.id}-${body}`}
                            onClick={() =>
                              summaryHandler ? summaryHandler(data.id) : null
                            }
                            id={data.name}
                            error={data.error}
                          >
                            {getProperty(data, body)}
                          </Td>
                        ) : body.double ? (
                          <Td
                            key={`${data.id}-${body}`}
                            id={data.name}
                            color={"blue"}
                          >
                            <UIIconButton
                              id={data.id}
                              onClick={() =>
                                selectionHandler ? selectionHandler(data) : null
                              }
                            >
                              <FA
                                icon={body.icon1}
                                size="lg"
                                className="text-app-blue-lighter"
                              />
                            </UIIconButton>
                          </Td>
                        ) : body.icon ? (
                          <Td
                            key={`${data.id}-${body}`}
                            id={data.name}
                            onClick={selectionHandler}
                          >
                            <UIIconButton
                              data-qa-id={`delContact_${data.name
                                .split(" ")
                                .join("_")}`}
                              name={data.id}
                              id={data.id}
                              onClick={() =>
                                selectionHandler ? selectionHandler(data) : null
                              }
                              className={`delete-icon ${data.error && "error"}`}
                            >
                              <FA icon={["fas", body.icon]} />
                            </UIIconButton>
                          </Td>
                        ) : body.customRender ? (
                          <Td key={`${data.id}-${body.id}`}>
                            {body.renderFunc(data)}
                          </Td>
                        ) : (
                          <Td key={`${data.id}-${body}`}></Td>
                        )
                      )}
                    </MotionTableRow>
                  ))}
                </tbody>
              </Table>
            ) : (
              <StyledDiv>There are no results for the search.</StyledDiv>
            )}
          </>
        ) : (
          <LoadingContainer>
            <ThreeDots
              text={"Waiting for data"}
              width={"30%"}
              color={"#A6A8AA"}
            />
          </LoadingContainer>
        )}
      </div>
      {paginatedData && dataTable.length && !isLoading ? (
        <PaginationControls>
          <div>{getPagesInfo()}</div>
          {paginatedData.totalCount > paginatedData.pageSize && (
            <>
              <IconButton
                onClick={() => handleChangePage("prev")}
                disabled={!(readyToChangePage && canGoPrev())}
              >
                <FA icon={["fas", "angle-left"]} />
              </IconButton>
              <IconButton
                onClick={() => handleChangePage("next")}
                disabled={!(readyToChangePage && canGoNext())}
              >
                <FA icon={["fas", "angle-right"]} />
              </IconButton>
            </>
          )}
        </PaginationControls>
      ) : null}
    </TableWrapper>
  );
};

export default UITable;
