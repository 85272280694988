import React, { StrictMode, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory, BrowserRouter, Router } from "react-router-dom";
import { AppContext } from "./libs/contextLib";
import Routes from "./Routes";
import "./index.css";
import ThemeProviderWrapper from "./components/app/ThemeProviderWrapper";
import UserProvider, {
  UserProviderContext,
} from "./components/app/UserProvider";
import { CurrentUser } from "./types";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { config } from "./config";
import constants from "./constants.json";
import TagManager from "react-gtm-module";
import axios from "axios";
import routes from "./constants/routes";
import { isTokenExpired } from "./core/utils/sessionRefresher";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  FeaturesProvider,
  featuresStore,
} from "./components/app/FeaturesProvider";
import UIToastContainer, { showUIToast } from "./core/ui/UIToast";
import logout from "./api/Auth/logout";

library.add(fas);

const tagManagerArgs = {
  gtmId: config.analytics.tagManager,
};
TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: "https://8eafc96948a048e68a13ba9133cf2ca4@o1282397.ingest.sentry.io/6489830",
  release: constants.version,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: config.sentry.tracesSampleRate,
  environment: config.sentry.environment,
  debug: false,
  enabled: config.sentry.environment !== "local",
});

const App = () => {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userSession, setUserSession] = useState({} as CurrentUser);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSdr, setIsSdr] = useState(false);
  const [userName, setUserName] = useState("");
  const { setCurrentUser, clearCurrentUser } = useContext(UserProviderContext);
  const { fetchFeatures } = useContext(featuresStore);

  useEffect(() => {
    const path = window.location.pathname;
    if (!isAuthenticated) {
      TagManager.dataLayer({
        dataLayer: {
          path: path,
        },
      });
    }
  }, [isAuthenticated]);

  const handleLogout = async () => {
    const token = window.localStorage.getItem("target");

    const { error } = await logout(token as string);

    if (error) {
      showUIToast({
        type: "error",
        text: error.message,
      });
    }

    await invalidateSession();
  };

  const invalidateSession = async () => {
    window.localStorage.removeItem("target");
    window.localStorage.removeItem("user");
    userHasAuthenticated(false);
    setIsAdmin(false);
    setIsSdr(false);

    clearCurrentUser();

    delete axios.defaults.headers.common["Authorization"];

    history.push("/login?redirect=/home");
  };

  const getUserType = () => {
    const userSession = JSON.parse(window.localStorage.getItem("user") || "{}");
    setUserName(userSession.userName);
    setUserSession(userSession);

    if (userSession) {
      const { userId, userName, type, sdrId, email } = userSession;
      // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
      Sentry.setUser({ email: email });
      // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/context/
      Sentry.setContext("customContext", {
        userId,
        userName,
        type,
        sdrId,
        email,
      });
      const { version, branch } = constants;
      // https://docs.sentry.io/platforms/javascript/enriching-events/tags/
      // prettier-ignore
      Sentry.setTags({
        "userId": userId,
        "sdrId": sdrId,
        "version": version,
        "branch": branch
      });

      const userTypeAdmin = userSession.type.filter(
        (item) => item.type === "admin"
      );
      const userTypeSdr = userSession.type.filter(
        (item) => item.type === "sdr"
      );

      if (userTypeAdmin.length === 1) {
        setIsAdmin(true);
      }
      if (userTypeSdr.length === 1) {
        setIsSdr(true);
      }
    }
  };

  useEffect(() => {
    const userTarget = window.localStorage.getItem("target") || "";
    if (userTarget !== "") {
      //validate target and prepare user session
      if (!isTokenExpired(userTarget)) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${userTarget}`;

        userHasAuthenticated(true);
      } else {
        invalidateSession().then(() =>
          showUIToast({
            type: "warning",
            text: "Your session has expired, please login again.",
          })
        );
      }
    }

    isAuthenticated && getUserType();
  }, [userName, isAuthenticated]);

  return (
    <div>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <Router history={history}>
          <UserProvider>
            <FeaturesProvider>
              <ThemeProviderWrapper>
                <UIToastContainer />
                <Routes
                  isSDRorAdmin={isAdmin || isSdr}
                  userSession={JSON.stringify(userSession)}
                  handleLogout={handleLogout}
                  fourMativ={fetchFeatures}
                />
              </ThemeProviderWrapper>
            </FeaturesProvider>
          </UserProvider>
        </Router>
      </AppContext.Provider>
    </div>
  );
};

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);
