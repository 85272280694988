import React, { useState, createContext, ReactElement } from "react";
import { UserTypes } from "../shared/enums";
import { CurrentUser } from "../../types";

type UserProviderContextProps = {
  currentUser: CurrentUser;
  setCurrentUser(user): void;
  clearCurrentUser(): void;
};

export const UserProviderContext = createContext<UserProviderContextProps>({
  currentUser: {} as CurrentUser,
  setCurrentUser: () => {
    /* only for declaration purposes */
  },
  clearCurrentUser: () => {
    /* only for declaration purposes */
  },
});

type Props = {
  children: ReactElement;
};

const getTypes = (user) => {
  const types = user.type.map((t) => t.type);
  return {
    isAdmin: types.includes(UserTypes.ADMIN),
    isSdr: types.includes(UserTypes.SDR),
    isAgent: types.includes(UserTypes.AGENT),
  };
};

const UserProvider = ({ children }: Props): JSX.Element => {
  const [user, setUser] = useState<CurrentUser>({} as CurrentUser);

  // Add here all the user info you want to have at the context
  const setCurrentUser = (user: CurrentUser) => {
    const types = getTypes(user);
    setUser({ ...user, ...types });
  };

  const clearCurrentUser = () => {
    setUser({} as CurrentUser);
  };

  return (
    <UserProviderContext.Provider
      value={{ currentUser: user, setCurrentUser, clearCurrentUser }}
    >
      {children}
    </UserProviderContext.Provider>
  );
};

export default UserProvider;
