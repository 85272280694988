import React, { ReactNode } from "react";
import styled from "styled-components";
import { Box, Flex, Image, Text, BoxProps, FlexProps } from "../../core/ui";

type StatsTableHeaderProps = {
  icon: string;
  headline: string;
} & FlexProps;

type StatsTableRowProps = {
  icon: string | null;
  name: string;
  data: ReactNode;
} & FlexProps;

type StatsTableProps = {
  children: ReactNode[];
} & BoxProps;

export const StatsTableHeader = ({
  icon,
  headline,
  ...rest
}: StatsTableHeaderProps): JSX.Element => (
  <Flex p="7px" alignItems="center" {...rest}>
    <Image src={icon} width={20} mr="7px" alt="GenericTable header icon" />
    <Text fontSize={13} fontWeight="600">
      {headline}
    </Text>
  </Flex>
);

const RowContainer = styled(Flex)`
  margin: 0px 5px;
  padding: 6px 0px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.white};
  ${Flex} {
    flex: 1;
    align-items: center;
  }
`;

export const StatsTableRow = ({
  icon,
  name,
  data,
  ...rest
}: StatsTableRowProps): JSX.Element => (
  <RowContainer {...rest}>
    <Flex pl="5px">
      {icon && (
        <Image src={icon} width={15} mr="5px" alt="GenericTable row icon" />
      )}
      <Text fontSize={12} fontWeight="600">
        {name}
      </Text>
    </Flex>
    <Flex>{data}</Flex>
  </RowContainer>
);

const TableContainer = styled(Box)`
  min-width: 300px;
  border: solid 1px ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  overflow: hidden;
`;

export const StatsTable = ({
  children,
  ...rest
}: StatsTableProps): JSX.Element => (
  <TableContainer {...rest}>{children}</TableContainer>
);
