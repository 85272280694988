import React from "react";
import { motion } from "framer-motion";
import styled, { useTheme } from "styled-components";
import { Flex } from "../../core/ui";

const StyledFlex = styled(Flex)`
  margin-top: 15px;
  border-radius: 5px;
  padding: 5px;
  flex-direction: column;
  margin: auto;
  align-items: center;
`;

const title = {
  fontSize: "2.5rem",
};

const LoadingContainer = styled(motion.div)<containerProps>`
  width: ${(props) => {
    if (props.width) {
      return props.width;
    } else {
      return "100%";
    }
  }};
  height: 8rem;
  display: flex;
  justify-content: space-around;
`;

const LoadingCircle = styled(motion.span)<dotsProps>`
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${(props) => {
    if (props.color) {
      return props.color;
    } else {
      return "white";
    }
  }};
  border-radius: 90%;
`;

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const loadingCircleVariants = {
  start: {
    y: "50%",
  },
  end: {
    y: "150%",
  },
};

const loadingCircleTransition = {
  duration: 0.5,
  yoyo: Infinity,
  ease: "easeInOut",
};

interface Props {
  text: string;
  color?: string;
  width?: string;
}

interface dotsProps {
  color?: string;
}

interface containerProps {
  width?: string;
}
const ThreeDots: React.FC<Props> = ({ text, color, width }: Props) => {
  return (
    <StyledFlex>
      <LoadingContainer
        variants={loadingContainerVariants}
        initial="start"
        animate="end"
        width={width}
      >
        <LoadingCircle
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
          color={color}
        />
        <LoadingCircle
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
          color={color}
        />
        <LoadingCircle
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
          color={color}
        />
      </LoadingContainer>
      <span style={title}>{text}</span>
    </StyledFlex>
  );
};

export default ThreeDots;
