import React from "react";
import { UISection, UITitle, UIText } from "../../core/ui/UIElements";
import styled from "styled-components";
interface ScriptProps {
  title: string;
  body: string;
}
interface Props {
  script: ScriptProps;
}

const ScriptPreview: React.FC<Props> = ({ script: { title, body } }: Props) => {
  return (
    <UISection>
      <UITitle>{title}</UITitle>
      <ScrollableUIText>{body}</ScrollableUIText>
    </UISection>
  );
};

const ScrollableUIText = styled(UIText)`
  height: 100%;
  max-height: 404px;
  overflow-y: auto;
`;

export default ScriptPreview;
