import React, { useState, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import Recording from "../../assets/icons/recording.svg";

import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { theme } from "../../core/theme";
import { UITitle } from "../../core/ui/UIElements";

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  font-size: 2.5rem;
  background-color: transparent;
  background-image: url(${Recording});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 0.25rem;
  svg {
    border-radius: 50%;
    border: 0.15em solid ${theme.colors.lighterBlue};
    background-color: white;
    color: ${theme.colors.lightBlue};
  }
`;

const StyledAudio = styled("audio")`
  display: none;
`;

interface VoicemailProps {
  title: string;
  url: string;
}

interface Props {
  voicemail: VoicemailProps;
}

const PlayVoicemail: React.FC<Props> = ({
  voicemail: { title, url },
}: Props) => {
  const theme = useTheme();
  const ref = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handleClick = () => {
    isPlaying ? ref?.current?.pause() : ref?.current?.play();
    setIsPlaying((prev) => !prev);
  };

  useEffect(() => {
    ref?.current?.load();
    setIsPlaying(false);
  }, [url]);

  return (
    <>
      <UITitle>{title}</UITitle>
      <PlayButton data-qa-id={"playVoiceButton"} onClick={handleClick}>
        <FA icon={["fas", `${isPlaying ? "circle-pause" : "circle-play"}`]} />
      </PlayButton>

      <StyledAudio
        style={{ display: "none" }}
        ref={ref}
        onEnded={() => {
          setIsPlaying(false);
        }}
      >
        <source src={url} type="audio/x-wav" />
      </StyledAudio>
    </>
  );
};

export default PlayVoicemail;
