import React from "react";
import styled from "styled-components";
import { Box, Flex, Button, Text, Image, TextSpan } from "../core/ui";
import { Container, CircleButton, AlertBox } from "../components/shared";
import { useTheme } from "styled-components";
import BellIcon from "../assets/icons/bell.svg";
import LogoutIcon from "../assets/icons/logout.svg";
import AvatarIcon from "../assets/icons/avatar.svg";
import UserWhiteIcon from "../assets/icons/user_white.svg";
import StarBlueIcon from "../assets/icons/star_blue.svg";
import {
  StatsTableHeader,
  StatsTableRow,
  StatsTable,
} from "../components/shared/StatsTable";

const TestComponents = (): JSX.Element => {
  const theme = useTheme();

  const fakeStats = {
    icon: UserWhiteIcon,
    users: [
      { name: "Arli Gomez", data: "10" },
      { name: "Carlos Luz", data: "7" },
      { name: "Victoria Contreras", data: "5" },
      { name: "Hector Elizondo", data: "3" },
      { name: "Paola Banderas", data: "1" },
    ],
  };

  const StyledFlex = styled(Flex)`
    gap: 50px;
  `;

  return (
    <Container>
      <StyledFlex
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-around"
      >
        <Flex justifyContent="center" alignItems="center">
          <Image src={AvatarIcon} width={40} mr={15} />
          <Text color="lightBlue" fontWeight="400">
            <TextSpan fontWeight="800">Welcome</TextSpan> John Smith
          </Text>
        </Flex>
        <Button bg="lightBlue" px={25} py={12}>
          <Flex alignItems="center">
            <Image src={LogoutIcon} width={16} mr={15}></Image>
            <Text fontSize={15} color="white">
              Logout
            </Text>
          </Flex>
        </Button>

        <CircleButton
          width={100}
          height={100}
          bg={theme.colors.white}
          outsideBg={`${theme.colors.white}80`}
        >
          <Text color="lightBlue" fontWeight="600" fontSize={14}>
            {"Let's start"}
          </Text>
        </CircleButton>
        <CircleButton
          width={100}
          height={100}
          bg={theme.colors.orange}
          outsideBg={theme.colors.yellow}
        >
          <Text color="white" fontWeight="600" fontSize={14}>
            Stop Recording
          </Text>
        </CircleButton>

        <AlertBox
          icon={BellIcon}
          text="Connected"
          bg="linear-gradient(90deg, #0095D4, #0B5089)"
        />

        <StatsTable>
          <StatsTableHeader
            icon={StarBlueIcon}
            headline="Top transfer today"
            bg="white"
            color="lightBlue"
          />
          <Box>
            {fakeStats.users.map(({ name, data }) => (
              <StatsTableRow
                key={name}
                icon={fakeStats.icon}
                name={name}
                data={data}
                color="white"
              />
            ))}
          </Box>
        </StatsTable>
      </StyledFlex>
    </Container>
  );
};

export default TestComponents;
