import routes from "../../constants/routes";
import axios from "axios";
import { NumberPool } from "../../types";

type ResponseProps = {
  data?: NumberPool[] | [];
  totalPages?: number;
  totalCount?: number;
  pageNumber?: number;
  error?: { message: string };
};

const getNumbersAsync = async (
  pageNumber: number,
  pageLimit: number,
  search: string
): Promise<ResponseProps> => {
  try {
    const { data } = await axios.get(`${routes.get.numbers.getForManagement}`, {
      params: {
        page: pageNumber,
        pageLimit: pageLimit,
        search,
      },
    });
    return {
      data: data.data,
      pageNumber: data.pageNumber,
      totalPages: data.totalPages,
      totalCount: data.totalCount,
    };
  } catch {
    return { error: { message: "Error trying to get numbers" } };
  }
};

export default getNumbersAsync;
