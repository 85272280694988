import { useContext, createContext } from "react";

export type GlobalContext = {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
};

export const AppContext = createContext<GlobalContext>({
  isAuthenticated: false,
  userHasAuthenticated: () => undefined,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAppContext: any = () => {
  return useContext(AppContext);
};
