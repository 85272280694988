import routes from "../../constants/routes";
import axios from "axios";
import { Account } from "../../pages/Settings/Shared/Entities/Account";

type AccountsProps = {
  accounts?: Account[];
  error?: { message: string };
};

const getOutreachAccountsAsync = async (
  userId: string,
  orgId: string
): Promise<AccountsProps> => {
  try {
    const outreachAccounts = await axios.get<Account[]>(
      `${routes.get.outreach.getLinkedAccounts}/${orgId}/${userId}`
    );

    return { accounts: outreachAccounts?.data };
  } catch (error) {
    return { error: { message: "Error while getting sequences" } };
  }
};

export default getOutreachAccountsAsync;
