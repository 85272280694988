import routes from "../../constants/routes";
import axios from "axios";
import { HubspotApp } from "../../types/HubspotAppTypes";

type HuspotCustomConfigProps = {
  hubspotConfig?: HubspotApp;
  error?: { message: string };
};

const getHubspotCustomConfigAsync = async (
  userId: string,
  orgId: string
): Promise<HuspotCustomConfigProps> => {
  try {
    const hubspotCustomConfig = await axios.get<HubspotApp>(
      `${routes.get.customHubspot.getCustomConfiguration}/${orgId}/${userId}`
    );

    return { hubspotConfig: hubspotCustomConfig?.data };
  } catch (error) {
    return { error: { message: "Error while getting Hubspot configuration" } };
  }
};

export default getHubspotCustomConfigAsync;
