import { ApiResponse } from "../../types/ApiResponse";
import { Recording, UpdateRecordingDto } from "../../types/Recording";
import axios from "axios";
import routes from "../../constants/routes";

export const updateRecordingAsync = async (
  updateRecordingDto: UpdateRecordingDto
): Promise<ApiResponse<Recording>> => {
  try {
    const recordingId = updateRecordingDto.id ?? updateRecordingDto._id;
    const { data } = await axios.put<Recording>(
      `${routes.put.recordings.updateRecording}/${recordingId}`,
      updateRecordingDto
    );
    return { success: true, data };
  } catch {
    return { error: { message: "Error updating Recording" }, success: false };
  }
};
