import styled from "styled-components";
import { theme } from "../theme";
import { UIIconButton } from "./UIElements";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons/faEllipsisVertical";
import React from "react";

type UIDropDownContentProps = {
  backgroundColor?: string;
  minWidth?: string;
  width?: string;
  right?: string;
  top?: string;
};

export const UIDropDownContent = styled.div<UIDropDownContentProps>`
  display: none;
  position: absolute;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: ${({ backgroundColor }) =>
    backgroundColor || theme.colors.white};
  min-width: ${({ minWidth }) => minWidth || "5rem"};
  right: ${({ right }) => right || "1.5rem"};
  top: ${({ top }) => top || "0"};
`;

type UIDropDownProps = {
  margin?: string;
};

export const UIDropDown = styled.div<UIDropDownProps>`
  position: relative;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin: ${({ margin }) => margin || "auto"};
  :hover {
    transform: scale(1.1);
    & > ${UIDropDownContent} {
      display: flex;
      flex-direction: column;
    }
  }
`;

type UIDropDownItemProps = {
  display?: string;
  gap?: string;
  alignItems?: string;
  justifyItems?: string;
  width?: string;
  padding?: string;
  color?: string;
  hoverBackgroundColor?: string;
};

export const UIDropDownItem = styled(UIIconButton)<UIDropDownItemProps>`
  display: ${({ display }) => display || "flex"};
  gap: ${({ gap }) => gap || "0.5rem"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  justify-items: ${({ justifyItems }) => justifyItems || "flex-start"};
  width: ${({ width }) => width || "100%"};
  padding: ${({ padding }) => padding || "0.5rem 1rem"};
  color: ${theme.colors.darkGray};
  :hover {
    background-color: ${theme.colors.lightGray};
  }
  z-index: 1;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const UIHoverIconMenu = ({ dataTitle, children }: any): JSX.Element => {
  return (
    <UIDropDown margin="0 0 0 auto">
      <UIIconButton data-qa-id={`${dataTitle}`}>
        <FA
          style={{
            margin: "0 1rem",
          }}
          icon={faEllipsisVertical}
          size="xl"
        />
      </UIIconButton>
      <UIDropDownContent>{children}</UIDropDownContent>
    </UIDropDown>
  );
};
