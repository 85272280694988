const getType = (types) => {
  const sdr = types.find((item) => item.type === "sdr");
  const agent = types.find((item) => item.type === "agent");
  const admin = types.find((item) => item.type === "admin");

  const values = `${sdr?.type === undefined ? "" : sdr?.type}, ${
    admin?.type === undefined ? "" : admin?.type
  }, ${agent?.type === undefined ? "" : agent?.type}`;

  return values;
};
export default getType;
