import React from "react";
import GenericModal from "../../../../components/shared/GenericModal";
import { Text } from "../../../../core/ui";
import { StyledButton } from "../../../../components/shared/FormAndFields";
import { UIRow } from "../../../../core/ui/UIStructures";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleDelete: () => void;
};

const DeleteAccountModal = ({
  isOpen,
  onClose,
  handleDelete,
}: Props): JSX.Element => (
  <GenericModal
    isOpen={isOpen}
    onClose={onClose}
    width="500px"
    padding={20}
    overflow="hidden!important"
  >
    <Text
      fontSize={22}
      fontWeight="600"
      textAlign="center"
      color="black"
      mb={40}
    >
      Are you sure?
    </Text>
    <Text color="black" mb={30} textAlign="center">
      This will permanently delete the linked account, all sessions that are not
      synced won&apos;t be synced back.
    </Text>
    <UIRow gap={1} justifyContent={"center"}>
      <StyledButton
        onClick={onClose}
        bg={"darkGray"}
        px={25}
        py={12}
        mt={12}
        width={200}
      >
        <Text fontSize={16} fontWeight="600" color="white">
          Cancel
        </Text>
      </StyledButton>
      <StyledButton
        onClick={handleDelete}
        bg={"lightBlue"}
        px={25}
        py={12}
        mt={12}
        width={200}
      >
        <Text fontSize={16} fontWeight="600" color="white">
          Delete account
        </Text>
      </StyledButton>
    </UIRow>
  </GenericModal>
);

export default DeleteAccountModal;
