import axios from "axios";
import routes from "../../constants/routes";
import jwt_decode from "jwt-decode";

type SessionResponse = {
  authToken: string;
};

type userType = {
  type: string;
};

type UserGroupType = {
  [key: string]: userType;
};

type decoded = {
  user: {
    id: string;
    name: string;
    type: UserGroupType;
    sdrId: string;
    organizationId: string;
    isSuperAdmin: boolean;
    email: string;
  };
  exp: number;
};

export const refreshSessionToken = async (): Promise<boolean> => {
  const userFromSession = window.localStorage.getItem("target");

  try {
    const session = await axios.post<SessionResponse>(
      routes.post.session.refresh,
      { token: userFromSession }
    );

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.data.authToken}`;

    const user = createSessionUser(session.data.authToken);

    window.localStorage.setItem("user", JSON.stringify(user));
    window.localStorage.setItem("target", session.data.authToken);

    return true;
  } catch {
    return false;
  }
};

export const createSessionUser = (authToken: string) => {
  const decoded: decoded = jwt_decode(authToken);

  return {
    userId: decoded.user.id,
    userName: decoded.user.name,
    type: decoded.user.type,
    sdrId: decoded.user.sdrId,
    organizationId: decoded.user.organizationId,
    isSuperAdmin: decoded.user.isSuperAdmin,
    email: decoded.user.email,
  };
};

export const isTokenExpired = (authToken: string) => {
  const decoded: decoded = jwt_decode(authToken);

  return Date.now() >= decoded.exp * 1000;
};
