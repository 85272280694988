import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: #fff;
  justify-content: space-between;
`;

const Sidebar = styled.div`
  flex-grow: 1;
  max-width: 250px; /* Ancho máximo inicial */
  background-color: #ffffff;
  padding: 20px;
  border-right: 1px solid #ccc;
  margin: 5% 0;
  transition: max-width 0.3s ease;

  @media (max-width: 1300px) {
    max-width: 200px;
  }

  @media (max-width: 800px) {
    max-width: 150px;
  }
`;
type MenuItemProps = {
  isActive: boolean;
  onClick: () => void;
};

const MenuItem = styled.div<MenuItemProps>`
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#31a9f9" : "#000")};
  background-color: ${(props) => (props.isActive ? "#b8dff5" : "transparent")};
  padding: 10px;
  border-radius: 50px;
  &:hover {
    background-color: ${(props) => (props.isActive ? "#b8dff5" : "#eaeaea")};
  }
`;

const Content = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const SettingsSideBar = ({ getMenuOption, children }) => {
  const [activeItem, setActiveItem] = useState("Integrations");

  const handleItemClick = (item) => {
    setActiveItem(item);
    getMenuOption(item);
  };
  return (
    <Container>
      <Sidebar>
        <MenuItem
          isActive={activeItem === "Integrations"}
          onClick={() => handleItemClick("Integrations")}
        >
          Integrations
        </MenuItem>
      </Sidebar>
      <Content>{activeItem === "Integrations" ? children : null}</Content>
    </Container>
  );
};

export default SettingsSideBar;
