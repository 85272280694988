import React, { useState, useEffect, useContext } from "react";
import { Form, Field } from "react-final-form";
import { Flex, Text } from "../../../core/ui";
import getActiveOrganizationsAsync, {
  ActiveOrganizationsResponse,
} from "../../../api/Organizations/getActiveOrganizationsAsync";
import {
  composeValidators,
  required,
  minLength,
  maxLength,
} from "../../../core/utils";
import { NumberPool } from "../../../types";
import {
  StyledForm,
  InputField,
  SelectField,
  CheckboxField,
  StyledButton,
} from "../../shared/FormAndFields";
import { UserProviderContext } from "../../app/UserProvider";
import { showUIToast } from "../../../core/ui/UIToast";
import { NumberType } from "../../shared/enums/numbersTypes";
import createNumberAsync from "../../../api/Numbers/createNumberAsync";
import updateNumberAsync from "../../../api/Numbers/updateNumberAsync";

type Props = {
  numberPool?: NumberPool;
  onSuccess: () => void;
};

const CreateEditNumberPoolModal = ({
  numberPool,
  onSuccess,
}: Props): JSX.Element => {
  const [isOrganization, setIsOrganization] = useState<boolean>(
    numberPool ? numberPool.type.includes(NumberType.ORGANIZATION) : false
  );
  const [isNew] = useState<boolean>(!numberPool);
  const [organizations, setOrganizations] = useState<
    ActiveOrganizationsResponse[] | []
  >([]);

  const [types, setTypes] = useState<{ id: string; name: string }[] | []>([
    { id: "GLOBAL", name: NumberType.GLOBAL },
    { id: "ORGANIZATION", name: NumberType.ORGANIZATION },
  ]);

  const [selectedOrg, setSelectedOrg] = useState<string>("");

  const {
    currentUser: { isSuperAdmin, organizationId },
  } = useContext(UserProviderContext);

  const getOrganizations = async () => {
    const { activeOrganizations, error } = await getActiveOrganizationsAsync();
    if (error) return;
    if (activeOrganizations) setOrganizations(activeOrganizations);
  };

  useEffect(() => {
    if (isSuperAdmin) getOrganizations();

    if (numberPool && numberPool?.ownerId) {
      setSelectedOrg(numberPool.ownerId);
    } else {
      setSelectedOrg(organizationId);
    }
  }, []);

  const handleCreateNumber = async (data) => {
    const { numberPool, error } = await createNumberAsync(data);
    if (numberPool) {
      showUIToast({ type: "success", text: "A new number was created." });
      onSuccess();
      return;
    }

    error && showUIToast({ type: "error", text: error.message });
  };

  const handleUpdateNumber = async (data) => {
    const { numberPool, error } = await updateNumberAsync(data);
    if (numberPool) {
      showUIToast({ type: "info", text: "Number updated." });
      onSuccess();
      return;
    }

    error && showUIToast({ type: "error", text: error.message });
  };

  const handleCreateOrEdit = async (values) => {
    const isEdit = typeof values._id !== "undefined";
    let ownerId = null;
    const type =
      "global" === values.type || NumberType.GLOBAL === values.type
        ? NumberType.GLOBAL
        : NumberType.ORGANIZATION;

    const location = values.location;
    if (type === NumberType.ORGANIZATION) {
      ownerId = values.ownerId;
    }
    const number = values.number;
    const active = values.active ? values.active : false;

    const data = {
      location,
      ownerId,
      number,
      type,
      active,
      id: values._id,
      _id: values._id,
    };

    if (isEdit) {
      await handleUpdateNumber(data);
      return;
    }

    await handleCreateNumber(data);
  };

  const validateRecord = (values) => {
    const errors: {
      NumberType?: string;
    } = {};

    if (!values.type?.length) {
      errors.NumberType = "Required";
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleCreateOrEdit}
      initialValues={numberPool || { organizationId }}
      validate={validateRecord}
      render={({ handleSubmit, form, submitting, pristine, valid }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Flex mb="20px" gap="24px">
            <Flex flexDirection="column" flex="1">
              {isSuperAdmin && (
                <Field
                  label="Number"
                  name="number"
                  component={InputField}
                  type="text"
                  validate={composeValidators(
                    required,
                    minLength(10),
                    maxLength(20)
                  )}
                />
              )}
              {isSuperAdmin && (
                <Field
                  label="Location"
                  name="location"
                  component={InputField}
                  type="text"
                />
              )}
            </Flex>
            <Flex flexDirection="column" flex="1">
              {isNew && <></>}
              {isSuperAdmin && (
                <Field
                  label="Type"
                  name="type"
                  component={SelectField}
                  validate={required}
                  defaultValue={"global"}
                  options={types}
                  customOnChange={(e) => {
                    if (e.target.value === NumberType.ORGANIZATION) {
                      setIsOrganization(true);
                    } else {
                      setIsOrganization(false);
                    }
                    setSelectedOrg(e.target.value);
                  }}
                />
              )}

              {isOrganization && isSuperAdmin && organizations.length > 0 ? (
                <Field
                  label="Organization"
                  name="ownerId"
                  component={SelectField}
                  validate={required}
                  options={organizations}
                  customOnChange={(e) => {
                    setSelectedOrg(e.target.value);
                  }}
                />
              ) : (
                <Field name="ownerId" type="hidden" component="input" />
              )}
            </Flex>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-around"
            height="36px"
            mt="8px"
          >
            <Field
              label={"Active"}
              name="active"
              component={CheckboxField}
              type="checkbox"
            />
          </Flex>

          <StyledButton
            bg="lightBlue"
            px={25}
            py={12}
            type="submit"
            mt={12}
            disabled={submitting || pristine || !valid}
          >
            <Text fontSize={16} fontWeight="600" color="white">
              Submit
            </Text>
          </StyledButton>
        </StyledForm>
      )}
    ></Form>
  );
};

export default CreateEditNumberPoolModal;
