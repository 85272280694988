import routes from "../../constants/routes";
import axios from "axios";
import { ApiTransactionResponse, BatchResponse } from "../../types/ApiResponse";
import Organization from "../../types/OrganizationTypes";

const deleteOrganizationAsync = async (
  organizationId: string
): Promise<ApiTransactionResponse<Organization>> => {
  try {
    const { data } = await axios.delete<BatchResponse<Organization>>(
      `${routes.delete.organizations.organizationNest}/${organizationId}`
    );

    return {
      data: data.data,
      transaction: data.transaction,
      success: true,
    };
  } catch (e) {
    return {
      transaction: { count: 0 },
      success: false,
      error: { message: "Error deleting organization" },
    };
  }
};

export default deleteOrganizationAsync;
