import routes from "../../constants/routes";
import axios from "axios";
import { CurrentCallSessionMetrics } from "../../components/shared/CurrentCallSessionMetrics";

type CurrentSessionResponseProps = {
  currentSessionStats?: CurrentCallSessionMetrics | null;
  error?: { message: string };
};

const getCurrentSessionStats =
  async (): Promise<CurrentSessionResponseProps> => {
    try {
      const userSession = JSON.parse(
        window.localStorage.getItem("user") || "{}"
      );
      const callSessionUrl = `${routes.get.callSession.getByMultipleStatusAndSdrIdNest}/${userSession.userId}`;
      const callSessionRes = await axios.get(callSessionUrl, {
        params: { status: ["started", "paused"] },
      });

      let currentSessionMetrics;

      if (callSessionRes.data.id) {
        const metricsURL = `${routes.get.metrics.buildMetrics}/${callSessionRes.data.id}`;
        const metricsRes = await axios.get(metricsURL);
        currentSessionMetrics = metricsRes.data;

        const totalContactsUrl = `${routes.get.contacts.contactsNest}/${callSessionRes.data.contactList[0].contactListId}`;
        const totalContactsRes = await axios.get(totalContactsUrl);

        if (totalContactsRes.data.length) {
          currentSessionMetrics.totalContacts = totalContactsRes.data.length;
        } else {
          currentSessionMetrics.totalContacts = 0;
        }
      }

      return { currentSessionStats: currentSessionMetrics };
    } catch (e) {
      return { error: { message: "Error getting current session stats" } };
    }
  };

export default getCurrentSessionStats;
