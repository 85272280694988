import React, { useEffect, useState } from "react";
import Layout from "../../components/shared/Layout";
import { useHistory, useParams } from "react-router-dom";
import Collapsible from "../../components/collapsible/Collapsible";
import axios from "axios";
import routes from "../../constants/routes";
import UICard from "../../core/ui/UICard";

import Phone from "../../assets/icons/summary/phone.svg";
import Answer from "../../assets/icons/summary/answer.svg";
import Transfer from "../../assets/icons/summary/tranfer.svg";
import Voicemail from "../../assets/icons/summary/voicemail.svg";
import Cutoff from "../../assets/icons/summary/cutoof.svg";
import TransferError from "../../assets/icons/summary/tranfer-errpr.svg";
import NotAnswered from "../../assets/icons/summary/ban.svg";
import BdBs from "../../assets/icons/summary/signal.svg";
import Refusal from "../../assets/icons/summary/phone-slash.svg";
import RefusalTransfer from "../../assets/icons/summary/users-slash.svg";
import NoLonger from "../../assets/icons/summary/user-slash.svg";
import Referred from "../../assets/icons/summary/user-xmark.svg";
import OptOut from "../../assets/icons/summary/circle-xmark.svg";
import SetAppointment from "../../assets/icons/summary/calendar-check.svg";
import Gatekeeper from "../../assets/icons/summary/building-user.svg";
import DialConnect from "../../assets/icons/summary/percent.svg";
import SDRIcon from "../../assets/icons/summary/user-tie-solid.svg";
import ArrowLeft from "../../assets/icons/summary/arrow-left.svg";
import styled from "styled-components";
import { showUIToast } from "../../core/ui/UIToast";
import getCallSessionInfo from "../../api/CallSession/getCallSessionInfo";
import getUserInfoAsync from "../../api/Users/getUserInfo";

const BackButton = styled.label`
  padding: 0 10px;
`;

const BackContainer = styled.label`
  cursor: pointer;
`;
interface RouteParams {
  callsessionid: string;
}

const userPlaceholder = {
  userId: "4ng589627kwa54s8",
  userName: "No Username",
  type: "SDR",
  sdrId: "4ng589627kwa54s8",
  organizationId: "Tendril LLC",
  isSuperAdmin: "false",
  email: "noreplay@tendril.us",
};
interface Props {
  userSession: string;
  handleLogout: () => void;
  intervals: { [key: string]: any };
  setIntervals: (value: any) => void | undefined;
}
const Summary = ({
  userSession,
  handleLogout,
  intervals,
  setIntervals,
}: Props): JSX.Element => {
  const userSessionJSON = JSON.parse(userSession);
  const [extraData, setExtraData] = useState({});
  const history = useHistory();
  const [callSessionInfo, setCallSessionInfo] = useState({});

  const { callsessionid } = useParams<RouteParams>();

  useEffect(() => {
    const fetchMetrics = async (callsessionid) => {
      try {
        if (callsessionid !== "") {
          const metricsURL = `${routes.get.metrics.buildMetrics}/${callsessionid}`;
          const metricsRes = await axios.get(metricsURL);
          const metricsDispositionUrl = `${routes.get.metrics.buildDispositionMetrics}/${callsessionid}`;
          const metricsDisposition = await axios.get(metricsDispositionUrl);

          const totalCalled = metricsRes.data["called"];
          const totalAnswered = metricsRes.data["answered"];
          const totalTransfer = metricsRes.data["transferred"];
          const totalVoicemail = metricsRes.data["voicemail"];
          const totalNotAnswered = metricsRes.data["not_answered"];
          const totalTransferError = metricsRes.data["transfer_error"];
          const totalRTT = metricsDisposition.data["refuse_transfer"];
          const totalBNBS = metricsDisposition.data["bad_number_busy_signal"];
          const totalOO = metricsDisposition.data["opt_out"];
          const totalLVM = metricsDisposition.data["left_vm"];
          const totalPR = metricsDisposition.data["phone_refusal"];
          const totalGC = metricsDisposition.data["gatekeeper_conversation"];
          const totalRAC = metricsDisposition.data["referred_another_contact"];
          const totalNLC = metricsDisposition.data["no_longer_with_company"];
          const totalSA = metricsDisposition.data["set_appointment"];
          const totalCutOff = metricsRes.data["cut_off"];
          const expandableData = {};

          const SDRInfo = await getSDRInfo();

          expandableData[callsessionid] = [
            { text: "Total calls", value: totalCalled, image: Phone },
            { text: "Answered", value: totalAnswered, image: Answer },
            { text: "Transfers", value: totalTransfer, image: Transfer },
            { text: "Voicemails", value: totalVoicemail, image: Voicemail },
            { text: "Cut off", value: totalCutOff, image: Cutoff },
            {
              text: "Transfer errors",
              value: totalTransferError,
              image: TransferError,
            },
            {
              text: "Not answered",
              value: totalNotAnswered,
              image: NotAnswered,
            },
            { text: "BD/BS", value: totalBNBS, image: BdBs },
            { text: "Left VM", value: totalLVM, image: Voicemail },
            { text: "Phone refusal (Hangup)", value: totalPR, image: Refusal },
            {
              text: "Refuse to transfer",
              value: totalRTT,
              image: RefusalTransfer,
            },
            {
              text: "No longer with company",
              value: totalNLC,
              image: NoLonger,
            },
            {
              text: "Referred to another contact",
              value: totalRAC,
              image: Referred,
            },
            { text: "Opt Out", value: totalOO, image: OptOut },
            { text: "Set Appointment", value: totalSA, image: SetAppointment },
            {
              text: "Gatekeeper Conversation",
              value: totalGC,
              image: Gatekeeper,
            },
            {
              text: "Dial to Connect Ratio",
              value:
                totalTransfer === 0
                  ? "0 %"
                  : ((totalTransfer / totalCalled) * 100).toFixed(2) + " %",
              image: DialConnect,
            },
            {
              text: "SDR",
              value: SDRInfo?.userName,
              image: SDRIcon,
            },
          ];

          console.log(expandableData);
          setExtraData((prev) => {
            return { ...prev, ...expandableData };
          });
        }
      } catch (e) {
        showUIToast({
          type: "error",
          text: "Error getting metrics, try again later.",
        });
      }
    };
    fetchMetrics(callsessionid);
  }, [callsessionid]);

  const getSDRInfo = async () => {
    const { callSession, error } = await getCallSessionInfo({
      callSessionId: callsessionid,
    });

    if (error) {
      console.log(error);
      return;
    }

    if (callSession) {
      setCallSessionInfo(callSession);
      const { user, error: errorUser } = await getUserInfoAsync(
        callSession?.sdrId
      );

      if (errorUser) {
        console.log(errorUser);
        return;
      }

      return user ? user : userPlaceholder;
    }

    return userPlaceholder;
  };

  const handleDashboard = async () => {
    history.push("/dashboard");
  };

  return (
    <Layout
      sidebar
      handleLogout={handleLogout}
      user={userSessionJSON.userName}
      intervals={intervals}
      setIntervals={setIntervals}
    >
      <UICard title={"Summary"} width="90%">
        <BackContainer>
          <a onClick={handleDashboard}>
            <img width={"9px"} src={ArrowLeft} alt="icon" />
            <BackButton>Back to Dashboard </BackButton>
          </a>
        </BackContainer>
        <Collapsible extraData={extraData[callsessionid]} show={true} />
      </UICard>
    </Layout>
  );
};

export default Summary;
