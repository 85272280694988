import React from "react";
import GenericModal from "../../shared/GenericModal";
import { Text } from "../../../core/ui";
import { NumberPool } from "../../../types";
import CreateEditNumberPoolModal from "./CreateEditNumberPoolForm";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedNumber: NumberPool;
  onSuccess: () => void;
};

const CreateEditNumberModal = ({
  isOpen,
  onClose,
  selectedNumber,
  onSuccess,
}: Props) => (
  <GenericModal
    isOpen={isOpen}
    onClose={onClose}
    padding={20}
    overflow="hidden!important"
  >
    <Text
      fontSize={22}
      fontWeight="600"
      textAlign="center"
      color="black"
      mb={40}
    >
      {selectedNumber ? "Edit number" : "Add new number"}
    </Text>
    <Text color="black" mb={15} textAlign="center">
      {selectedNumber
        ? `Please edit ${selectedNumber.number} data`
        : "Please fill all the fields"}
    </Text>
    <CreateEditNumberPoolModal
      numberPool={selectedNumber}
      onSuccess={onSuccess}
    />
  </GenericModal>
);

export default CreateEditNumberModal;
