import { ApiResponse } from "../../types/ApiResponse";
import { Contact } from "../../types/ContactTypes";
import axios from "axios";
import routes from "../../constants/routes";

const deleteContactAsync = async (
  id: string
): Promise<ApiResponse<Contact>> => {
  try {
    const response = await axios.delete(
      `${routes.delete.contacts.deleteContactNest}/${id}`
    );
    return {
      success: true,
      data: response.data,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response.status === 400) return {} as ApiResponse<Contact>;
    if (e.response.status === 500) {
      return {
        error: { message: "Error deleting contact." },
      };
    }
    return {
      error: { message: "Something went wrong, try later" },
    };
  }
};

export default deleteContactAsync;
