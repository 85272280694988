const fourMativTemplate = {
  name: "Jon Doe",
  phone: "000-000-0000",
  jobTitle: "Point of Contact",
  email: "example@mail.com",
  accountName: "Tendril LLC",
  notes: "This is a note",
  crmId: "123456-Id",
  ssid: "HBA_14683",
  firstName: "Anzal",
  lastName: "Abdi",
  dateOfBirth: "04/12/2012",
  gender: "F",
  grade: "2",
  returningSchool: "Yes",
  leavingReason: "",
  firstParentGuardianName: "Kossar",
  firstParentGuardianLastName: "Ismail",
  firstParentGuardianEmail: "kossar@example.com",
  firstParentGuardianPhone: "000-000-0000",
  firstParentGuardianRelationChild: "Mother",
  secondParentGuardianName: "Ali",
  secondParentGuardianLastName: "Kossar",
  secondParentGuardianEmail: "ali@example.com",
  secondParentGuardianPhone: "000-000-0000",
  secondParentGuardianRelationChild: "Father",
  phoneTwo: "000-000-0000",
  contactNameTwo: "Abdul",
  homeAddress: "1808 University Ave NE,326",
  homeLineTwo: "1806 University",
  homeCity: "Minniapolis",
  homeZip: "55418",
  transportationStatus: "Not Riding",
  amAddress: "",
  lineTwo: "",
  amCity: "",
  amZip: "",
  pmAddress: "",
  pmLineTwo: "",
  pmCity: "",
  pmZip: "",
  daysOfWeek: "Monday",
  incomingSibling: "Yes",
  additionalNotes: "some notes",
};
export default fourMativTemplate;
