import React, { ReactElement } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { UIText } from "../../../../core/ui/UIElements";
import { User } from "../../../../types";
import { faUserCog } from "@fortawesome/free-solid-svg-icons/faUserCog";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons/faUserGroup";
import { theme } from "../../../../core/theme";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle";

const { lightBlue, lightGray, darkGray } = theme.colors;

type UserListProps = {
  users: Partial<User>[];
};

const UserList = ({ users }: UserListProps): ReactElement => {
  return (
    <UserListContainer>
      {users && users.length > 0 ? (
        <UserListWrapper>
          {users.map((user) => (
            <UserListItem key={`${user._id}_${user.name}`}>
              <ProfilePic>
                <FA icon={faUserCircle} size="2xl" />
              </ProfilePic>
              <UserInfoWrapper>
                <Name>{user.name}</Name>
                <Email>{user.email}</Email>
              </UserInfoWrapper>
              <UserTypeWrapper>
                <UserType active={!!user?.userType?.includes("admin")}>
                  <FA icon={faUserCog} /> Admin
                </UserType>
                <UserType active={!!user?.userType?.includes("sdr")}>
                  <FA icon={faUser} /> Sdr
                </UserType>
                <UserType active={!!user?.userType?.includes("agent")}>
                  <FA icon={faUserGroup} /> Agent
                </UserType>
              </UserTypeWrapper>
            </UserListItem>
          ))}
        </UserListWrapper>
      ) : (
        <UIText>No users were found for this organization.</UIText>
      )}
    </UserListContainer>
  );
};

const UserListContainer = styled.div`
  margin: 0.5rem;
  min-width: 100%;
  max-height: 300px;
  overflow-y: auto;
`;

const UserListWrapper = styled.ul`
  list-style: none;
  padding: 0;
`;

const UserListItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 0;
`;

const ProfilePic = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled(UIText)`
  font-weight: bold;
`;

const Email = styled(UIText)`
  font-size: 0.8rem;
`;

const UserTypeWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 1rem;

  font-size: 0.7rem;
`;

const UserType = styled.div<{ active: boolean }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  border-radius: 1.25rem;
  background-color: ${lightGray};
  color: ${darkGray};

  ${({ active }) =>
    active &&
    css`
      background-color: ${lightBlue};
      color: ${lightGray};
      font-weight: 600;
    `}
`;

export default UserList;
