import React, { useEffect, useRef, useState } from "react";
import { Flex, Text } from "../../core/ui";
import Modal from "../shared/Modal";
import { refreshSessionToken } from "../../core/utils/sessionRefresher";

type Props = {
  modalTimer: boolean;
  setModalTimer: (boolean) => void;
  logoutAction: () => void;
};

const ExpiredSessionModal = ({
  setModalTimer,
  modalTimer,
  logoutAction,
}: Props): JSX.Element => {
  const handleLogout = () => {
    logoutAction();
  };

  const [seconds, setSeconds] = useState<number>(60 * 30);
  const timerRef = React.useRef(seconds);

  const handleRefreshSession = async () => {
    const response = await refreshSessionToken();

    if (response) {
      setModalTimer(false);
    }
  };

  useEffect(() => {
    const timerId = setInterval(function () {
      if (timerRef.current !== 0) {
        timerRef.current -= 1;

        setSeconds(timerRef.current);
      } else {
        setModalTimer(false);
        handleLogout();
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds % 60;
  let secounsResult = secondsLeft.toString();
  if (secondsLeft < 10) {
    secounsResult = "0" + secounsResult;
  }

  return (
    <Modal
      state={modalTimer}
      setState={setModalTimer}
      title={"Session Expired"}
      showHeader={false}
      showOverlay={true}
      modalPosition={"center"}
      padding={"20px"}
      showCloseBtn={false}
      zIndex={2}
    >
      <>
        <p>Your session will expire soon.</p>

        <p>
          {" "}
          {minutes} : {secounsResult}
        </p>

        <Flex flexDirection="row" justifyContent="space-between">
          <button onClick={handleRefreshSession}> Stay login </button>
          <button onClick={handleLogout}> Logout </button>
        </Flex>
      </>
    </Modal>
  );
};

export default ExpiredSessionModal;
