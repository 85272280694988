import routes from "../../constants/routes";
import axios from "axios";
import { NumberPool } from "../../types";

type ResponseProps = {
  number?: NumberPool;
  error?: { message: string };
};

const deleteNumberAsync = async (numberId: string): Promise<ResponseProps> => {
  try {
    const number = await axios.delete<NumberPool>(
      `${routes.delete.numbers.deleteNumber}/${numberId}`
    );

    return { number: number.data };
  } catch {
    return { error: { message: "Error trying to delete number" } };
  }
};

export default deleteNumberAsync;
