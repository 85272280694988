import routes from "../../constants/routes";
import axios from "axios";

type ResponseProps = {
  authToken?: string;
  error?: { message: string };
};

const login = async (
  email: string,
  password: string
): Promise<ResponseProps> => {
  try {
    const data = {
      email,
      password,
    };

    const response = await axios.post(routes.post.auth.login, data);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.authToken}`;

    return { authToken: response.data.authToken };
  } catch (e) {
    return { error: { message: "error" } };
  }
};

export default login;
