import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../core/theme";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons/faPenToSquare";
import { UIDropDownItem, UIHoverIconMenu } from "../../core/ui/UIDropDown";

const List = styled.ul<{
  height?: string;
}>`
  display: flex;
  flex-direction: column;
  ${({ height }) => ({
    minHeight: height,
    maxHeight: height,
    overflowY: "scroll",
  })};
  background-color: ${theme.colors.lightGray};
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 0 0 0.5rem 0.5rem;
  overflow-y: auto;
`;

const ListButton = styled.li`
  display: flex;
  gap: 0.5em;
  align-items: center;
  width: 100%;
  padding: 1em 1.5em;
  label {
    flex: 1;
  }
  background-color: white;
  cursor: pointer;
  border-bottom: 1px solid ${theme.colors.lightGray};
  transition: 0.25s;
  &.selected {
    color: white;
    background-color: ${theme.colors.lightBlue};
  }
`;

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  radioBtn: boolean;
  setOptionSelected: (value) => void;
  setVisible: (value) => void;
  deleteItem?: (e) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editItem?: (e: any) => void;
  newItemTitle?: string;
  height?: string;
  colorScroll?: string;
}

const GenericList: React.FC<Props> = ({
  data,
  radioBtn,
  setVisible,
  deleteItem,
  editItem,
  newItemTitle,
  setOptionSelected,
  height,
}) => {
  const [dataList, setDataList] = useState([] as any[]);

  useEffect(() => {
    if (newItemTitle) {
      setDataList([
        ...data.map((item) => ({ ...item, select: false })),
        {
          id: "newItem",
          organizationId: "newItem",
          title: newItemTitle,
          select: true,
        },
      ]);
    } else {
      setDataList(data.map((item) => ({ ...item, select: false })));
    }
  }, [data]);

  const handleChangeRadioBtn = (id: string, tendrilId: null | string) =>
    setDataList(
      dataList.map((d) => {
        if (d.id !== id) d.select = false;
        if (d.id === "newItem" && d.select === true) setVisible(true);
        if (d.tendrilId && tendrilId && d.tendrilId !== tendrilId)
          d.select = false;
        return d;
      })
    );

  const handleChangeCheckBox = (id: string, tendrilId: null | string) =>
    setDataList(
      dataList.map((d) => {
        d.select = id === d.id;
        if (id === "newItem") setVisible(true);
        if (d.tendrilId && tendrilId && d.tendrilId !== tendrilId) {
          d.select = false;
        }
        return d;
      })
    );

  useEffect(() => {
    setOptionSelected(dataList);
  }, [dataList]);

  const handleChange = (id: string, tendrilId: null | string) => {
    switch (radioBtn) {
      case true:
        handleChangeRadioBtn(id, tendrilId);
      case false:
      default:
        handleChangeCheckBox(id, tendrilId);
    }
  };

  return (
    <List height={height}>
      {dataList.map((element: { id; select; title; tendrilId }) => (
        <ListButton
          data-qa-id={`list_${element.title.split(" ").join("_")}`}
          key={`ListButton_${element.id}`}
          className={`${element.select && "selected"}`}
          value={element.title}
          onClick={() => handleChange(element.id, element.tendrilId || null)}
        >
          {element.select ? (
            <FA icon={["fas", radioBtn ? "circle-dot" : "square-check"]} />
          ) : (
            <FA icon={["fas", radioBtn ? "circle" : "square"]} />
          )}
          {element.title}
          {element.id !== "newItem" && (deleteItem || editItem) && (
            <UIHoverIconMenu
              dataTitle={`menu_${element.title.split(" ").join("_")}`}
            >
              {editItem && (
                <UIDropDownItem
                  data-qa-id={`edit_${element.title.split(" ").join("_")}`}
                  key={`UIDropDownItemEdit_${element.id}`}
                  className={`${element.select && "selected"}`}
                  value={element.title}
                  onClick={() => editItem(element)}
                >
                  <FA icon={faPenToSquare} />
                  Edit
                </UIDropDownItem>
              )}
              {deleteItem && (
                <UIDropDownItem
                  data-qa-id={`delete_${element.title.split(" ").join("_")}`}
                  key={`UIDropDownItemDelete_${element.id}`}
                  className={`${element.select && "selected"}`}
                  value={element.title}
                  onClick={() => deleteItem(element)}
                >
                  <FA icon={faTrash} />
                  Delete
                </UIDropDownItem>
              )}
            </UIHoverIconMenu>
          )}
        </ListButton>
      ))}
    </List>
  );
};
export default GenericList;
