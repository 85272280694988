import routes from "../../constants/routes";
import axios from "axios";

type DeleteProps = {
  success?: string;
  error?: { message: string };
};

const deleteHubspotAccountAsync = async (
  orgId: string,
  userId: string,
  nonce: string,
  custom: boolean
): Promise<DeleteProps> => {
  try {
    await axios.delete(
      `${
        custom
          ? routes.delete.customHubspot.deleteAccount
          : routes.delete.hubspot.deleteAccount
      }/${orgId}/${userId}/${nonce}`
    );

    return { success: "Account deleted successfully" };
  } catch (error) {
    console.log(error);
    return { error: { message: "Error while deleting account" } };
  }
};

export default deleteHubspotAccountAsync;
