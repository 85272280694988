import React, { useEffect, useState } from "react";
import Layout from "../../components/shared/Layout";
import { useHistory, useLocation } from "react-router-dom";
import {
  CallSession,
  LocationState,
  TwilioTokenSuccessResponse,
  VoiceMailList,
} from "../../components/shared/CallSession";
import { checkAndRedirectCallSession } from "../../core/utils/redirectCallSession";
import NewVoicemail from "../../components/newVoicemail/newVoicemail";
import PlayVoicemail from "../../components/newVoicemail/PlayVoicemail";
import routes from "../../constants/routes";
import axios from "axios";
import { Device } from "twilio-client";
import tabTitle from "../../utils/updateTitle";
import UICard from "../../core/ui/UICard";
import GenericList from "../../components/shared/GenericList";
import { UIButton, UIInputText } from "../../core/ui/UIElements";
import getRecordingByCallIdAsync from "../../api/Recording/getRecordingByCallIdAsync";
import { showUIToast } from "../../core/ui/UIToast";
import getTwilioToken from "../../api/Twilio/getTwilioToken";
import { Recording, UpdateRecordingDto } from "../../types/Recording";
import { updateRecordingAsync } from "../../api/Recording/updateRecordingAsync";
import EditVoicemail from "../../components/newVoicemail/editVoicemail";

interface Props {
  userSession: string;
  handleLogout: () => void;
  twilioDevice: Device;
  setTwilioDevice: (device: Device) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intervals: { [key: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIntervals: (value: any) => void | undefined;
}

const Voice = ({
  userSession,
  handleLogout,
  setIntervals,
  intervals,
}: Props): JSX.Element => {
  const [callSession, setCallSession] = useState<CallSession>(
    {} as CallSession
  );
  const history = useHistory();
  const location = useLocation();
  const userSessionJSON = JSON.parse(userSession);
  const [localTwilioDevice, setLocalTwilioDevice] = useState<Device>(
    {} as Device
  );
  const [isSession, setIsSession] = useState(true);
  const [voiceMailList, setVoiceMailList] = useState<Recording[]>([]);
  const [visible, setVisible] = useState(false);
  const [optionSelected, setOptionSelected] = useState([] as any[]);
  const [recordingTitle, setRecordingTitle] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [currentVoicemail, setCurrentVoicemail] = useState<
    Recording | undefined
  >(undefined);
  const [showEditRecording, setShowEditRecording] = useState<boolean>(false);
  const [editData, setEditData] = useState<UpdateRecordingDto>({
    id: "",
    title: "",
  });
  const [btnText, setBtnText] = useState("Upload");
  const [newData, setNewData] = useState<Pick<Recording, "id" | "title">>({
    id: "",
    title: "",
  });

  if (window.location.search === "?setup=1") {
    tabTitle("Tendril Connect | Voicemail - setup");
  } else {
    tabTitle("Tendril Connect | Voicemail");
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = location.state as LocationState;
    const setup = urlParams.get("setup");
    if (setup && setup === "1" && state) {
      setIsSession(true);
      setCallSession(state.callSession);
    } else {
      history.push({
        pathname: "/voice",
      });
      setIsSession(false);
    }
  }, [callSession, location.state]);

  useEffect(() => {
    fetchVoiceMailList();
    (async () => {
      const { twilioTokenResponse, error } = await getTwilioToken(
        userSessionJSON.userId
      );
      const token = twilioTokenResponse?.token;
      if (error || !twilioTokenResponse || !token) {
        showUIToast({
          type: "error",
          text: "Twilio requires attention. If the problem persist, please contact customer support.",
        });
        return false;
      }

      const auxTwilioDevice = new Device(token);
      auxTwilioDevice.disconnect(function (connection) {
        setIsRecording(false);
        fetchNewVoiceMail(connection.parameters.CallSid).then();
      });
      setLocalTwilioDevice(auxTwilioDevice);
    })();
  }, [userSessionJSON.userId]);

  const fetchVoiceMailList = async () => {
    try {
      const recordingUrl = `${routes.get.voicemail.voicemailListNest}/${userSessionJSON.userId}`;
      const voicemailListRes = await axios.get<VoiceMailList[]>(recordingUrl);
      if (voicemailListRes.data.length > 0 && voicemailListRes.status === 200) {
        setVoiceMailList(voicemailListRes.data);
        setShowButton(false);
        setDisabled(true);
      } else {
        setVisible(true);
      }
    } catch (error) {
      showUIToast({
        type: "error",
        text: "Error getting voicemail list.",
      });
    }
  };

  useEffect(() => {
    const handleChangeOption = () => {
      optionSelected.forEach((item) => {
        if (item.id !== "newItem" && item.select === true) {
          if (isSession) {
            setShowButton(true);
            setDisabled(false);
          } else {
            setShowButton(false);
            setDisabled(true);
          }
        }
        if (item.id === "newItem" && item.select === true) {
          setShowButton(false);
          setDisabled(true);
        }
      });
    };
    handleChangeOption();

    const current = optionSelected.find((opt) => opt.select);
    setCurrentVoicemail(current?.id !== "newItem" ? current : undefined);
  }, [optionSelected]);

  const selectVoicedropsCallback = () => {
    optionSelected.forEach((item) => {
      if (item.id !== "newItem" && item.select === true) {
        const callSession1 = callSession;
        callSession1.voiceDropsIds = [item.id];
        callSession1.voiceDropsTitles = [item.title];
        checkAndRedirectCallSession(callSession, history);
        showUIToast({
          type: "info",
          text: "Voicemail selected",
        });
      }
    });
  };

  const fetchNewVoiceMail = async (callSid: string) => {
    setShowButton(false);
    setDisabled(true);
    const { success, error } = await getRecordingByCallIdAsync(callSid);
    if (error && !success) {
      showUIToast({ type: "error", text: error.message });
      return;
    }
    await fetchVoiceMailList();
    showUIToast({
      type: "success",
      text: "A new voicemail was created.",
    });
    setVisible(false);
  };

  const handleDeleteItem = async (element) => {
    try {
      const { id } = element;

      if (id) {
        const deleteItemUrl = `${routes.delete.voicemail.deleteVoicemailNest}/${id}`;
        const deleteReq = await axios.delete(deleteItemUrl);
        if (deleteReq.status === 200) {
          showUIToast({
            type: "info",
            text: "Voicemail deleted.",
          });
          const voiceMailListUpdated = voiceMailList.filter((item) => {
            if (item.id !== id && item.id !== "newItem") {
              return item;
            }
          });
          setVoiceMailList(voiceMailListUpdated);
          setRecordingTitle("");
          if (voiceMailListUpdated.length === 1) {
            setVisible(true);
          }
          setOptionSelected([]);
        }
      }
    } catch (error) {
      showUIToast({
        type: "error",
        text: "Error deleting voicemail.",
      });
    }
  };

  const handleUpdateItem = async (
    recording: Pick<Recording, "id" | "title">
  ) => {
    if (isSession) {
      showUIToast({
        type: "warning",
        text: "You can't edit a recording while configuring a session.",
      });
    }

    if (!showEditRecording || !recording) {
      setTimeout(() => {
        const selected = optionSelected.find((item) => item.select === true);
        setEditData(selected);
        setShowEditRecording(true);
        setBtnText("Update");
        setShowButton(true);
        setDisabled(false);
      }, 100);
      return;
    }

    const { id: recordingId, title } = recording;
    if (title === "") {
      showUIToast({
        type: "warning",
        text: "The Recording name is required.",
      });
    }

    if (
      currentVoicemail?.title !== title &&
      currentVoicemail?.id === recordingId &&
      recordingId
    ) {
      const { data, success, error } = await updateRecordingAsync({
        id: recordingId,
        title,
      });

      if (success && data) {
        showUIToast({
          type: "info",
          text: "Recording updated.",
        });
        console.log(data);
        setVoiceMailList((previousRecordings) =>
          previousRecordings.map((previousRecording) => {
            if (previousRecording.id === data.id) {
              return {
                ...previousRecording,
                title: data.title,
              };
            }
            return previousRecording;
          })
        );
        resetEditRecording();
      }
      error && showUIToast({ type: "error", text: error.message });
    }
  };

  const handleSaveUpdate = () => {
    if (!isSession && showEditRecording) {
      handleUpdateItem(editData);
      return;
    }
    isSession ? selectVoicedropsCallback() : handleCard();
  };

  const handleCard = () => {
    setRecordingTitle("");
    handleNewVoicemail();
  };

  const handleNewVoicemail = async () => {
    if (recordingTitle === "") {
      showUIToast({
        type: "warning",
        text: "Voicemail name is required.",
      });
    } else {
      if (!isRecording) {
        try {
          // TODO: Figure out why this error :C
          // twilioDevice!.sounds.outgoing(false)
          // twilioDevice!.sounds.disconnect(false)
          localTwilioDevice.connect(
            {
              number: userSessionJSON.userId,
              isVoicedrop: "true",
              agent: userSessionJSON.userId,
              recordTitle: recordingTitle,
              newRecording: "true",
              recordId: "",
            },
            true
          );
        } catch (e) {
          showUIToast({
            type: "error",
            text: "Twilio requires attention. If the problem persist, please contact customer support.",
          });
        }
      } else {
        localTwilioDevice.activeConnection()?.sendDigits("*");
      }
      setIsRecording(!isRecording);
    }
  };

  const resetEditRecording = () => {
    setShowEditRecording(false);
    setEditData({
      id: "",
      title: "",
    });
    setBtnText("Save");
    setShowButton(false);
    setDisabled(true);
  };

  return (
    <Layout
      sidebar
      handleLogout={handleLogout}
      user={userSessionJSON.userName}
      intervals={intervals}
      setIntervals={setIntervals}
    >
      <UICard
        title={isSession ? "Select your voicemail" : "Add your voicemail"}
        width="375px"
      >
        <GenericList
          data={voiceMailList}
          radioBtn={true}
          setOptionSelected={setOptionSelected}
          setVisible={setVisible}
          deleteItem={handleDeleteItem}
          editItem={handleUpdateItem}
          newItemTitle="+ New Voicemail"
          height="325px"
        />
        {currentVoicemail && currentVoicemail?.title ? (
          showEditRecording ? (
            <EditVoicemail
              recordingDto={editData}
              setRecordingDto={setEditData}
            />
          ) : (
            <PlayVoicemail voicemail={currentVoicemail} />
          )
        ) : (
          <NewVoicemail
            handleBtn={handleNewVoicemail}
            setRecordingTitle={setRecordingTitle}
            isRecording={isRecording}
          />
        )}
        {showButton && (
          <UIButton
            data-qa-id="voice-btn"
            disabled={disabled}
            onClick={handleSaveUpdate}
          >
            {btnText}
          </UIButton>
        )}
      </UICard>
    </Layout>
  );
};

export default Voice;
