import React, { useState, ReactNode, createContext } from "react";
import { ThemeProvider, DefaultTheme } from "styled-components";
import { theme, themeFree, themePause, themeBusy } from "../../core/theme";
import { FREE, BUSY, PAUSED } from "../../constants/theme";

type ThemeProviderWrapperProps = {
  changeTheme(status: string): void;
};
const ThemeProviderWrapperContext = createContext<ThemeProviderWrapperProps>({
  changeTheme: () => {
    /* only for declaration purposes */
  },
});

type Props = {
  children: ReactNode;
};
const ThemeProviderWrapper = ({ children }: Props): JSX.Element => {
  const [customTheme, setCustomTheme] = useState<DefaultTheme>(theme);

  const changeTheme = (status: string) => {
    const themes = {
      [FREE]: themeFree,
      [BUSY]: themeBusy,
      [PAUSED]: themePause,
    };
    setCustomTheme(themes[status] || theme);
  };
  return (
    <ThemeProviderWrapperContext.Provider value={{ changeTheme }}>
      <ThemeProvider theme={customTheme}>{children}</ThemeProvider>
    </ThemeProviderWrapperContext.Provider>
  );
};

export default ThemeProviderWrapper;

export { ThemeProviderWrapperContext };
