import routes from "../../constants/routes";
import axios from "axios";
import { User } from "../../types";

type ResponseProps = {
  user?: User;
  error?: { message: string };
};

const updateUserAsync = async (userData): Promise<ResponseProps> => {
  try {
    const user = await axios.put<User>(
      `${routes.put.users.user}/${userData._id}`,
      userData
    );
    return { user: user.data };
  } catch {
    return { error: { message: "Error trying to update user" } };
  }
};

export default updateUserAsync;
