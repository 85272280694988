import React from "react";
import GenericModal from "../../shared/GenericModal";
import CreateEditOrgForm from "./CreateEditOrgForm";
import { Text } from "../../../core/ui";
import Organization from "../../../types/OrganizationTypes";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedOrg?: Organization;
  onSuccess: () => void;
};

const CreateEditOrgModal = ({
  isOpen,
  onClose,
  selectedOrg,
  onSuccess,
}: Props): JSX.Element => (
  <GenericModal
    isOpen={isOpen}
    onClose={onClose}
    width="500px"
    padding={20}
    overflow="hidden!important"
  >
    <Text
      fontSize={22}
      fontWeight="600"
      textAlign="center"
      color="black"
      mb={40}
    >
      {selectedOrg
        ? `Edit ${selectedOrg.name.split(" ")[0].toUpperCase()}'s organization`
        : "Create new organization"}
    </Text>
    <CreateEditOrgForm organization={selectedOrg} onSuccess={onSuccess} />
  </GenericModal>
);

export default CreateEditOrgModal;
