import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UIButton, UIText } from "../../../../core/ui/UIElements";
import GenericModal from "../../../../components/shared/GenericModal";
import UserList from "./UserList.component";
import getOrganizationUsersAsync from "../../../../api/Organizations/getOrganizationUsersAsync";
import { showUIToast } from "../../../../core/ui/UIToast";
import { UpdateOrganizationDto } from "../../../../types/OrganizationTypes";
import { User } from "../../../../types";
import deleteOrganizationAsync from "../../../../api/Organizations/deleteOrganizationAsync";

type Props = {
  organization: UpdateOrganizationDto | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const DeleteOrganizationModal = ({
  organization,
  isOpen,
  onClose,
  onSuccess,
}: Props): JSX.Element => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const getOrganizationUsers = async (): Promise<void> => {
      if (!organization) {
        return;
      }

      const { data, success, error } = await getOrganizationUsersAsync(
        organization?.name
      );

      if (success && !!data) {
        setUsers(data?.data ?? []);
        return;
      }

      error && showUIToast({ type: "error", text: error.message });
    };

    isOpen && getOrganizationUsers();

    return () => {
      setUsers([]);
    };
  }, [isOpen, organization]);

  const handleDeleteOrganization = async () => {
    if (!organization) {
      showUIToast({ type: "error", text: "Organization not found." });
      return;
    }

    const { transaction, data, success, error } = await deleteOrganizationAsync(
      organization.id ?? organization._id
    );

    if (success && !!data) {
      showUIToast({
        type: "info",
        text: `Organization deleted. ${transaction.count} users have been removed.`,
      });
      onSuccess();
      return;
    }

    error && showUIToast({ type: "error", text: error.message });
  };

  return (
    <>
      {isOpen && (
        <GenericModal onClose={onClose} isOpen={isOpen} maxWidth="700px">
          <ModalWrapper>
            <ModalTitle>
              Delete {organization?.name.toUpperCase()} organization
            </ModalTitle>
            <ModalContent>
              <UIText>
                Are you sure you want to delete this organization? Deleting the
                organization will also remove the following users:
              </UIText>
              <UserList users={users} />
              <UIText>
                This action is irreversible and will permanently delete the
                organization and its associated users. Please take a moment to
                review the list of users and consider the consequences before
                proceeding.
              </UIText>
            </ModalContent>
            <ModalButtons>
              <UIButton
                onClick={() => handleDeleteOrganization()}
                size="md"
                color="danger"
              >
                Delete Anyway
              </UIButton>
              <UIButton onClick={onClose} size="md" color="secondary">
                Cancel
              </UIButton>
            </ModalButtons>
          </ModalWrapper>
        </GenericModal>
      )}
    </>
  );
};

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 0.5rem;
`;

const ModalTitle = styled(UIText)`
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  color: black;
  line-height: 1.25;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  gap: 0.5rem;
  padding: 0.5rem;
`;

const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export default DeleteOrganizationModal;
