import React, { useContext, useEffect, useState } from "react";
import { Flex, Text, Image, Button } from "../../../core/ui";
import { TransparentButton } from "../../shared";
import {
  GenericTableHeader,
  PaginatedProps,
  SearchProps,
} from "../../../core/ui/UITable";
import CreateEditNumberModal from "./CreateEditNumberModal";
import DeleteNumberModal from "./DeleteNumberModal";
import Edit from "../../../assets/icons/edit_blue.svg";
import Delete from "../../../assets/icons/garbage_gray.svg";
import UICard from "../../../core/ui/UICard";
import UITable from "../../../core/ui/UITable";
import Loader from "../../../core/ui/UILoader";
import { showUIToast } from "../../../core/ui/UIToast";
import getNumbersAsync from "../../../api/Management/getNumbersAsync";
import Inactive from "../../../assets/icons/summary/xmark.svg";
import Active from "../../../assets/icons/summary/active.svg";
import { UserProviderContext } from "../../app/UserProvider";
import { NumberPool } from "../../../types";
import getOrganizationInfo from "../../../api/Organizations/getOrganizationInfo";
import { NumberType } from "../../shared/enums/numbersTypes";
import EnableNumberOrgModal from "./EnableNumberOrgModal";

const icons = {
  ACTIVE: Active,
  INACTIVE: Inactive,
};

const NumbersManagementContainer = (): JSX.Element => {
  const [numbers, setNumbers] = useState<any[]>([]);
  const [selectedNumber, setSelectedNumber] = useState<any>(null);
  const [showCreateEditModal, setShowCreateEditModal] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedData, setPaginatedData] = useState<
    PaginatedProps | undefined
  >(undefined);
  const [searchData, setSearchData] = useState<SearchProps | undefined>(
    undefined
  );
  const [search, setSearch] = useState("");
  const [numberPoolId, setNumberPoolId] = useState("");
  const [loadingStatus, updateLoadingStatus] = useState<{
    numbers: boolean;
  }>({ numbers: true });

  const [showNumberOrgModal, setShowNumberOrfModal] = useState<boolean>(false);

  const {
    currentUser: { isSuperAdmin, organizationId },
  } = useContext(UserProviderContext);

  //For table fields, custom render will be the preferred way if the value is not string
  const renderNumberStatus = (numberPool: NumberPool) => {
    let icon = icons["INACTIVE"];

    if (isSuperAdmin) {
      if (numberPool.active) {
        icon = icons["ACTIVE"];
      }
    } else {
      if (
        (numberPool.active && numberPool.type === NumberType.ORGANIZATION) ||
        (numberPoolId && numberPool._id === numberPoolId)
      ) {
        icon = icons["ACTIVE"];
      }
    }

    return isSuperAdmin ? (
      <div>
        <img width={"15px"} src={icon} alt="icon" />
      </div>
    ) : (
      <TransparentButton
        onClick={() => handleShowEnableOrgNumberModal(numberPool)}
      >
        <img width={"15px"} src={icon} alt="icon" />
      </TransparentButton>
    );
  };

  const handleEditNumber = (numberPool) => {
    setSelectedNumber(numberPool);
    setShowCreateEditModal(true);
  };

  const handleShowDeleteNumberModal = (numberPool) => {
    setSelectedNumber(numberPool);
    setShowDeleteModal(true);
  };

  const handleShowEnableOrgNumberModal = (numberPool) => {
    setSelectedNumber(numberPool);
    setShowNumberOrfModal(true);
  };

  const renderOperations = (numberPool) => {
    const canBeDeleted = numberPool.type !== "DEFAULT";
    return (
      <Flex gap="20px">
        {canBeDeleted && isSuperAdmin && (
          <TransparentButton onClick={() => handleEditNumber(numberPool)}>
            <Image height="20px" src={Edit} alt="edit" />
          </TransparentButton>
        )}
        {canBeDeleted && isSuperAdmin && (
          <TransparentButton
            onClick={() => handleShowDeleteNumberModal(numberPool)}
          >
            <Image height="20px" src={Delete} alt="delete" />
          </TransparentButton>
        )}
      </Flex>
    );
  };

  const headers: GenericTableHeader[] = [
    { text: "Number", width: "1.5" },
    { text: "Location", width: "1.5" },
    { text: "Number type", width: "1.5" },
    { text: "Owner", width: "1.5" },
    { text: "Status", width: "1" },
  ];

  const tableBodies = [
    "number",
    "location",
    "type",
    "ownerName",
    { id: "active", customRender: true, renderFunc: renderNumberStatus },
  ];

  if (isSuperAdmin) {
    tableBodies.push({
      id: "operations",
      customRender: true,
      renderFunc: renderOperations,
    });
    headers.push({ text: "", width: "0.5" });
  }

  const getNumbers = async (pageNumber: number, searchValue: string) => {
    const { data, totalCount, totalPages, error } = await getNumbersAsync(
      pageNumber,
      pageSize,
      searchValue
    );

    if (!isSuperAdmin) {
      const { organization, error } = await getOrganizationInfo({
        organizationId,
      });

      if (organization?.numberPoolId) {
        setNumberPoolId(organization.numberPoolId);
      } else {
        setNumberPoolId("");
      }
    }

    setPageNumber(pageNumber);
    setNumbers(data === undefined ? [] : data);
    setPaginatedData({
      pageNumber,
      totalCount: totalCount ? totalCount : 0,
      totalPages: totalPages ? totalPages : 1,
      pageSize,
      onPrevPage: handlePrev,
      onNextPage: handleNext,
    });
    setSearchData({
      placeHolder: "Search by number, location or organization",
      onSearch: handleSearch,
    });

    updateLoadingStatus({ ...loadingStatus, numbers: false });

    error && showUIToast({ type: "error", text: error.message });
  };

  useEffect(() => {
    getNumbers(pageNumber, search);
  }, [pageNumber, search, numberPoolId]);

  const handleSearch = async (searchData) => {
    await setPageNumber(1);
    await setSearch(searchData);
  };

  const handlePrev = () => {
    setPageNumber((last) => last - 1);
  };

  const handleNext = () => {
    setPageNumber((last) => last + 1);
  };

  const handleOnCloseCreateOrEditModal = () => {
    setShowCreateEditModal(false);
    cleanup();
  };

  const handleEnableModal = () => {
    setShowNumberOrfModal(false);
    cleanup();
  };

  const handleOnSuccessModal = () => {
    getNumbers(1, search);
    setShowDeleteModal(false);
    setShowCreateEditModal(false);
    setShowNumberOrfModal(false);
    cleanup();
  };

  const handleOnCloseDeleteNumberModal = () => {
    setShowDeleteModal(false);
    cleanup();
  };

  const cleanup = () => {
    setSelectedNumber(null);
  };
  return (
    <>
      <UICard
        title={"Contacts from selected List"}
        showTitle={false}
        width={"100%"}
        height="580px"
      >
        {loadingStatus.numbers ? (
          <Loader message={"Loading numbers, please wait..."} />
        ) : (
          <UITable
            headers={headers}
            tableBodies={tableBodies}
            data={numbers}
            visible={false}
            paginatedData={paginatedData}
            searchData={searchData}
          >
            {isSuperAdmin && (
              <Flex padding="0 2rem" justifyContent="flex-end">
                <Button
                  bg="lightBlue"
                  px={25}
                  py={12}
                  onClick={() => setShowCreateEditModal(true)}
                  style={{ paddingBottom: "6px", paddingTop: "6px" }}
                >
                  <Flex alignItems="center">
                    <Text fontSize={15} color="white">
                      Add new number
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            )}
          </UITable>
        )}
      </UICard>
      <CreateEditNumberModal
        isOpen={showCreateEditModal}
        onClose={handleOnCloseCreateOrEditModal}
        selectedNumber={selectedNumber}
        onSuccess={handleOnSuccessModal}
      />
      <DeleteNumberModal
        isOpen={showDeleteModal}
        onClose={handleOnCloseDeleteNumberModal}
        selectedNumber={selectedNumber}
        onSuccess={handleOnSuccessModal}
      />
      <EnableNumberOrgModal
        isOpen={showNumberOrgModal}
        onClose={handleEnableModal}
        selectedNumber={selectedNumber}
        onSuccess={handleOnSuccessModal}
        numberPoolId={numberPoolId}
      />
    </>
  );
};

export default NumbersManagementContainer;
