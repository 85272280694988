import { CallSession } from "../../components/shared/CallSession";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const checkAndRedirectCallSession = (
  callSession: CallSession,
  history
): void => {
  if (!callSession.contactList) {
    history.push({
      pathname: "/contacts",
      search: "?setup=1",
      state: { callSession: callSession },
    });
  } else if (!callSession.voiceDropsIds) {
    history.push({
      pathname: "/voice",
      search: "?setup=1",
      state: { callSession: callSession },
    });
  } else if (!callSession.speech) {
    history.push({
      pathname: "/script",
      search: "?setup=1",
      state: { callSession: callSession },
    });
  } else {
    history.push({
      pathname: "/home",
      search: "?setup=1",
      state: { callSession: callSession },
    });
  }
};
