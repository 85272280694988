import routes from "../../constants/routes";
import axios from "axios";

type DeleteProps = {
  success?: string;
  error?: { message: string };
};

const deleteOutreachAccountAsync = async (
  orgId: string,
  userId: string,
  nonce: string
): Promise<DeleteProps> => {
  try {
    await axios.delete(
      `${routes.delete.outreach.deleteAccount}/${orgId}/${userId}/${nonce}`
    );

    return { success: "Account deleted successfully" };
  } catch (error) {
    return { error: { message: "Error while deleting account" } };
  }
};

export default deleteOutreachAccountAsync;
