import routes from "../../constants/routes";
import axios from "axios";

type ResponseProps = {
  number?: string;
  error?: { message: string };
};

const enableNumberPool = async (
  id: string,
  active: boolean
): Promise<ResponseProps> => {
  try {
    const number = await axios.put<string>(
      `${routes.put.numbers.enableNumberPool}/${id}`,
      { active }
    );
    return { number: number.data };
  } catch {
    return { error: { message: "Error trying to update Number" } };
  }
};

export default enableNumberPool;
