import React, { useEffect, useState } from "react";
import Layout from "../../components/shared/Layout";
import NewScript from "../../components/newScript/newScript";
import axios from "axios";
import routes from "../../constants/routes";
import { useHistory, useLocation } from "react-router-dom";
import {
  CallSession,
  LocationState,
} from "../../components/shared/CallSession";
import { checkAndRedirectCallSession } from "../../core/utils/redirectCallSession";
import ScriptPreview from "../../components/newScript/ScriptPreview";
import tabTitle from "../../utils/updateTitle";
import UICard from "../../core/ui/UICard";
import GenericList from "../../components/shared/GenericList";
import { UIButton } from "../../core/ui/UIElements";
import { UICol, UIRow } from "../../core/ui/UIStructures";
import { Script as ScriptType } from "../../types/ScriptTypes";
import updateScriptAsync from "../../api/Scripts/updateScriptAsync";
import { showUIToast } from "../../core/ui/UIToast";
import getUser, { UserResponseProps } from "../../api/Users/getUser";

interface Type {
  type: string;
}

interface OrgIdSuccessResponse {
  name: string;
  email: string;
  organizationId: string;
  userType: Type[];
  id: string;
}

interface Props {
  userSession: string;
  handleLogout: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intervals: { [key: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIntervals: (value: any) => void | undefined;
}

const Script = ({
  userSession,
  handleLogout,
  intervals,
  setIntervals,
}: Props): JSX.Element => {
  const [callSession, setCallSession] = useState<CallSession>(
    {} as CallSession
  );
  const history = useHistory();
  const location = useLocation();
  const userSessionJSON = JSON.parse(userSession);
  const [scriptList, setScriptList] = useState<ScriptType[]>([]);
  const [isSession, setIsSession] = useState(false);
  const [orgId, setOrgId] = useState<string | undefined>("");
  const [newData, setNewData] = useState<Omit<ScriptType, "organizationId">>({
    title: "",
    body: "",
  });
  const [editData, setEditData] = useState<ScriptType | undefined>(undefined);
  const [visible, setVisible] = useState(false);
  const [optionSelected, setOptionSelected] = useState([] as any[]);
  const [btnText, setBtnText] = useState("Upload");
  const [showButton, setShowButton] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [currentScript, setCurrentScript] = useState<ScriptType | undefined>(
    undefined
  );
  const [showScript, setShowScript] = useState<boolean>(false);
  const [showEditScript, setShowEditScript] = useState<boolean>(false);

  if (window.location.search === "?setup=1") {
    tabTitle("Tendril Connect | Intent - setup");
  } else {
    tabTitle("Tendril Connect | Intent");
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = location.state as LocationState;

    const setup = urlParams.get("setup");
    if (setup && setup === "1" && state) {
      setIsSession(true);
      setCallSession(state.callSession);
    } else {
      history.push({
        pathname: "/script",
      });
      setIsSession(false);
    }
  }, [callSession, location.state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const actualUser: UserResponseProps = await getUser(
          userSessionJSON.userId
        );
        const organizationId = actualUser.user?.organizationId;
        setOrgId(organizationId);
        const scriptURL = `${routes.get.script.scriptList}/${organizationId}`;
        const scriptListResponse = await axios.get<ScriptType[]>(scriptURL);
        if (
          scriptListResponse.status === 200 &&
          scriptListResponse.data.length > 0
        ) {
          setScriptList(scriptListResponse.data);
        } else {
          setVisible(true);
        }
      } catch (error) {
        showUIToast({
          type: "error",
          text: "Something went wrong, try again later.",
        });
      }
    };
    fetchData();
  }, [userSessionJSON.userId]);

  useEffect(() => {
    const handleOptionChange = () => {
      const foundNewItem = optionSelected.some((el) => {
        if (el !== undefined) {
          if (el.id === "newItem" && el.select === true) {
            return el;
          }
        }
      });
      if (foundNewItem) {
        setBtnText("Save");
        setShowButton(true);
        setDisabled(false);
      }
      const foundItem = optionSelected.some((el) => {
        if (el !== undefined) {
          if (el.id !== "newItem" && el.select === true) {
            return el;
          }
        }
      });
      if (foundItem) {
        if (isSession) {
          setBtnText("Upload");
          setShowButton(true);
          setDisabled(false);
        } else {
          setShowButton(false);
          setDisabled(true);
        }
      }

      const noSelected = optionSelected.filter((el) => el.select === false);
      if (noSelected.length === optionSelected.length) {
        setShowButton(false);
        setDisabled(true);
      }
    };

    const current = optionSelected.find((item) => item.select);
    setCurrentScript(current?.id !== "newItem" ? current : undefined);

    if (currentScript !== undefined) {
      setShowScript(true);
    } else {
      setShowScript(false);
    }

    resetEditScript();
    handleOptionChange();
  }, [optionSelected, showScript, currentScript]);

  const selectScriptCallback = () => {
    optionSelected.map((item) => {
      if (item.id === "newItem" && item.select === true) {
        handleAddItem();
      }
      if (item.id !== "newItem" && item.select === true) {
        const callSession1 = callSession;
        callSession1.speech = item.body;
        callSession1.scriptTitle = item.title;
        callSession1.scriptId = item.id;
        checkAndRedirectCallSession(callSession, history);
        showUIToast({
          type: "info",
          text: "Intent selected.",
        });
      }
    });
  };

  const handleAddItem = async () => {
    if (newData.title !== "" && newData.body !== "") {
      try {
        const addScriptUrl = routes.post.scripts.scriptNest;
        const body = { ...newData, ...{ organizationId: orgId } };
        const addScriptRes = await axios.post<ScriptType>(addScriptUrl, body);
        if (addScriptRes.status === 200 || addScriptRes.status === 201) {
          const updatedScriptList = scriptList.filter(
            (item) => item.id !== "newItem"
          );
          const newScriptList = [addScriptRes.data, ...updatedScriptList];
          setScriptList(newScriptList);
        }
        showUIToast({
          type: "success",
          text: "A new intent was created.",
        });
        setVisible(false);
      } catch (error) {
        setVisible(false);
        showUIToast({
          type: "error",
          text: "Error adding intent, try again later.",
        });
      }
    } else if (newData.title === "" && newData.body === "") {
      showUIToast({
        type: "warning",
        text: "Intent name and speech are required.",
      });
    } else if (newData.title !== "" && newData.body === "") {
      setNewData(newData);
      showUIToast({
        type: "warning",
        text: "Intent name is required.",
      });
    } else if (newData.title === "" && newData.body !== "") {
      setNewData(newData);
      showUIToast({
        type: "warning",
        text: "Intent speech is required.",
      });
    }
  };

  const handleCardNoSession = () => {
    const data = {
      title: "",
      body: "",
    };
    setNewData(data);
    const selected = optionSelected.filter((item) => item.select !== false);
    if (selected[0].id === "newItem") {
      handleAddItem();
    }
  };

  const handleDeleteItem = async (element) => {
    if (isSession) {
      showUIToast({
        type: "warning",
        text: "You can't delete your Intent while in session configuration.",
      });
      return;
    }
    try {
      const { id } = element;

      if (id) {
        const deleteItemUrl = `${routes.delete.script.deleteScriptNest}/${id}`;
        const deleteReq = await axios.delete(deleteItemUrl);
        if (deleteReq.status === 200 || deleteReq.status === 201) {
          showUIToast({
            type: "info",
            text: "Intent deleted.",
          });
          const scriptListUpdate = scriptList.filter((item) => item.id !== id);
          setScriptList(
            scriptListUpdate.filter((item) => item.id !== "newItem")
          );
          setOptionSelected([]);
          if (scriptListUpdate.length === 1) {
            setVisible(true);
          }
        }
      }
    } catch (error) {
      showUIToast({
        type: "error",
        text: "Error deleting intent, try again later.",
      });
    }
  };

  const handleEditItem = async (
    script?: Omit<ScriptType, "organizationId">
  ) => {
    if (isSession) {
      showUIToast({
        type: "warning",
        text: "You can't edit your Intent while in session configuration.",
      });
      return;
    }

    if (!showEditScript || !script) {
      setTimeout(() => {
        const selected = optionSelected.find((item) => item.select === true);
        setEditData(selected);
        setShowEditScript(true);
        setBtnText("Update");
        setShowButton(true);
        setDisabled(false);
      }, 100);
      return;
    }

    const { id, title, body } = script;
    if (title === "" && body === "") {
      showUIToast({
        type: "warning",
        text: "Intent name and speech are required.",
      });
      return;
    }

    if (
      (currentScript?.body !== body || currentScript?.title !== title) &&
      currentScript?.id === id &&
      id
    ) {
      const { data, success, error } = await updateScriptAsync(id, {
        title,
        body,
      });

      if (success && data) {
        showUIToast({
          type: "info",
          text: "Intent updated.",
        });
        setScriptList((prevScripts) =>
          prevScripts.map((prevScript) => {
            if (prevScript.id === data.id) {
              return {
                ...prevScript,
                title: data.title,
                body: data.body,
              };
            }
            return prevScript;
          })
        );
        resetEditScript();
      }
      error && showUIToast({ type: "error", text: error.message });
    }
  };

  const handleSaveButton = () => {
    if (!isSession && showEditScript) {
      handleEditItem(editData);
      return;
    }
    isSession ? selectScriptCallback() : handleCardNoSession();
  };

  const resetEditScript = () => {
    setShowEditScript(false);
    setEditData(undefined);
    setBtnText("Save");
    setShowButton(false);
    setDisabled(true);
    setNewData({ title: "", body: "" });
  };

  return (
    <Layout
      sidebar
      handleLogout={handleLogout}
      user={userSessionJSON.userName}
      intervals={intervals}
      setIntervals={setIntervals}
    >
      <UIRow justifyContent="center">
        <UICol justifyContent="center" maxWidth="375px">
          <UICard
            title={
              isSession
                ? "Select your intent"
                : `${showScript ? "Intent Selected" : "Add new intent"}`
            }
            width="100%"
          >
            <GenericList
              data={scriptList}
              radioBtn={true}
              setOptionSelected={setOptionSelected}
              setVisible={setVisible}
              deleteItem={handleDeleteItem}
              editItem={handleEditItem}
              newItemTitle="+ New Intent"
              height={showButton ? "315px" : "375px"}
            />
            {showButton && (
              <UIButton
                data-qa-id="script-btn"
                disabled={disabled}
                onClick={handleSaveButton}
              >
                {btnText}
              </UIButton>
            )}
          </UICard>
        </UICol>
        <UICol justifyContent="center" minWidth="375px">
          <UICard
            title="Intent Preview"
            width="100%"
            minHeight="464px"
            showTitle={false}
          >
            {showScript && currentScript?.title ? (
              showEditScript ? (
                <NewScript setScript={setEditData} script={editData!} />
              ) : (
                <ScriptPreview script={currentScript} />
              )
            ) : (
              <NewScript setScript={setNewData} script={newData} />
            )}
          </UICard>
        </UICol>
      </UIRow>
    </Layout>
  );
};

export default Script;
