import routes from "../../constants/routes";
import axios from "axios";
import { HubspotList } from "../../types/HubspotTypes";

type ListsProps = {
  lists?: HubspotList[];
  error?: { message: string };
};

const getListsFromHubspotAsync = async (
  userId: string,
  orgId: string,
  custom = false
): Promise<ListsProps> => {
  try {
    const hubspotSequences = await axios.get<HubspotList[]>(
      `${
        custom ? routes.get.customHubspot.getLists : routes.get.hubspot.getLists
      }/${orgId}/${userId}`
    );

    return { lists: hubspotSequences?.data };
  } catch (error) {
    return { error: { message: "Error while getting sequences" } };
  }
};

export default getListsFromHubspotAsync;
