import routes from "../../constants/routes";
import axios from "axios";

export type ActiveOrganizationsResponse = {
  id: string;
  name: string;
};

type ResponseProps = {
  activeOrganizations?: ActiveOrganizationsResponse[] | [];
  error?: { message: string };
};

const getActiveOrganizationsAsync = async (): Promise<ResponseProps> => {
  try {
    const activeOrganizations = await axios.get<ActiveOrganizationsResponse[]>(
      routes.get.organizations.activeOrganizations
    );
    return { activeOrganizations: activeOrganizations.data };
  } catch {
    return { error: { message: "Error getting organizations" } };
  }
};

export default getActiveOrganizationsAsync;
