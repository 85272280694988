import routes from "../../constants/routes";
import axios from "axios";

type ResponseProps = {
  status?: number;
  error?: { message: string };
};

const logout = async (token: string): Promise<ResponseProps> => {
  try {
    const response = await axios.post<string>(routes.post.auth.logout, {
      token,
    });

    console.log(response);
    return { status: response.status };
  } catch (e) {
    return { error: { message: "Error while logging out" } };
  }
};

export default logout;
