import routes from "../../constants/routes";
import axios from "axios";
import { Script } from "../../types/ScriptTypes";
import { ApiResponse } from "../../types/ApiResponse";

const updateScriptAsync = async (
  scriptId: string,
  script: Pick<Script, "body" | "title">
): Promise<ApiResponse<Script>> => {
  try {
    const response = await axios.put<Script>(
      `${routes.delete.script.editScriptNest}/${scriptId}`,
      script
    );
    return {
      success: true,
      data: response.data,
    };
  } catch (e) {
    return {
      error: { message: "Error updating intent, try again later." },
    };
  }
};

export default updateScriptAsync;
