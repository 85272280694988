import routes from "../../constants/routes";
import axios from "axios";
import { ApiResponse } from "../../types/ApiResponse";

export const getSdrIdsByNameOrOrganization = async (
  encodeSearch: string
): Promise<ApiResponse<any>> => {
  try {
    const response = await axios.get(
      `${routes.get.dashboard.getSdrIdsByNameOrOrganizationNest}/${encodeSearch}`
    );
    return {
      success: true,
      data: response.data,
    };
  } catch (e) {
    return {
      error: { message: "Error getting ids, try again later." },
    };
  }
};
