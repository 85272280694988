import React, { useEffect, useState } from "react";
import { Flex, Text, Image, Button } from "../../../core/ui";
import {
  GenericTableHeader,
  PaginatedProps,
  SearchProps,
} from "../../../core/ui/UITable";
import CreateEditOrgModal from "./CreateEditOrgModal";
import getOrganizationsAsync from "../../../api/Management/getOrganizationsAsync";
import { OrganizationManagement } from "../../../types";
import AddOrgIcon from "../../../assets/icons/add_org_white.svg";
import UICard from "../../../core/ui/UICard";
import UITable from "../../../core/ui/UITable";
import Loader from "../../../core/ui/UILoader";
import { showUIToast } from "../../../core/ui/UIToast";
import DeleteOrganizationModal from "../../../pages/Management/components/Organization/DeleteOrganizationModal.component";
import Organization from "../../../types/OrganizationTypes";
import { UIIconButton } from "../../../core/ui/UIElements";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import FeatureFlagsDialog from "../../../../src-v2/Presentation/Views/Management/Components/Dialogs/FeatureFlags/FeatureFlags.dialog";

const OrgsManagementContainer = (): JSX.Element => {
  const [orgs, setOrgs] = useState<OrganizationManagement[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<Organization | undefined>();
  const [pageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [paginatedData, setPaginatedData] = useState<
    PaginatedProps | undefined
  >(undefined);
  const [searchData, setSearchData] = useState<SearchProps | undefined>(
    undefined
  );
  const [showCreateEditModal, setShowCreateEditModal] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [loadingStatus, updateLoadingStatus] = useState<{
    sequences: boolean;
    orgs: boolean;
  }>({ sequences: true, orgs: true });

  const handleEditOrg = (org) => {
    setSelectedOrg(org);
    setShowCreateEditModal(true);
  };

  const handleShowDeleteOrgrModal = (org) => {
    setSelectedOrg(org);
    setShowDeleteModal(true);
  };

  const renderOperations = (org) => (
    <Flex gap="20px">
      <UIIconButton onClick={() => handleEditOrg(org)}>
        <FA icon={faPenToSquare} size="lg" className="text-app-blue-light" />
      </UIIconButton>
      <FeatureFlagsDialog organization={{name: org.name, id: org.id ?? org._id}}/>
      <UIIconButton onClick={() => handleShowDeleteOrgrModal(org)}>
        <FA icon={faTrash} size="lg" />
      </UIIconButton>
    </Flex>
  );

  const headers: GenericTableHeader[] = [
    { text: "Name", width: "2" },
    { text: "Created at", width: "1" },
    { text: "", width: "0.5" },
  ];

  const tableBodies = [
    "name",
    {
      id: "createdAt.$date",
      customRender: true,
      renderFunc: ({ createdAt }) =>
        new Date(createdAt.$date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }),
    },
    { id: "operations", customRender: true, renderFunc: renderOperations },
  ];

  const getOrgs = async (pageNumber: number, searchValue: string) => {
    const { data, totalCount, totalPages, error } = await getOrganizationsAsync(
      pageNumber,
      pageSize,
      searchValue
    );

    setPageNumber(pageNumber);
    setOrgs(data === undefined ? [] : data);
    setPaginatedData({
      pageNumber,
      totalCount: totalCount ? totalCount : 0,
      totalPages: totalPages ? totalPages : 1,
      pageSize,
      onPrevPage: handlePrev,
      onNextPage: handleNext,
    });
    setSearchData({
      placeHolder: "Search by organization or user name",
      onSearch: handleSearch,
    });

    updateLoadingStatus({ ...loadingStatus, orgs: false });

    error && showUIToast({ type: "error", text: error.message });
  };

  useEffect(() => {
    getOrgs(pageNumber, search);
  }, [pageNumber, search]);

  const handleSearch = async (searchData) => {
    await setPageNumber(1);
    await setSearch(searchData);
  };

  const handlePrev = () => {
    setPageNumber((last) => last - 1);
  };

  const handleNext = () => {
    setPageNumber((last) => last + 1);
  };

  const handleOnCloseCreateEditModal = () => {
    setShowCreateEditModal(false);
    cleanup();
  };

  const handleOnCloseDeleteModal = () => {
    setShowDeleteModal(false);
    cleanup();
  };

  const handleOnSuccessModal = () => {
    getOrgs(1, search);
    setShowCreateEditModal(false);
    setShowDeleteModal(false);
    cleanup();
  };

  const handleModal = (event) => {
    event.preventDefault();
    setShowCreateEditModal(true);
  };

  const cleanup = () => {
    setSelectedOrg(undefined);
  };
  return (
    <>
      <UICard
        title={"Organizations manager"}
        showTitle={false}
        width={"100%"}
        height="580px"
      >
        {loadingStatus.orgs ? (
          <Loader message={"Loading call sessions, please wait..."} />
        ) : (
          <UITable
            headers={headers}
            tableBodies={tableBodies}
            data={orgs}
            visible={false}
            paginatedData={paginatedData}
            searchData={searchData}
          >
            <Flex padding="0 2rem" justifyContent="flex-end">
              <Button
                bg="lightBlue"
                px={25}
                py={12}
                onClick={handleModal}
                style={{ paddingBottom: "6px", paddingTop: "6px" }}
              >
                <Flex alignItems="center">
                  <Image src={AddOrgIcon} width={20} mr={15}></Image>
                  <Text fontSize={15} color="white">
                    Create organization
                  </Text>
                </Flex>
              </Button>
            </Flex>
          </UITable>
        )}
      </UICard>
      <CreateEditOrgModal
        isOpen={showCreateEditModal}
        onClose={handleOnCloseCreateEditModal}
        selectedOrg={selectedOrg}
        onSuccess={handleOnSuccessModal}
      />
      <DeleteOrganizationModal
        organization={selectedOrg}
        isOpen={showDeleteModal}
        onClose={handleOnCloseDeleteModal}
        onSuccess={handleOnSuccessModal}
      />
    </>
  );
};

export default OrgsManagementContainer;
