import routes from "../../constants/routes";
import axios, { AxiosResponse } from "axios";
import { User } from "../../types";

type GetUserParams = {
  userId: string;
};

export type UserResponseProps = {
  user?: User;
  error?: { message: string };
};

const getUser = async (userId: GetUserParams): Promise<UserResponseProps> => {
  try {
    const userUrl = `${routes.get.users.userNest}/${userId}`;
    const user: AxiosResponse<User> = await axios.get(userUrl);
    return { user: user.data };
  } catch (e) {
    return { error: { message: "Error getting the requested user" } };
  }
};

export default getUser;
