import { config } from "../config";

/**
 * Registers different microservices base url
 * @type {{edge, contacts}}
 */
export const microservices = {
  edge: config.url.edgeUrl,
  contacts: config.url.contactsUrl,
  sync: config.url.syncUrl,
  contactsNest: config.url.contactsNestUrl,
  edgeNest: config.url.edgeNestUrl,
};
