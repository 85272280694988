import routes from "../../constants/routes";
import axios from "axios";

import Organization, {
  CreateOrganizationDto,
} from "../../types/OrganizationTypes";
import { ApiResponse } from "../../types/ApiResponse";

const createOrganizationAsync = async (
  organization: CreateOrganizationDto
): Promise<ApiResponse<Organization>> => {
  try {
    const url = `${routes.post.organizations.organizationNest}`;
    const { data } = await axios.post<Organization>(url, {
      ...organization,
      adminsId: organization.adminsId ?? [],
    });

    return { success: true, data };
  } catch {
    return {
      success: false,
      error: { message: "Error creating organization" },
    };
  }
};

export default createOrganizationAsync;
