import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Flex, Text, Image, Button } from "../../../core/ui";
import { TransparentButton } from "../../shared";
import {
  GenericTableHeader,
  PaginatedProps,
  SearchProps,
} from "../../../core/ui/UITable";
import { UserTypes } from "../../shared/enums";
import CreateEditModal from "./CreateEditModal";
import DeleteModal from "./DeleteModal";
import getUsersAsync from "../../../api/Management/getUsersAsync";
import Edit from "../../../assets/icons/edit_blue.svg";
import Delete from "../../../assets/icons/garbage_gray.svg";
import AddUserIcon from "../../../assets/icons/add_user_white.svg";
import UICard from "../../../core/ui/UICard";
import UITable from "../../../core/ui/UITable";
import Loader from "../../../core/ui/UILoader";
import { showUIToast } from "../../../core/ui/UIToast";

const TypeContainer = styled(Box)`
  width: 65px;
  padding: 4px;
  border-radius: 5px;
  text-align: center;
  color: white;
  background: linear-gradient(90deg, #0093d2 0%, #0a5690 50%);
`;

const UsersManagementContainer = (): JSX.Element => {
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [showCreateEditModal, setShowCreateEditModal] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedData, setPaginatedData] = useState<
    PaginatedProps | undefined
  >(undefined);
  const [searchData, setSearchData] = useState<SearchProps | undefined>(
    undefined
  );
  const [search, setSearch] = useState("");
  const [loadingStatus, updateLoadingStatus] = useState<{
    users: boolean;
  }>({ users: true });

  //For table fields, custom render will be the preferred way if the value is not string
  const renderUserType = (user) => {
    const { userType } = user;
    if (userType.length > 1) {
      return (
        <Flex gap="10px">
          {userType.map((type) => (
            <TypeContainer key={type}>{type}</TypeContainer>
          ))}
        </Flex>
      );
    }
    return userType.length ? (
      <Flex>
        <TypeContainer>{userType[0]}</TypeContainer>
      </Flex>
    ) : null;
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setShowCreateEditModal(true);
  };

  const handleShowDeleteUserModal = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const renderOperations = (user) => {
    const { userType } = user;
    const canBeDeleted = !userType.includes(UserTypes.ADMIN);
    return (
      <Flex gap="20px">
        <TransparentButton onClick={() => handleEditUser(user)}>
          <Image height="20px" src={Edit} alt="edit" />
        </TransparentButton>
        {canBeDeleted && (
          <TransparentButton onClick={() => handleShowDeleteUserModal(user)}>
            <Image height="20px" src={Delete} alt="delete" />
          </TransparentButton>
        )}
      </Flex>
    );
  };

  const headers: GenericTableHeader[] = [
    { text: "Name", width: "2" },
    { text: "Email", width: "2" },
    { text: "User type(s)", width: "1" },
    { text: "Organization", width: "1" },
    { text: "", width: "0.5" },
  ];

  const tableBodies = [
    "name",
    "email",
    { id: "userType", customRender: true, renderFunc: renderUserType },
    "organizationName",
    { id: "operations", customRender: true, renderFunc: renderOperations },
  ];

  const getUsers = async (pageNumber: number, searchValue: string) => {
    const { data, success, error } = await getUsersAsync(
      pageNumber,
      pageSize,
      searchValue
    );
    if (data && data.data && success) {
      const { totalCount, totalPages } = data;
      setPageNumber(pageNumber);
      setUsers(data.data);
      setPaginatedData({
        pageNumber,
        totalCount: totalCount ? totalCount : 0,
        totalPages: totalPages ? totalPages : 1,
        pageSize,
        onPrevPage: handlePrev,
        onNextPage: handleNext,
      });
      setSearchData({
        placeHolder: "Search by name, email or organization",
        onSearch: handleSearch,
      });

      updateLoadingStatus({ ...loadingStatus, users: false });
    }
    error && showUIToast({ type: "error", text: error.message });
  };

  useEffect(() => {
    getUsers(pageNumber, search);
  }, [pageNumber, search]);

  const handleSearch = async (searchData) => {
    await setPageNumber(1);
    await setSearch(searchData);
  };

  const handlePrev = () => {
    setPageNumber((last) => last - 1);
  };

  const handleNext = () => {
    setPageNumber((last) => last + 1);
  };

  const handleOnCloseCreateOrEditModal = () => {
    setShowCreateEditModal(false);
    cleanup();
  };

  const handleOnSuccessModal = () => {
    getUsers(1, search);
    setShowDeleteModal(false);
    setShowCreateEditModal(false);
    cleanup();
  };

  const handleOnCloseDeleteUserModal = () => {
    setShowDeleteModal(false);
    cleanup();
  };

  const cleanup = () => {
    setSelectedUser(null);
  };
  return (
    <>
      <UICard
        title={"Contacts from selected List"}
        showTitle={false}
        width={"100%"}
        height="580px"
      >
        {loadingStatus.users ? (
          <Loader message={"Loading call sessions, please wait..."} />
        ) : (
          <UITable
            headers={headers}
            tableBodies={tableBodies}
            data={users}
            visible={false}
            paginatedData={paginatedData}
            searchData={searchData}
          >
            <Flex padding="0 2rem" justifyContent="flex-end">
              <Button
                bg="lightBlue"
                px={25}
                py={12}
                onClick={() => setShowCreateEditModal(true)}
                style={{ paddingBottom: "6px", paddingTop: "6px" }}
              >
                <Flex alignItems="center">
                  <Image src={AddUserIcon} width={20} mr={15}></Image>
                  <Text fontSize={15} color="white">
                    Create user
                  </Text>
                </Flex>
              </Button>
            </Flex>
          </UITable>
        )}
      </UICard>
      <CreateEditModal
        isOpen={showCreateEditModal}
        onClose={handleOnCloseCreateOrEditModal}
        selectedUser={selectedUser}
        onSuccess={handleOnSuccessModal}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={handleOnCloseDeleteUserModal}
        selectedUser={selectedUser}
        onSuccess={handleOnSuccessModal}
      />
    </>
  );
};

export default UsersManagementContainer;
