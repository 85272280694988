import routes from "../../constants/routes";
import axios from "axios";
import Organization, {
  UpdateOrganizationDto,
} from "../../types/OrganizationTypes";
import { ApiResponse } from "../../types/ApiResponse";

const updateOrganizationAsync = async (
  organization: UpdateOrganizationDto
): Promise<ApiResponse<Organization>> => {
  try {
    const compatId = organization._id ?? organization.id;
    const url = `${routes.put.organizations.organizationNest}/${compatId}`;

    const { data } = await axios.put<Organization>(url, {
      ...organization,
      id: compatId,
      adminsId: organization.adminsId ?? [],
    });

    return { success: true, data };
  } catch {
    return {
      success: false,
      error: { message: "Error updating organization, try again later." },
    };
  }
};

export default updateOrganizationAsync;
