import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/shared/Layout";
import { Flex } from "../../core/ui";
import { theme } from "../../core/theme";
import axios from "axios";
import routes from "../../constants/routes";
import styled from "styled-components";
import { PaginatedProps, SearchProps } from "../../core/ui/UITable";
import Csv from "../../assets/icons/CSV.svg";
import Tendril from "../../assets/icons/tendril_icon.svg";
import Modal from "../../components/shared/Modal";
import { isTendrilAdmin } from "../../core/utils/userTypeValidator";
import tabTitle from "../../utils/updateTitle";
import { featuresStore } from "../../components/app/FeaturesProvider";

import UITable from "../../core/ui/UITable";
import UICard from "../../core/ui/UICard";
import Outreach from "../Settings/Outreach/outreach_logo.svg";
import Hubspot from "../Settings/Hubspot/hubspot-logo.svg";
import { useHistory } from "react-router-dom";
import Loader from "../../core/ui/UILoader";
import { showUIToast } from "../../core/ui/UIToast";
import { getSdrIdsByNameOrOrganization } from "../../api/Dashboard/dashboardRequestsAsync";
const List = styled.ul`
  color: ${theme.colors.darkGray};
  list-style-type: disc;
`;
const DescriptionContainer = styled.div`
  margin-bottom: 1.5rem;
  margin-left: 2rem;
`;
const Bold = styled.span`
  font-weight: 600;
`;

const icons = {
  OUTREACH: Outreach,
  HUBSPOT: Hubspot,
  CUSTOM_HUBSPOT: Hubspot,
  CSV: Tendril,
  FOURMATIV: Csv,
};

interface Props {
  userSession: string;
  handleLogout: () => void;
  intervals: { [key: string]: any };
  setIntervals: (value: any) => void | undefined;
}

const Dashboard = ({
  userSession,
  handleLogout,
  intervals,
  setIntervals,
}: Props): JSX.Element => {
  const history = useHistory();
  const userSessionJSON = JSON.parse(userSession);
  const [callSessions, setCallSessions] = useState([] as any[]);
  const [callSessionId, setCallSessionId] = useState("");
  const [extraData, setExtraData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const { featuresState } = useContext(featuresStore);
  const { fourMativ } = featuresState;
  const [loadingStatus, updateLoadingStatus] = useState<{
    sequences: boolean;
    callsessions: boolean;
  }>({ sequences: true, callsessions: true });
  tabTitle("Tendril Connect | Dashboard");

  const [paginatedData, setPaginatedData] = useState<
    PaginatedProps | undefined
  >(undefined);

  const [searchData, setSearchData] = useState<SearchProps | undefined>(
    undefined
  );

  const [modal, setModal] = useState<boolean>(false);
  const [idDownload, setIdDownload] = useState("");

  useEffect(() => {
    setSearchData({
      placeHolder: "Search by sdr, contact list name or organization",
      onSearch: handleSearch,
    });

    fetchData(userSessionJSON.userId);
  }, [pageNumber, search]);

  const fetchData = async (sdrId) => {
    const isSuperAdmin = await isTendrilAdmin();
    const dashboardURL = `${routes.get.dashboard.getDashboardNest}/${sdrId}`;
    const sdrArrays: string[] = [];

    if (search != "") {
      const encodeSearch = encodeURIComponent(search);
      const { data } = await getSdrIdsByNameOrOrganization(encodeSearch);
      if (data) {
        data.forEach((i: any) => {
          const id = i._id || i.id;
          sdrArrays.push(id);
        });
      }
    }

    try {
      const {
        data: { formatedData, ...rest },
      } = await axios.get(dashboardURL, {
        params: {
          page: pageNumber,
          pageLimit: 30,
          isAdmin: isSuperAdmin,
          searchQuery: search,
          sdrIds: sdrArrays,
        },
      });

      setPaginatedData({
        ...rest,
        pageSize: 30,
        onPrevPage: handlePrev,
        onNextPage: handleNext,
      });

      await buildSessions(formatedData);

      updateLoadingStatus({ ...loadingStatus, callsessions: false });
    } catch (e) {
      showUIToast({
        type: "error",
        text: "Error getting dashboard, try again later.",
      });
    }
  };

  const handlePrev = async () => {
    setPageNumber((last) => last - 1);
  };

  const handleNext = async () => {
    setPageNumber((last) => last + 1);
  };

  const buildSessions = async (sessionData) => {
    try {
      const { data } = await axios.get(routes.get.organizations.organization);

      sessionData.forEach((item) => {
        const organizationData = data.filter(function (org) {
          return org.id === item.organizationId;
        });
        item.organizationName = organizationData[0]
          ? organizationData[0].name
          : "Not Organization";
      });

      setCallSessions(sessionData);
    } catch {
      showUIToast({
        type: "error",
        text: "Error getting organization, try again later.",
      });
    }
  };

  const handleRenderFunction = (data) => {
    const icon = icons[data.type];

    return (
      <div>
        <img width={"25px"} src={icon} alt="icon" />
      </div>
    );
  };

  const headers = [
    { text: "Id", width: "1" },
    { text: "Contact list name ", width: "3" },
    { text: "List Type", width: "1" },
    { text: "Start at", width: "2" },
    { text: "Contacts", width: "1" },
    { text: "Organization", width: "2" },
    { text: "Download report", width: "1" },
  ];

  const tableBodies = [
    `shortId`,
    `listNames`,
    {
      id: "type-id",
      customRender: true,
      renderFunc: handleRenderFunction,
    },
    `startAt`,
    `totalContacts`,
    `organizationName`,
    {
      double: true,
      icon1: Csv,
    },
  ];

  const handleSearch = async (searchData) => {
    await setPageNumber(1);
    await setSearch(searchData);
  };

  const handleSummaryData = (callSessionId) => {
    history.push("/summary/" + callSessionId);
  };

  const downloadReport = async (e, id, type) => {
    if (id) {
      try {
        // Get with axios is necessary because sometimes call session doesn't
        // have metrics and on this way we can show feedback.
        const reportURL = `${routes.get.callSession.getReportNest}/${id}`;

        const createBlob = (data) => {
          const objURL = (window as any).URL.createObjectURL(new Blob([data]));
          const tempLink = document.createElement("a");
          tempLink.href = objURL;
          tempLink.setAttribute("download", `report-${id}.csv`);
          document.body.appendChild(tempLink);
          tempLink.click();

          setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(objURL);
          }, 200);
        };

        if (type === "raw") {
          const params = { type: "raw" };
          const callSession = callSessions.find(
            (callSession) => callSession.id === id
          );
          params["organizationId"] = callSession.organizationId;

          const { data } = await axios.get(reportURL, { params });
          createBlob(data);
          setModal(false);
        }

        if (type === "compact") {
          const params = { type: "compact" };
          const { data } = await axios.get(reportURL, { params });
          createBlob(data);
          setModal(false);
        }
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          return showUIToast({
            type: "error",
            text: e.response.data.error,
          });
        }
        showUIToast({
          type: "error",
          text: "Something went wrong, try again later.",
        });
      }
    }
  };

  const getId = (e) => {
    if (e.id) {
      setIdDownload(e.id);
      setModal(true);
    }
  };

  return (
    <Layout
      sidebar
      handleLogout={handleLogout}
      user={userSessionJSON.userName}
      intervals={intervals}
      setIntervals={setIntervals}
    >
      <UICard
        title={"Contacts from selected List"}
        showTitle={false}
        width={"100%"}
        height="720px"
      >
        {loadingStatus.callsessions ? (
          <Loader message={"Loading call sessions, please wait..."} />
        ) : (
          <UITable
            headers={headers}
            tableBodies={tableBodies}
            data={callSessions}
            visible={false}
            extraData={extraData}
            paginatedData={paginatedData}
            searchData={searchData}
            selectionHandler={getId}
            summaryHandler={handleSummaryData}
            setItemId={setCallSessionId}
          />
        )}
      </UICard>
      <Modal
        state={modal}
        setState={setModal}
        title={"Download report"}
        showHeader={true}
        showOverlay={true}
        modalPosition={"center"}
        padding={"20px"}
        showCloseBtn={true}
      >
        <>
          <p>Choose an export type</p>

          <DescriptionContainer>
            <List>
              <li>
                {" "}
                <Bold>Raw:</Bold> Includes all the stages of each call.
              </li>
              {!fourMativ && (
                <li>
                  {" "}
                  <Bold>Compact:</Bold> Only has the last status per call.
                </li>
              )}
            </List>
          </DescriptionContainer>

          <Flex flexDirection="row" justifyContent="space-between">
            <button onClick={(e) => downloadReport(e, idDownload, "raw")}>
              {" "}
              Raw{" "}
            </button>
            {!fourMativ && (
              <button onClick={(e) => downloadReport(e, idDownload, "compact")}>
                {" "}
                Compact{" "}
              </button>
            )}
          </Flex>
        </>
      </Modal>
    </Layout>
  );
};

export default Dashboard;
