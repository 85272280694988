import React from "react";
import { Script } from "../../types/ScriptTypes";
import {
  BaseField,
  BaseInput,
  BaseTextArea,
} from "../../core/ui/base/FormElements";

interface Props {
  script: Partial<Script>;
  setScript: (value) => void;
}

const NewScript: React.FC<Props> = ({ script, setScript }) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setScript({ ...script, [name]: value });
  };

  return (
    <>
      <BaseField>
        <BaseInput
          data-qa-id={"scriptInput"}
          type="text"
          name="title"
          placeholder={"Intent name"}
          value={script?.title || ""}
          onChange={handleInputChange}
        />
      </BaseField>
      <BaseField>
        <BaseTextArea
          data-qa-id={"scriptTextArea"}
          name="body"
          cols={35}
          rows={15}
          placeholder={"Write your Intent here..."}
          value={script?.body || ""}
          onChange={handleInputChange}
        />
      </BaseField>
    </>
  );
};

export default NewScript;
