import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { theme } from "../theme";

const { colors } = theme;

const Box = styled.div`
  width: calc(100% - 2rem);
  border: 1px solid;
  border-radius: 10px;
  overflow: hidden;
  color: inherit;
  grid-area: sidenav;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  margin: 1.5rem auto 0;
  .closed & {
    width: 48px;
    margin-left: 5px;
    margin-right: auto;
  }
`;
const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25em;
  background-color: white;
  color: ${colors.lightBlue};
  gap: 0.5em;
  font-weight: 600;
  font-size: 14px;
  .closed & {
    justify-content: center;
  }
  .closed & .text {
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
  }
`;
const BoxRow = styled.div`
  font-size: 12px;
  padding: 0.25em;
  display: flex;
  justify-content: space-between;
  line-height: 1.5;

  .open & {
    flex-direction: row;
  }

  .closed & {
    flex-direction: row;
    justify-content: center;
    text-align: center;
    .text {
      position: absolute;
      height: 0;
      width: 0;
      overflow: hidden;
    }
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid;
  }
`;
interface RowProps {
  name: string;
  data: number;
}

interface Props {
  title: string;
  rows: RowProps[];
}

const UIStatsBox = (props: Props): JSX.Element => {
  const { title, rows } = props;
  return (
    <Box title={title}>
      <BoxTitle>
        <FA icon={["fas", "star"]} />
        <span className="text">{title}</span>
      </BoxTitle>
      {rows.map(({ name, data }, i) => (
        <BoxRow key={`${name.trim().toLowerCase()}${i}`}>
          <div className="text">{name}</div>
          <div className="number">{data}</div>
        </BoxRow>
      ))}
    </Box>
  );
};

export { UIStatsBox };
